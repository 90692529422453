import { Fragment } from "react"
import { DurationPicker, MilitaryPicker, StandardTimePicker } from "../../components/simple/timepicker/index.js"
import { PropTable } from "../../components/simple/Documentation/proptable/proptable.js"
import { Showcode } from "../../components/simple/Documentation/showcode.js"
export const TimePickerPage = () => {
    const dataShow = `<StandardTimePicker name="test" title="Standard Clock">  </StandardTimePicker>
    `
    const dataShow2 = `<DurationPicker name="test" title="Duration" />
    `
    const dataShow3 = `<MilitaryPicker name="t3" title="Military"/>
    `
    return (
        <Fragment>
            <StandardTimePicker name="test" title="Standard Clock">  </StandardTimePicker>
            <Showcode data = {dataShow} />
            <DurationPicker name="test" title="Duration" />
            <Showcode data = {dataShow2} />
            <MilitaryPicker name="t3" title="Military"/>
            <Showcode data = {dataShow3} />
            <PropTable title="StandardTimePicker Props" propTypes={StandardTimePicker.propTypes} descriptions={StandardTimePicker.descriptions} ></PropTable>
            <PropTable title="DurationPicker Props" propTypes={DurationPicker.propTypes} descriptions={DurationPicker.descriptions} ></PropTable>
            <PropTable title="MilitaryPicker Props" propTypes={MilitaryPicker.propTypes} descriptions={MilitaryPicker.descriptions} ></PropTable>
        </Fragment>
    )
}
