import { Fragment } from "react"
import { Table, TableRowControlText } from "../../components/simple/table/index.js"
import { Block } from "../../components/simple/layout/index.js"
import { PropTable } from "../../components/simple/Documentation/proptable/proptable.js"
import { Showcode } from "../../components/simple/Documentation/showcode.js"
import { DropList } from "../../components/simple/input/index.js"

const header = [
    {title: "Title", disabled: true},
    {title: "Address", callback: (key, title, order) => alert(key + title + order)},
    {title: "Date", alignRight: true},
    {title: "Issue/Finding", callback: (key, title, order) => alert(key + title + order)}]
const rows = [
    [{title: <a>Title 11</a>, dropdown: true, subtitle: ["example1", "example 2"]}, {title: <a>"Address 1"</a>, subtitle: ["example1", "example 2"]}, {title: "Date 1", subtitle: ["example1", "example 2"]}, {title: "Issue 1", subtitle: ["example1", "example 2"]}],
    [{title: "Title 2gaageaegeag Title 2gaageaegeag Title 2gaageaegeag Title 2gaageaegeag Title 2gaageaegeag"}, {title:  <a>"Address 1"</a>}, {title: "Date 2"}, {title: "Issue 2"}],
    [{title: "Title 3"}, {title:  <a>"Address 1"</a>}, {title: "Date 3"}, {title: "Issue 3"}],
    [{title: "Title 5df"}, {title: "bAddress 5"}, {title: "Date 5"}, {title: "Issue 5"}],
    [{title: "Title 4313"}, {title: "Adcccdress 4"}, {title: "Date 4"}, {title: "Issue 4"}],
    [{title: "Title 1115"}, {title: "Addddress 5"}, {title: "Date 5"}, {title: "Issue 5"}],
    [{title: "Title 3"}, {title:  <a>"Address 1"</a>}, {title: "Date 3"}, {title: "Issue 3"}],
    [{title: "Title 5df"}, {title: "bAddress 5"}, {title: "Date 5"}, {title: "Issue 5"}],
    [{title: "Title 4313"}, {title: "Adcccdress 4"}, {title: "Date 4"}, {title: "Issue 4"}],
    [{title: "Title 1115"}, {title: "Addddress 5"}, {title: "Date 5"}, {title: "Issue 5"}],
    [{title: "Title 3"}, {title:  <a>"Address 1"</a>}, {title: "Date 3"}, {title: "Issue 3"}],
    [{title: "Title 5df"}, {title: "bAddress 5"}, {title: "Date 5"}, {title: "Issue 5"}],
    [{title: "Title 4313"}, {title: "Adcccdress 4"}, {title: "Date 4"}, {title: "Issue 4"}],
    [{title: "Title 1115"}, {title: "Addddress 5"}, {title: "Date 5"}, {title: "Issue 5"}],
    [{title: "Title 3"}, {title:  <a>"Address 1"</a>}, {title: "Date 3"}, {title: "Issue 3"}],
    [{title: "Title 5df"}, {title: "bAddress 5"}, {title: "Date 5"}, {title: "Issue 5"}],
    [{title: "Title 4313"}, {title: "Adcccdress 4"}, {title: "Date 4"}, {title: "Issue 4"}],
    [{title: "Title 1115"}, {title: "Addddress 5"}, {title: "Date 5"}, {title: "Issue 5"}],
    [{title: "Title 3"}, {title:  <a>"Address 1"</a>}, {title: "Date 3"}, {title: "Issue 3"}],
    [{title: "Title 5df"}, {title: "bAddress 5"}, {title: "Date 5"}, {title: "Issue 5"}],
    [{title: "Title 4313"}, {title: "Adcccdress 4"}, {title: "Date 4"}, {title: "Issue 4"}],
    [{title: "Title 1115"}, {title: "Addddress 5"}, {title: "Date 5"}, {title: "Issue 5"}],
    [{title: "Title 3"}, {title:  <a>"Address 1"</a>}, {title: "Date 3"}, {title: "Issue 3"}],
    [{title: "Title 5df"}, {title: "bAddress 5"}, {title: "Date 5"}, {title: "Issue 5"}],
    [{title: "Title 4313"}, {title: "Adcccdress 4"}, {title: "Date 4"}, {title: "Issue 4"}],
    [{title: "Title 1115"}, {title: "Addddress 5"}, {title: "Date 5"}, {title: "Issue 5"}],
    [{title: "Title 3"}, {title:  <a>"Address 1"</a>}, {title: "Date 3"}, {title: "Issue 3"}],
    [{title: "Title 5df"}, {title: "bAddress 5"}, {title: "Date 5"}, {title: "Issue 5"}],
    [{title: "Title 4313"}, {title: "Adcccdress 4"}, {title: "Date 4"}, {title: "Issue 4"}],
    [{title: "Title 1115"}, {title: "Addddress 5"}, {title: "Date 5"}, {title: "Issue 5"}],
    [{title: "Title 1115"}, {title: "Addddress 5"}, {title: "Date 5"}, {title: "Issue 5"}],
    [{title: "Title 1116"}, {title: "Addddress 5"}, {title: "Date 5"}, {title: "Issue 5"}],
]
const filters = [
    {
        label: "Choose a car",
        name: "cars",
        id: "cars",
        defaultLabel: "All Cars",
        action: (name, id, value) => alert(name + id + value),
        options: [
            {name: "Audi", value: "audi"},
            {name: "Bentley", value: "bentley"},
            {name: "Chevrolet", value: "Chevrolet"},
            {name: "Datsun", value: "datsun"},
        ]
    },
    {
        label: "Choose a Weapon",
        name: "weapons",
        id: "weapons",
        defaultLabel: "All Weapons",
        action: (name, id, value) => alert(name + id + value),
        options: [
            {name: "Axe", value: "axe"},
            {name: "Bow", value: "bow"},
            {name: "Dagger", value: "dagger"},
            {name: "Sword", value: "sword"},
        ]
    },
    {
        label: "Choose a Sport",
        name: "sports",
        id: "sports",
        defaultLabel: "All Sports",
        action: (name, id, value) => alert(name + id + value),
        options: [
            {name: "Baseball", value: "baseball"},
            {name: "Basketball", value: "basketball"},
            {name: "Football", value: "football"},
            {name: "Hockey", value: "hockey"},
        ]
    },
    {
        label: "Choose a Food",
        name: "foods",
        id: "foods",
        defaultLabel: "All Foods",
        action: (name, id, value) => alert(name + id + value),
        options: [
            {name: "Bacon", value: "bacon"},
            {name: "Cheese", value: "cheese"},
            {name: "Pizza", value: "pizza"},
            {name: "Steak", value: "steak"},
        ]
    },
    {
        label: "Choose a State",
        name: "states",
        id: "states",
        defaultLabel: "All States",
        action: (name, id, value) => alert(name + id + value),
        options: [
            {name: "Iowa", value: "iowa"},
            {name: "Minnesota", value: "minnesota"},
            {name: "North Dakota", value: "north dakota"},
            {name: "Wisconsin", value: "wisconsin"},
        ]
    },
    {
        label: "Choose a Food",
        name: "foods",
        id: "foods",
        defaultLabel: "All Foods",
        action: (name, id, value) => alert(name + id + value),
        options: [
            {name: "Bacon", value: "bacon"},
            {name: "Cheese", value: "cheese"},
            {name: "Pizza", value: "pizza"},
            {name: "Steak", value: "steak"},
        ]
    },
    {
        label: "Choose a State",
        name: "states",
        id: "states",
        defaultLabel: "All States",
        action: (name, id, value) => alert(name + id + value),
        options: [
            {name: "Iowa", value: "iowa"},
            {name: "Minnesota", value: "minnesota"},
            {name: "North Dakota", value: "north dakota"},
            {name: "Wisconsin", value: "wisconsin"},
        ]
    },
]


export const TablePage = () => {
    const dataShow = `<Table title={"Title"}
    action={() => alert("Table Action")}
    actionText={"Add Row"}
    search={true}
    searchControls={<DropList options={["One","Two","Three","Four","Five"]}
                        onChange={console.log('Search Control')} value={'Search Control'} label="Label" help="Help text" />}
    filters={filters}
    clearFilters={() => alert("Clear Filters")}
    tableControl={() => alert("Table Control")}
    header={header}
    subrowHeader={true}
    rows={rows}
    rowControl={[
        <TableRowControlText onClick={() => alert("Alert 1")}>Alert 1</TableRowControlText>,
        <TableRowControlText onClick={() => alert("Alert 2")}>Alert 2</TableRowControlText>
    ]} />`
    return(
        <Fragment>
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="flex-start">
                <Table title={"Title"}
                    action={() => alert("Table Action")}
                    actionText={"Add Row"}
                    search={true}
                    searchControls={<DropList options={["One","Two","Three","Four","Five"]}
                        onChange={console.log('Search Control')} value={'Search Control'} label="Label" help="Help text" />}
                    filters={filters}
                    clearFilters={() => alert("Clear Filters")}
                    tableControl={() => alert("Table Control")}
                    header={header}
                    subrowHeader={true}
                    rows={rows}
                    rowControl={[
                        <TableRowControlText onClick={() => alert("Alert 1")}>Alert 1</TableRowControlText>,
                        <TableRowControlText onClick={() => alert("Alert 2")}>Alert 2</TableRowControlText>
                    ]} />
            </Block>
            <Showcode data = {dataShow} />
            <PropTable descriptions={Table.descriptions} ></PropTable>
        </Fragment>
    )
}