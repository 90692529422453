import { Fragment, useState } from "react"
import { Progress } from "../../components/simple/progress/index.js"
import { Block } from "../../components/simple/layout/index.js"
import { PropTable } from "../../components/simple/Documentation/proptable/proptable.js"
import { Showcode } from "../../components/simple/Documentation/showcode.js"
export const ProgressPage = () => {
    const [percent, setPercent] = useState(0)
    const data = `<Progress percent={parseInt(percent)} data={
        [
            {name: "start", percent: 0},
            {name: "quarter", percent: 25},
            {name: "half", percent: 50},
            {name: "almost", percent: 75},
            {name: "done", percent: 100}
        ]} />`
    return(
        <Fragment>
            <Block width="calc(100% - 40px)" padding="20px">
                <p>Progress Control</p>
                <input type="number" id="percent" name="percent" min="0" max="100" onChange={(e) => setPercent(e.target.value)}></input>
            </Block>
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="flex-start">
                <Progress percent={parseInt(percent)} data={
                    [
                        {name: "start", percent: 0},
                        {name: "quarter", percent: 25},
                        {name: "half", percent: 50},
                        {name: "almost", percent: 75},
                        {name: "done", percent: 100}
                    ]} />
            </Block>
            <Showcode data = {data} />
            <PropTable descriptions={Progress.descriptions} ></PropTable>
        </Fragment>
    )
}