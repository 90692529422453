import { Fragment } from "react"
import { Block } from "../../components/simple/layout/index.js"
import * as Color from "../../components/color.js"

export const ColorPage = () => {
    return(
        <Fragment>
            <h1>Primary Color</h1>
            <Block display="flex" flexFlow="row wrap" width="calc(100% - 40px)" padding="20px" justify="flex-start">
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.PRIMARY_BLUE} border="1px solid black" radius="5px">
                    PRIMARY_BLUE
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.DARK_BLUE} border="1px solid black" radius="5px">
                    DARK_BLUE
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.TRANE_ORANGE} border="1px solid black" radius="5px">
                    TRANE_ORANGE
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.PRIMARY_BG} border="1px solid black" radius="5px">
                    PRIMARY_BG
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.ZEBRA_STRIPE_BG} border="1px solid black" radius="5px">
                    ZEBRA_STRIPE_BG
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.DM_BLUE} border="1px solid black" radius="5px">
                    DM_BLUE
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.DARK_GREY_BG_1} border="1px solid black" radius="5px">
                    DARK_GREY_BG_1
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.DARK_GREY_BG_2} border="1px solid black" radius="5px">
                    DARK_GREY_BG_2
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.WHITE} border="1px solid black" radius="5px">
                    WHITE
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.BLACK} border="1px solid black" radius="5px">
                    BLACK
                </Block>
            </Block>
            <h1>Secondary Color</h1>
            <Block display="flex" flexFlow="row wrap" width="calc(100% - 40px)" padding="20px" justify="flex-start">
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.HIGHLIGHT} border="1px solid black" radius="5px">
                    HIGHLIGHT
                </Block>
            </Block>
            <h1>Status Color</h1>
            <Block display="flex" flexFlow="row wrap" width="calc(100% - 40px)" padding="20px" justify="flex-start">
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.SUCCESS_GREEN} border="1px solid black" radius="5px">
                    SUCCESS_GREEN
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.LIGHT_GREEN_BG} border="1px solid black" radius="5px">
                    LIGHT_GREEN_BG
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.WARNING_ORANGE} border="1px solid black" radius="5px">
                    WARNING_ORANGE
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.LIGHT_ORANGE_BG} border="1px solid black" radius="5px">
                    LIGHT_ORANGE_BG
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.ERROR_RED} border="1px solid black" radius="5px">
                    ERROR_RED
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.LIGHT_PINK_BG} border="1px solid black" radius="5px">
                    LIGHT_PINK_BG
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.DARK_ERROR_RED} border="1px solid black" radius="5px">
                    DARK_ERROR_RED
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.LIGHT_BLUE_BG} border="1px solid black" radius="5px">
                    LIGHT_BLUE_BG
                </Block>
            </Block>
            <h1>Gray Color</h1>
            <Block display="flex" flexFlow="row wrap" width="calc(100% - 40px)" padding="20px" justify="flex-start">
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.TEXT_SECONDARY_1} border="1px solid black" radius="5px">
                    TEXT_SECONDARY_1
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.TEXT_HOVER_1} border="1px solid black" radius="5px">
                    TEXT_HOVER_1
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.TEXT_SECONDARY_2} border="1px solid black" radius="5px">
                    TEXT_SECONDARY_2
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.TEXT_HOVER_2} border="1px solid black" radius="5px">
                    TEXT_HOVER_2
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.HAIRLINE} border="1px solid black" radius="5px">
                    HAIRLINE
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.LIGHT_BORDER} border="1px solid black" radius="5px">
                    LIGHT_BORDER
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.INPUT_BACKGROUND} border="1px solid black" radius="5px">
                    INPUT_BACKGROUND
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.DISABLED_BG} border="1px solid black" radius="5px">
                    DISABLED_BG
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.DISABLED_TEXT} border="1px solid black" radius="5px">
                    DISABLED_TEXT
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.TERTIARY_HOVER} border="1px solid black" radius="5px">
                    TERTIARY_HOVER
                </Block>
            </Block>
            <h1>Text Color</h1>
            <Block display="flex" flexFlow="row wrap" width="calc(100% - 40px)" padding="20px" justify="flex-start">
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.DARK_GREY} border="1px solid black" radius="5px">
                    DARK_GREY
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.MED_GREY} border="1px solid black" radius="5px">
                    MED_GREY
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.GREY} border="1px solid black" radius="5px">
                    GREY
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.SNOW} border="1px solid black" radius="5px">
                    SNOW
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.DM_MED_GREY} border="1px solid black" radius="5px">
                    DM_MED_GREY
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.DM_GREY} border="1px solid black" radius="5px">
                    DM_GREY
                </Block>
            </Block>
            <h1>Graph Color</h1>
            <Block display="flex" flexFlow="row wrap" width="calc(100% - 40px)" padding="20px" justify="flex-start">
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.GENERAL} border="1px solid black" radius="5px">
                    GENERAL
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.EUI} border="1px solid black" radius="5px">
                    EUI
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.ECI} border="1px solid black" radius="5px">
                    ECI
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.PREDICTIVE_ENERGY} border="1px solid black" radius="5px">
                    PREDICTIVE_ENERGY
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.PEAK_DEMAND} border="1px solid black" radius="5px">
                    PEAK_DEMAND
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.CONSUMPTION} border="1px solid black" radius="5px">
                    CONSUMPTION
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.ENERGY_STAR} border="1px solid black" radius="5px">
                    ENERGY_STAR
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.TEMPERATURE} border="1px solid black" radius="5px">
                    TEMPERATURE
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.ENERGY_OVERAGE} border="1px solid black" radius="5px">
                    ENERGY_OVERAGE
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.TOTAL_SAVINGS} border="1px solid black" radius="5px">
                    TOTAL_SAVINGS
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.ENERGY_SAVINGS} border="1px solid black" radius="5px">
                    ENERGY_SAVINGS
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.ELECTRIC_MONTHLY} border="1px solid black" radius="5px">
                    ELECTRIC_MONTHLY
                </Block>
            </Block>
            <h1>Tertiary Color</h1>
            <Block display="flex" flexFlow="row wrap" width="calc(100% - 40px)" padding="20px" justify="flex-start">
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.PURPLE_100} border="1px solid black" radius="5px">
                    PURPLE_100
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.PURPLE_200} border="1px solid black" radius="5px">
                    PURPLE_200
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.PURPLE_300} border="1px solid black" radius="5px">
                    PURPLE_300
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.PURPLE_400} border="1px solid black" radius="5px">
                    PURPLE_400
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.PURPLE_500} border="1px solid black" radius="5px">
                    PURPLE_500
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.PURPLE_600} border="1px solid black" radius="5px">
                    PURPLE_600
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.PURPLE_700} border="1px solid black" radius="5px">
                    PURPLE_700
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.YELLOW_100} border="1px solid black" radius="5px">
                    YELLOW_100
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.YELLOW_200} border="1px solid black" radius="5px">
                    YELLOW_200
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.YELLOW_300} border="1px solid black" radius="5px">
                    YELLOW_300
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.YELLOW_400} border="1px solid black" radius="5px">
                    YELLOW_400
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.YELLOW_500} border="1px solid black" radius="5px">
                    YELLOW_500
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.YELLOW_600} border="1px solid black" radius="5px">
                    YELLOW_600
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.YELLOW_700} border="1px solid black" radius="5px">
                    YELLOW_700
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.ORANGE_100} border="1px solid black" radius="5px">
                    ORANGE_100
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.ORANGE_200} border="1px solid black" radius="5px">
                    ORANGE_200
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.ORANGE_300} border="1px solid black" radius="5px">
                    ORANGE_300
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.ORANGE_400} border="1px solid black" radius="5px">
                    ORANGE_400
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.ORANGE_500} border="1px solid black" radius="5px">
                    ORANGE_500
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.ORANGE_600} border="1px solid black" radius="5px">
                    ORANGE_600
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.ORANGE_700} border="1px solid black" radius="5px">
                    ORANGE_700
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.RED_100} border="1px solid black" radius="5px">
                    RED_100
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.RED_200} border="1px solid black" radius="5px">
                    RED_200
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.RED_300} border="1px solid black" radius="5px">
                    RED_300
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.RED_400} border="1px solid black" radius="5px">
                    RED_400
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.RED_500} border="1px solid black" radius="5px">
                    RED_500
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.RED_600} border="1px solid black" radius="5px">
                    RED_600
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.RED_700} border="1px solid black" radius="5px">
                    RED_700
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.GREEN_100} border="1px solid black" radius="5px">
                    GREEN_100
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.GREEN_200} border="1px solid black" radius="5px">
                    GREEN_200
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.GREEN_300} border="1px solid black" radius="5px">
                    GREEN_300
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.GREEN_400} border="1px solid black" radius="5px">
                    GREEN_400
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.GREEN_500} border="1px solid black" radius="5px">
                    GREEN_500
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.GREEN_600} border="1px solid black" radius="5px">
                    GREEN_600
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.GREEN_700} border="1px solid black" radius="5px">
                    GREEN_700
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.TEAL_100} border="1px solid black" radius="5px">
                    TEAL_100
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.TEAL_200} border="1px solid black" radius="5px">
                    TEAL_200
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.TEAL_300} border="1px solid black" radius="5px">
                    TEAL_300
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.TEAL_400} border="1px solid black" radius="5px">
                    TEAL_400
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.TEAL_500} border="1px solid black" radius="5px">
                    TEAL_500
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.TEAL_600} border="1px solid black" radius="5px">
                    TEAL_600
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.TEAL_700} border="1px solid black" radius="5px">
                    TEAL_700
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.BLUE_100} border="1px solid black" radius="5px">
                    BLUE_100
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.BLUE_200} border="1px solid black" radius="5px">
                    BLUE_200
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.BLUE_300} border="1px solid black" radius="5px">
                    BLUE_300
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.BLUE_400} border="1px solid black" radius="5px">
                    BLUE_400
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.BLUE_500} border="1px solid black" radius="5px">
                    BLUE_500
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.BLUE_600} border="1px solid black" radius="5px">
                    BLUE_600
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.BLUE_700} border="1px solid black" radius="5px">
                    BLUE_700
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.GREY_100} border="1px solid black" radius="5px">
                    GREY_100
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.GREY_200} border="1px solid black" radius="5px">
                    GREY_200
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.GREY_300} border="1px solid black" radius="5px">
                    GREY_300
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.GREY_400} border="1px solid black" radius="5px">
                    GREY_400
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.GREY_500} border="1px solid black" radius="5px">
                    GREY_500
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.GREY_600} border="1px solid black" radius="5px">
                    GREY_600
                </Block>
                <Block display="flex" align="center" justify="center" width="calc(25% - 42px)" margin="20px" height="100px" background={Color.GREY_700} border="1px solid black" radius="5px">
                    GREY_700
                </Block>
            </Block>
        </Fragment>
    )
}
