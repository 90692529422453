import { Fragment, useState } from "react"
import { Modal } from "../../components/simple/modal/index.js"
import { Block } from "../../components/simple/layout/index.js"
import { Button } from "../../components/simple/button/index.js"
import { PropTable } from "../../components/simple/Documentation/proptable/proptable.js"
import { Showcode } from "../../components/simple/Documentation/showcode.js"
export const ModalPage = () => {
    const overlayModalEnabled = useState(false)
    const data = `<Modal closeAction={() => alert("Close Action")}
    action={() => alert("Primary Action")}
    actionText="Primary"
    secondaryAction={() => alert("Secondary Action")}
    secondaryActionText="Secondary"
    header="Header Text">
    Modal Content
</Modal>`
    const data2 = `<Modal closeAction={() => alert("Close Action")}
    action={() => alert("Primary Action")}
    actionText="Primary"
    header="Header Text">
    Modal Content
</Modal>`
    const data3 = `<Modal destructive={true}
    closeAction={() => alert("Close Action")}
    action={() => alert("Primary Action")}
    actionText="Primary"
    secondaryAction={() => alert("Secondary Action")}
    secondaryActionText="Secondary"
    header="Destructive Header Text">
    Destructive Modal Content
</Modal>`
    const data4 = `<Button onClick={() => overlayModalEnabled[1](!overlayModalEnabled[0])}>Overlay Modal Example</Button>
    {overlayModalEnabled[0] && <Modal destructive={true}
            overlay={true}
            closeAction={() => overlayModalEnabled[1](!overlayModalEnabled[0])}
            action={() => alert("Primary Action")}
            actionText="Primary"
            secondaryAction={() => alert("Secondary Action")}
            secondaryActionText="Secondary"
            header="Destructive Header Text"
            >
            Destructive Modal Content
        </Modal>}`
    return(
        <Fragment>
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="flex-start">
                <Modal closeAction={() => alert("Close Action")}
                    action={() => alert("Primary Action")}
                    actionText="Primary"
                    secondaryAction={() => alert("Secondary Action")}
                    secondaryActionText="Secondary"
                    header="Header Text">
                    Modal Content
                </Modal>
            </Block>
            <Showcode data = {data} />
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="flex-start">
                <Modal closeAction={() => alert("Close Action")}
                    action={() => alert("Primary Action")}
                    actionText="Primary"
                    header="Header Text">
                    Modal Content
                </Modal>
            </Block>
            <Showcode data = {data2} />
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="flex-start">
                <Modal destructive={true}
                    closeAction={() => alert("Close Action")}
                    action={() => alert("Primary Action")}
                    actionText="Primary"
                    secondaryAction={() => alert("Secondary Action")}
                    secondaryActionText="Secondary"
                    header="Destructive Header Text">
                    Destructive Modal Content
                </Modal>
            </Block>
            <Showcode data = {data3} />
            <Block>
                <Button onClick={() => overlayModalEnabled[1](!overlayModalEnabled[0])}>Overlay Modal Example</Button>
            {overlayModalEnabled[0] && <Modal destructive={true}
                    overlay={true}
                    closeAction={() => overlayModalEnabled[1](!overlayModalEnabled[0])}
                    action={() => alert("Primary Action")}
                    actionText="Primary"
                    secondaryAction={() => alert("Secondary Action")}
                    secondaryActionText="Secondary"
                    header="Destructive Header Text"
                    >
                    Destructive Modal Content
                </Modal>}
            </Block>
            <Showcode data = {data4} />
            <PropTable descriptions={Modal.descriptions} ></PropTable>
        </Fragment>
    )
}