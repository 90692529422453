import styled from "styled-components";
import { SPACING_03 } from "../../spacing.js";
import { PRIMARY_BLUE } from "../../color.js";

export const CalendarDay = styled.div`
    text-align: center;
    padding: ${SPACING_03};
    border-radius: 4px;
    cursor: pointer;
    background: ${props => props.active ? PRIMARY_BLUE : 'none'};
    color: ${props => props.active ? "white" : 'black'};
    &:hover {
        background: ${PRIMARY_BLUE};
        color: white;
    }
`

export const Days = styled.div`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 100%;
`

export const CalendarDays = (props) =>
{
    let firstDayOfMonth = new Date(props.day.getFullYear(), props.day.getMonth(), 1);
    let firstDayWeekday = firstDayOfMonth.getDay(); //0 is Sunday, 1 is Monday
    let currentDays =[];

    for (let day = 0; day < 42; day++){//42 days for 6 weeks
        if(day === 0 && firstDayWeekday === 0) //
        {
            firstDayOfMonth.setDate(firstDayOfMonth.getDate() -7);
        }
        else if(day === 0)
        {
            firstDayOfMonth.setDate(firstDayOfMonth.getDate() + (day - firstDayWeekday));
        }
        else
        {
            firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1)
        }


        let calendarDay = {
            currentMonth: (firstDayOfMonth.getMonth() === props.day.getMonth()),
            date: (new Date(firstDayOfMonth)),
            number: firstDayOfMonth.getDate(),
            month: firstDayOfMonth.getMonth(),
            year: firstDayOfMonth.getFullYear(),
        }
        currentDays.push(calendarDay)
    }
    console.log(props.day)
    return(
        <Days>
            {
             currentDays.map((day, key) =>  <CalendarDay active={day.number === props.day.getDate()} key={`CalendarDay-${key}-${day.number}`}
                onClick={() => props.changeCurrentDay(day)}>
                    {day.number}
                </CalendarDay>)
            }
        </Days>
    )
}
export default CalendarDays;

//<div className={"calendarDay"+ (day.currentMonth ? " current": "") + (day.selected ? " selected" : "")} onClick={() => props.changeCurrentDay(day)}>
