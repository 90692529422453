import { Block } from '../../components/simple/layout/index.js'

export const HomePage = () => {
    const text = `import {Button}; from '@rneui/base'; \n const App = () => {
            return <Button title="Hello World" />;
        };`
    return (
        <>
        <h1>Overview</h1>
        
        <p>
            Trane components is a React library of components to base UI projects on that is cohesive with a common look and feel. These components are reusable and can be used by any project that is based in React, being easy to use by those who don’t understand web development, only having to change parameters to fit the situation. They are customizable, with parameters able to change the component to fit the situation as needed. This is meant to make the UI feel uniform and make development easier and faster for Trane’s react projects.<br/>
            The library is made up of three types of components: Foundation, Elements, and Modules. Foundation is components and elements such as color, used for styling and design.
            Elements are the base components to be used such as a Button or Calendar element. Modules are more complex elements that are made up of different element components to be used for a specific purpose, such as a sign in page.
        </p>
        <h2>
            Installation
            
        </h2>
        <h4>
            How to install Trane Components to start developing
        </h4>
        <p>
            
            First open VSCode or your preferred code editor or a console. <br/>
            Use the following command to clone the repository to your local device. You will have to sign in with your GitLab account and have access to the repository. <br/>
            <img src="Gitlab.png" id="gitlab"/> <br/>
            $git clone https://gitlab.tis.trane.com/traneconnect/trane-components.git <br/> 
            Switch into the trane-components folder and run $npm install <br/>
            You should now be able to run npm start to start the Trane Components library on your local device! <br/>
            You can use npm test to run the unit tests setup. <br/>            
        </p>
        <h2>
            Quick Start
        </h2>
            <p>
                
                
            </p>
        </>
    )
}
//{text}
/*
../../components/simple/button/index.js
*/
/*
        import {Button}; from '@rneui/base';
        const App = () => {
                return <Button title="Hello World" />;
            };
*/