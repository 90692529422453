import React, { useState } from "react"
import { node, string } from "prop-types"
import styled from 'styled-components'
import * as Color from '../../color.js'
import * as Spacing from '../../spacing.js'
import { MdInfo } from "react-icons/md/index.esm.js"

const TooltipStyles = styled.div`
    position: relative;
`

const TooltipPopup = styled.div`
    position: absolute;
    width: max-content;
    padding: ${Spacing.SPACING_03};
    background: ${props => (props.mode === "light") ? Color.HAIRLINE : Color.INPUT_BACKGROUND};
    border-radius: 6px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: 9px;
    left: calc(50% - 3px);
    transform: translate(-50%, -50%);
`

const TooltipArrow = styled.p`
    margin: 0;
    left: calc(50% - 4px);
    top: 34px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    position: absolute;
    border-top: 7px solid ${props => (props.mode === "light") ? Color.HAIRLINE : Color.INPUT_BACKGROUND};
`

const TooltipIcon = styled.p`
    color: ${props => (props.mode === "light") ? Color.TEXT_SECONDARY_2 : Color.ZEBRA_STRIPE_BG};
    margin: 0;
`

export const TooltipText = styled.p`
    font-family: ArialMT;
    font-size: 16px;
    color: ${props => props.color ? props.color : Color.WHITE};
    margin: 0;
`

export const Tooltip = props => {
  const { children, mode } = props
  const [show, setShow] = useState(false)

  const ChangeShow = () => {
      setShow(!show)
  }
  // TODO: add ability to detect width and screen width and calculate position of arrow.
  return(
    <TooltipStyles onMouseEnter={ChangeShow}
        onMouseLeave={ChangeShow}>
        {show && <TooltipPopup mode={mode ? mode : "light"}>
            {children && children}
            <TooltipArrow mode={mode ? mode : "light"} />
        </TooltipPopup>}
        <TooltipIcon mode={mode ? mode : "light"} >
            <MdInfo />
        </TooltipIcon>
    </TooltipStyles>
  )
}

Tooltip.propTypes = {
    /** Node to appear in popup message, optionally if you are passing a string, wrap the string in the TooltipText component exported in this file. */
    children: node.isRequired,
    /** Mode of app, example: "light", "dark" */
    mode: string,
}

TooltipText.propTypes = {
    /** Trane components color variable to be passed in */
    color:string
}

Tooltip.descriptions = {
    children: ["node","Needs a default","True",`Node to appear in popup message, optionally if you are passing a string, wrap the string in the TooltipText component exported in this file.`],
    mode: ["string","","False",`Mode of app, example: "light", "dark"`],
}
TooltipText.descriptions = {
    color: ["string","","False",`Trane components color variable to be passed in`],
}