import React, { Fragment, useEffect, useContext } from 'react'

import { Context } from './index.js'

const handleResize = dispatch => {
	dispatch({type: 'SET_RESIZE', payload: window.innerWidth})
}

const handleScroll = dispatch => {
	dispatch({type: 'SET_SCROLL', payload: window.scrollY})
}

const handleKey = (dispatch, key) => {
	dispatch({type: 'SET_KEY', payload: key.code})
	dispatch({type: 'SET_KEY', payload: ''})
}

export const WithEventListeners = props => {
	const [state, dispatch] = useContext(Context)

	useEffect(() => {
		window.addEventListener('resize', () => handleResize(dispatch))
		window.addEventListener('scroll', () => handleScroll(dispatch))
		document.addEventListener('keydown', key => handleKey(dispatch, key), false)
		handleResize(dispatch)
		handleScroll(dispatch)
		return( () => {
			window.removeEventListener('resize', () => handleResize(dispatch))
			window.removeEventListener('scroll', () => handleScroll(dispatch))
			document.removeEventListener('keydown', key => handleKey(dispatch, key), false)
		})
	}, [dispatch])

	return(
		<Fragment>
			{props.children}
		</Fragment>
	)
}
