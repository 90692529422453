import { Fragment } from "react"
import { Tab } from "../../components/simple/tab/index.js"
import { Block } from "../../components/simple/layout/index.js"
import { MdWarning } from "react-icons/md/index.esm.js"
import * as Color from '../../components/color.js'
import { PropTable } from "../../components/simple/Documentation/proptable/proptable.js"
import { Showcode } from "../../components/simple/Documentation/showcode.js"

const data = [
    {
        name: "Default"
    },
    {
        name: "Default 2",
        action: key => alert("optional callback action" + key),
    },
    {
        name: "Disabled",
        action: () => alert("optional callback action"),
        status: "disabled"
    },
    {
        icon: <MdWarning />,
        name: "Destructive",
        action: () => alert("optional callback action"),
        status: "destructive"
    }
]

export const TabPage = () => {
    const dataShow = `<Tab data={data} />`
    const dataShow2 = `<Tab dark data={data} />`
    return(
        <Fragment>
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="flex-start">
                <Tab data={data} />
            </Block>
            <Showcode data = {dataShow} />
            <Block display="flex" width="calc(100% - 40px)" padding="20px" background={Color.DARK_GREY_BG_2} justify="space-evenly">
                <Tab dark data={data} />
            </Block>
            <Showcode data = {dataShow2} />
            <PropTable descriptions={Tab.descriptions} ></PropTable>
        </Fragment>
    )
}