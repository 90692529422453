import { Fragment } from "react"
import { AuthForgotPassword, AuthSignIn, AuthSignUp } from "../../components/advanced/auth/index.js"

export const AuthPage = () => {
    return(
        <Fragment>
            <AuthSignIn />
            <AuthSignUp />
            <AuthForgotPassword />
        </Fragment>
    )
}