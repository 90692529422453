import React, { useState, Fragment } from 'react'
import { Block } from '../layout/index.js'
import { TextInput } from '../input/index.js'
import * as Color from '../../color.js'
import * as Spacing from '../../spacing.js'
import { MdApps } from "react-icons/md/index.esm.js"
import { FaChevronDown, FaChevronUp, FaCaretDown, FaCaretUp } from "react-icons/fa/index.esm.js"
import { FaArrowsUpDown, FaCaretRight } from "react-icons/fa6/index.esm.js"
import { BiSearchAlt } from "react-icons/bi/index.esm.js"
import { Icon } from '../typography/index.js'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'

export const AppLogo = styled.p`
    font-size: 20px;
    font-weight: bold;
    color: ${Color.WHITE};
    margin: 0;
    cursor: pointer;
`

export const AppLogoArrow = styled.p`
    font-weight: bold;
    color: ${Color.WHITE};
    margin: 0 ${Spacing.SPACING_04} 0 ${Spacing.SPACING_02};
    font-size: 10px;
    align-self: center;
    cursor: pointer;
`

export const AppNameText = styled.h1`
    font-family: neue-haas-unica, sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: ${Color.WHITE};
    margin: 0;
`

export const AppNavSelectedTitle = styled.h2`
    color: white;
    font-weight: 700;
    font-size: 16px;
    margin: 0;
`

export const AppNavSelectedSubtitle = styled.p`
    color: white;
    font-size: 14px;
    margin: 0
`

export const AppNavSelectedIcon = styled.p`
    color: white;
    font-size: 14px;
    margin: 0;
`

export const AppNavSelectedDropdown = styled.div`
    position: absolute;
    padding: ${Spacing.SPACING_05} ${Spacing.SPACING_05} ${Spacing.SPACING_03} ${Spacing.SPACING_05} ;
    width: calc(100% - ${Spacing.SPACING_08});
    left: 0;
    top: 60px;
    background: black;
    z-index: 1;
    box-shadow: 0 0 1px 1px white inset;
`

export const OptionText = styled.p`
    font-family: ArialMT;
    font-size: 14px;
    color: ${Color.SNOW};
    margin: 0 0 0 ${Spacing.SPACING_07};
    cursor: pointer;
`

export const OptionIcon = styled.p`
    font-family: ArialMT;
    font-size: 14px;
    color: ${Color.SNOW};
    margin: 0 0 0 ${Spacing.SPACING_02};
    cursor: pointer;
`

export const OptionDropdownText = styled.p`
    font-family: ArialMT;
    font-size: 14px;
    margin: 0;
    cursor: pointer;
    padding: ${Spacing.SPACING_03};
    border-bottom: 1px solid ${Color.DISABLED_BG};
    &:last-child {
        border: none;
    }
`

export const OptionDropdownTitle = styled.h3`
    font-size: 16px;
    margin: ${props => props.margin ? props.margin : '0'};
    padding: ${Spacing.SPACING_04} ${Spacing.SPACING_03};
`

export const OptionDropdownAction = styled.p`
    font-size: 14px;
    margin: 0;
    color: ${Color.PRIMARY_BLUE};
    cursor: pointer;
    padding: ${Spacing.SPACING_04} ${Spacing.SPACING_03};
`

export const TopOptionNotification = styled.p`
    margin: 0;
    padding: ${Spacing.SPACING_01};
    color: ${Color.WHITE};
    background: ${Color.PRIMARY_BLUE};
    border-radius: ${Spacing.SPACING_03};
    position: absolute;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 8px;
    height: 8px;
    right: 12px;
    top: -4px;
    font-size: 10px;
`

export const PopoutAppText = styled.p`
    color: ${Color.SNOW};
    margin: 0;
    font-size: 12px;
`

export const OptionAppIcon = styled.p`
    color: ${props => props.selected ? Color.WHITE : Color.DM_GREY};
    margin: 0 0 0 ${Spacing.SPACING_05};
`

export const OptionAppText = styled.p`
    color: ${props => props.selected ? Color.WHITE : Color.DM_GREY};
    margin: 0 0 0 ${Spacing.SPACING_05};
`

export const TopNav = props => {
    return(
        <Block width="calc(100% - 40px)"
            padding={`${Spacing.SPACING_03} ${Spacing.SPACING_06}`}
            display="flex"
            align="center"
            justify="space-between"
            background={Color.BLUE_800}>
            <Block display="flex">
                <AppLogo onClick={() => props.setPopout(!props.popout)}><MdApps /></AppLogo>
                <AppLogoArrow onClick={() => props.setPopout(!props.popout)}>{props.popout ? <FaChevronUp /> : <FaChevronDown />}</AppLogoArrow>
                <AppNameText>{props.data.app.title}</AppNameText>
            </Block>
            <Block maxWidth="320px">
                {/* Add background color option to textinput that will override mode */}
                <TextInput placeholder="search" icon={<BiSearchAlt />} />
            </Block>
            {props?.data?.topnav?.options && <Block display="flex" justify="flex-end">
                {/* TODO: Change from Text to Icon when it gets too small for text */}
                {props?.data?.topnav?.options?.map((option, key) => <TopNavDropdown key={`TopNav-Option-${key}`} data={option} />)}
            </Block>}
        </Block>
    )
}

export const TopNavDropdown = props => {
    const [open, setOpen] = useState(false)
    let navigate = useNavigate()

    const Click = () => {
        if(props?.data?.onClick) {
            props.data.onClick()
        }
        if(props?.data?.route) {
            navigate(props?.data?.route)
        }
        if((props?.data?.options) || (props?.data?.header) || (props?.data?.footer)) {
            setOpen(!open)
        }
    }
    return (
        <Block display="flex" width="auto" align="center">
            {props?.data?.notifications && <TopOptionNotification>
                {props.data.notifications.length}
            </TopOptionNotification>}
            {((props?.data?.options) || (props?.data?.header) || (props?.data?.footer))
                ? <Fragment>
                    <OptionText onClick={Click}>
                        {props.data.name}
                    </OptionText>
                    <OptionIcon>
                        {open ? <FaCaretUp /> : <FaCaretDown />}
                    </OptionIcon>
                </Fragment>
                : <OptionText onClick={Click}>
                        {props.data.name}
                </OptionText>
            }
            {open && <Block width="max-content" position="absolute" top="24px" right="0px" background={Color.WHITE} border={`1px solid ${Color.DISABLED_BG}`} radius="5px">
                {props?.data?.header && <Block display="flex" justify="space-between" align="center" borderBottom={`1px solid ${Color.DISABLED_BG}`}>
                    {props?.data?.header?.title && <OptionDropdownTitle margin={props?.data?.header?.action && `0 ${Spacing.SPACING_12} 0 0`}>
                        {props?.data?.header?.title}
                    </OptionDropdownTitle>}
                    {props?.data?.header?.action && <OptionDropdownAction onClick={() => props?.data?.header?.action?.onClick()}>
                        {props?.data?.header?.action?.name}
                    </OptionDropdownAction>}
                </Block>}
                <Block padding={(props?.data?.header || props?.data?.footer) && `${Spacing.SPACING_05} 0`}>
                    {props?.data?.options
                        ? props?.data?.options?.map((option, key) => <OptionDropdownText key={`TopNav-Option-Dropdown-Option-${key}`}>
                            {option.name}
                        </OptionDropdownText>)
                    : <OptionDropdownText>
                        {props?.data?.empty}
                    </OptionDropdownText>}
                </Block>
                {props?.data?.footer && <Block display="flex" justify="space-between" align="center" borderTop={`1px solid ${Color.DISABLED_BG}`}>
                    {props?.data?.footer?.title && <OptionDropdownTitle margin={props?.data?.footer?.action && `0 ${Spacing.SPACING_12} 0 0`}>
                        {props?.data?.footer?.title}
                    </OptionDropdownTitle>}
                    {props?.data?.footer?.action && <OptionDropdownAction onClick={() => props?.data?.footer?.action?.onClick()}>
                        {props?.data?.footer?.action?.name}
                    </OptionDropdownAction>}
                </Block>}
            </Block>}
        </Block>
        
    )
}

export const AppNav = props => {
    let navigate = useNavigate()
    const Click = (onClick, route, key) => {
        if(onClick) {
            onClick()
        }
        if(route) {
            navigate(route)
        }
        if(key) {
            if(key === optionSelected) {
                setOptionSelected(false)
            } else {
                setOptionSelected(key)
            }
        }
    }
    const [selectedOpen, setSelectedOpen] = useState(false)
    const [optionSelected, setOptionSelected] = useState(false)
    return(
        <Block display="inline-block"
            width="auto"
            background={Color.DARK_GREY_BG_3}
            borderRight="1px solid lightgray"
            overflow="scroll"
            height="100vh"
            flexShrink="0">
            {props.data.app.selected && <Block display="flex" 
                width={`calc(100% - ${Spacing.SPACING_07})`} 
                align="center" 
                justify="space-between"
                cursor="pointer"
                padding={Spacing.SPACING_04}
                borderBottom="1px solid white"
                onClick={() => setSelectedOpen(!selectedOpen)}>
                <Block margin={`0 ${Spacing.SPACING_04} 0 0`}>
                    {props.data.app.selected.title && <AppNavSelectedTitle>{props.data.app.selected.title}</AppNavSelectedTitle>}
                    {props.data.app.selected.subtitle && <AppNavSelectedSubtitle>{props.data.app.selected.subtitle}</AppNavSelectedSubtitle>}
                </Block>
                <AppNavSelectedIcon>
                    <FaArrowsUpDown />
                </AppNavSelectedIcon>
            </Block>}
            {selectedOpen && (props.data.app.selectedoptions.length > 0) && <AppNavSelectedDropdown>
                    {props.data.app.selectedoptions.map((selectedoption, key) => <Block key={`AppNav-Selected-Option-${key}`} margin={`0 0 ${Spacing.SPACING_04} 0`}>
                        {selectedoption.title && <AppNavSelectedTitle>{selectedoption.title}</AppNavSelectedTitle>}
                        {selectedoption.subtitle && <AppNavSelectedSubtitle>{selectedoption.subtitle}</AppNavSelectedSubtitle>}
                    </Block>)}
                </AppNavSelectedDropdown>}
            {props.data.app.options && props.data.app.options.map((app, key) => 
                <Block cursor="pointer" width="auto" key={`Navigation-AppNav-${app.title}-${key}`}>
                    <Block onClick={() => Click(app.onClick ? app.onClick : null, app.route ? app.route : null, key)} 
                        background={(optionSelected === key) ? `${Color.HAIRLINE}` : null}
                        padding={Spacing.SPACING_05}
                        width="auto" justify="flex-start" display="flex">
                        {(app.options?.length > 0) && <Block position="absolute" 
                            left={Spacing.SPACING_03}
                            width="auto"
                            color={Color.DM_GREY}>
                            {(optionSelected !== key) ? <FaCaretRight /> : <FaCaretDown />}
                            </Block>}
                        {app.icon && <OptionAppIcon selected={optionSelected === key}>
                            {app.icon}
                        </OptionAppIcon>}
                        {app.title && <OptionAppText selected={optionSelected === key}>
                            {app.title}
                        </OptionAppText>}
                    </Block>
                    {app.options && (app.options?.length > 0) && (optionSelected === key) && <Block>
                        {app.options?.map((option, optionkey) => <Block key={`Navigation-AppNav-Option-${option.title}-${optionkey}`}
                            onClick={() => Click(option.onClick ? option.onClick : null, option.route ? option.route : null, null)} 
                            background={Color.TEXT_HOVER_2}
                            padding={`${Spacing.SPACING_03} 0 ${Spacing.SPACING_03} ${Spacing.SPACING_05}`}>
                            <OptionAppText>{option.title}</OptionAppText>
                        </Block>)}
                    </Block>}
                </Block>
            )}
        </Block>
    )
}

export const AppNavPopout = props => {
    // add drag icon to resize popout
    let navigate = useNavigate()
    const Click = (onClick, route, url) => {
        if(onClick) {
            onClick()
        }
        if(route) {
            navigate(route)
        }
        if(url) {
            window.location.href = url
        }
    }
    return(
        <Block z={1}
            display="inline-block"
            width="auto"
            background={Color.BLUE_800}
            padding={Spacing.SPACING_02}>
            {props.data?.popout?.map((app, key) => <Block key={`AppNavPopout-App-${app.title}-${key}`}
                textAlign="center"
                width="auto"
                padding={Spacing.SPACING_02}
                radius={Spacing.SPACING_02}
                cursor="pointer"
                margin={`0 0 ${Spacing.SPACING_02} 0`}
                background={(props.data.app.title === app.title) && Color.BLUE_600}
                onClick={() => Click(app.onClick, app.route, app.url)}>
                {app.icon && <Icon width="26px" height="26px" fill={Color.SNOW} margin={`0 auto ${Spacing.SPACING_01} auto`}>{app.icon}</Icon>}
                {app.title && <PopoutAppText>{app.title}</PopoutAppText>}
            </Block>)}
        </Block>
    )
}



export const Navigation = props => {
    const [popoutOpen, setPopoutOpen] = useState(false)
    return(
        <Block height="100vh">
            <TopNav data={props.data} popout={popoutOpen} setPopout={setPopoutOpen} />
            <Block display="flex">
                {props.data.popout && popoutOpen && <AppNavPopout data={props.data} />}
                <AppNav data={props.data}/>
                <Block height="100vh" overflow="scroll">
                    {props.children}
                </Block>
            </Block>
        </Block>
    )
}