import { useLocation, useNavigate } from 'react-router-dom'

export const NavigationArray = {
    selected: {
        title: "Finneytown High School",
        subTitle: "8916 Fontainbleu Ter",
        options: [
            {
                title: "School 1",
                subTitle: "Address 1",
                onClick: () => console.log("Test 1")
            },
            {
                title: "School 2",
                subTitle: "Address 2",
                onClick: () => console.log("Test 2")
            },
            {
                title: "School 3",
                subTitle: "Address 3",
                onClick: () => console.log("Test 3")
            },
        ]
    },
    options: [
        {
            title: "Trane.com",
            icon: "",
            onClick: "",
            options: [
                {
                    title: "",
                    onClick: "",
                    color: "",
                    options: [
                        {
                            title: "",
                            onClick: "",
                            color: ""
                        }
                    ]
                }
            ],
        },
    ]
}

export const HOME = '/'
export const ACCORDION = '/accordion'
export const AUTH = '/auth'
export const CARD = '/card'
export const COLOR = '/color'
export const TYPOGRAPHY = '/typography'
export const SPACING = '/spacing'
export const GRID = '/grid'
export const BUTTON = '/button'
export const BREADCRUMB = '/breadcrumb'
export const CALENDAR_PICKER = '/calendar-picker'
export const CHAT = '/chat'
export const INPUT = '/input'
export const LIST = '/list'
export const MODAL = '/modal'
export const NOTIFICATION = '/notification'
export const PROGRESS = '/progress'
export const TAB = '/tab'
export const TABLE = '/table'
export const TOOLTIP = '/tooltip'
export const SPINNER = '/spinner'
export const TIME_PICKER = '/timepicker'

