import React, { useState } from "react"
import { string, node, arrayOf, shape, func, bool } from "prop-types"
import styled from 'styled-components'
import * as Color from '../../color.js'
import * as Spacing from '../../spacing.js'


const TabStyles = styled.section`
    width: 100%;
    position: relative;
    display: flex;
`

const TabButton = styled.button`
    border: 1px solid ${props => TabTheme[props.dark ? "dark" : "light"][props.status].border};
    background: ${props => TabTheme[props.dark ? "dark" : "light"][props.status].background};
    color: ${props => TabTheme[props.dark ? "dark" : "light"][props.status].color};
    border-radius: 6px;
    padding: ${Spacing.SPACING_03} ${Spacing.SPACING_07};
    font-family: ArialMT;
    font-size: 16px;
    letter-spacing: 0.32px;
    margin: 0 ${Spacing.SPACING_06} 0 0;
    cursor: ${props => (props.status === "disabled") ? "not-allowed" : "pointer"};
    display: flex;
    &:hover {
        border: 1px solid ${props => TabTheme[props.dark ? "dark" : "light"][props.status].hoverBorder};
        background: ${props => TabTheme[props.dark ? "dark" : "light"][props.status].hoverBackground};
        color: ${props => TabTheme[props.dark ? "dark" : "light"][props.status].hoverColor};
    }
    &:last-child {
        margin: 0;
    }
`

const TabIcon = styled.p`
    height: 18px;
    width: 18px;
    font-size: 18px;
    margin: 0 ${Spacing.SPACING_02} 0 0;
`

export const TabTheme = {
    light: {
        active: {
            background: Color.PRIMARY_BLUE,
            color: Color.WHITE,
            border: Color.PRIMARY_BLUE,
            hoverBackground: Color.PRIMARY_BLUE,
            hoverColor: Color.WHITE,
            hoverBorder: Color.PRIMARY_BLUE
        },
        enabled: {
            background: Color.WHITE,
            color: Color.PRIMARY_BLUE,
            border: Color.TEXT_HOVER_1,
            hoverBackground: Color.WHITE,
            hoverColor: Color.PRIMARY_BLUE,
            hoverBorder: Color.TEXT_HOVER_1
        },
        disabled: {
            background: Color.DISABLED_BG,
            color: Color.DISABLED_TEXT,
            border: Color.DISABLED_BG,
            hoverBackground: Color.DISABLED_BG,
            hoverColor: Color.DISABLED_TEXT,
            hoverBorder: Color.DISABLED_BG
        },
        destructive: {
            background: Color.LIGHT_PINK_BG,
            color: Color.ERROR_RED,
            border: Color.ERROR_RED,
            hoverBackground: Color.LIGHT_PINK_BG,
            hoverColor: Color.ERROR_RED,
            hoverBorder: Color.ERROR_RED
        }
    },
    dark: {
        active: {
            background: Color.PRIMARY_BLUE,
            color: Color.WHITE,
            border: Color.PRIMARY_BLUE,
            hoverBackground: Color.PRIMARY_BLUE,
            hoverColor: Color.WHITE,
            hoverBorder: Color.PRIMARY_BLUE
        },
        enabled: {
            background: Color.HAIRLINE,
            color: Color.ZEBRA_STRIPE_BG,
            border: Color.ZEBRA_STRIPE_BG,
            hoverBackground: Color.HAIRLINE,
            hoverColor: Color.ZEBRA_STRIPE_BG,
            hoverBorder: Color.ZEBRA_STRIPE_BG
        },
        disabled: {
            background: Color.DARK_GREY_BG_2,
            color: Color.TEXT_HOVER_2,
            border: Color.DARK_GREY_BG_2,
            hoverBackground: Color.DARK_GREY_BG_2,
            hoverColor: Color.TEXT_HOVER_2,
            hoverBorder: Color.DARK_GREY_BG_2
        },
        destructive: {
            background: Color.DARK_GREY_BG_1,
            color: Color.ZEBRA_STRIPE_BG,
            border: Color.DARK_ERROR_RED,
            hoverBackground: Color.DARK_GREY_BG_1,
            hoverColor: Color.ZEBRA_STRIPE_BG,
            hoverBorder: Color.DARK_ERROR_RED
        }
    }
}

// TODO: Add vertical and content tabs
// TODO: Verify colors on tabs after MCB merges

export const Tab = props => {
    const { data, dark } = props
    const [activeTab, setActiveTab] = useState(0)

    const TabAction = (key, action = null) => {
        setActiveTab(key)
        if(action) {
            action(key)
        }
    }

    return(
        <TabStyles>
            {data && data.map((tab, key) => {
                let active = (activeTab === key) ? true : false
                const status = active ? "active" : (tab.status ? tab.status : "enabled")

                return(
                    <TabButton key={`Tab-${tab.name}-${key}`}
                        status={status}
                        onClick={() => TabAction(key, tab.action && tab.action)}
                        dark={dark}
                        disabled={tab.status === "disabled"}
                        active={activeTab === key}>
                        {tab.icon && <TabIcon>{tab.icon}</TabIcon>}{tab.name}
                    </TabButton>
                )
            })}
            
        </TabStyles>
    )
}

Tab.propTypes = {
    /** Array of objects which displays the tabs, 
     * icon is node to appear before name string inside of button, 
     * name is string inside of tab, usually text, 
     * action is callback upon pressing tab, and status is visual status of tab */
    data: arrayOf(shape({
        icon: node,
        name: string.isRequired,
        action: func,
        status: string
    })).isRequired,
    /** Is the app in dark mode? */
    dark: bool
}
Tab.descriptions = {
     // [Type, default, required, descriptions]
     data: ["Object(icon(node), name(string), action(func), status(string))","Needs a default","True", `Array of objects which displays the tabs, icon is node to appear before name string inside of button, name is string inside of tab, usually text, action is callback upon pressing tab, and status is visual status of tab`],
     dark: ["bool","Needs a default","True",`Is the app in dark mode?`],
 
}