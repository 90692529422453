import styled from 'styled-components'

export const Icon = styled.p`
    padding: ${props => props.padding && props.padding};
    color: ${props => props.color && props.color};
    fill: ${props => props.fill && props.fill};
    stroke: ${props => props.stroke && props.stroke};
    margin: ${props => props.margin && props.margin};
    cursor: ${props => props.cursor && props.cursor};
    text-align: ${props => props.textAlign && props.textAlign};
    width: ${props => props.width && props.width};
    height: ${props => props.height && props.height};
    svg {
        color: ${props => props.color && props.color};
        fill: ${props => props.fill && props.fill};
        stroke: ${props => props.stroke && props.stroke};
    }
    g {
        use {
            color: ${props => props.color && props.color};
            fill: ${props => props.fill && props.fill};
            stroke: ${props => props.stroke && props.stroke};
        }
    }
`