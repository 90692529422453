import { Fragment } from "react"
import { Breadcrumb } from "../../components/simple/breadcrumb/index.js"
import { Block } from "../../components/simple/layout/index.js"
import * as AppRoutes from '../../components/routes.js'
import { PropTable } from "../../components/simple/Documentation/proptable/proptable.js"
import { Showcode } from "../../components/simple/Documentation/showcode.js"

export const BreadcrumbPage = () => {
    const data = `<Breadcrumb data={[
        {url: AppRoutes.HOME, name: "Home"},
        {url: AppRoutes.BREADCRUMB, name: "Breadcrumb"},
    ]} />`
    const data2 = `<Breadcrumb data={[
        {url: AppRoutes.HOME, name: "Home"},
        {url: AppRoutes.BUTTON, name: "Button"},
    ]} />`
    return(
        <Fragment>
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="flex-start" height="0px">
            <label>Redirect to Same Page</label>
                <Breadcrumb data={[
                    {url: AppRoutes.HOME, name: "Home"},
                    {url: AppRoutes.BREADCRUMB, name: "Breadcrumb"},
                ]} />
                <Showcode data = {data} />
            </Block>
            

            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="flex-start" height="15px">
            <label>Redirect to Button Page</label>
            <Breadcrumb data={[
                    {url: AppRoutes.HOME, name: "Home"},
                    {url: AppRoutes.BUTTON, name: "Button"},
                ]} />
                <Showcode data = {data2} />
                </Block>
            <PropTable descriptions={Breadcrumb.descriptions} ></PropTable>
        </Fragment>
    )
}