import {string } from "prop-types"
import {useState} from "react"
import {CiClock2} from "react-icons/ci/index.esm.js"
import { Block } from '../../simple/layout/index.js'
import { TextInput } from '../../simple/input/index.js'
import {MilitaryTime, Minutes, DurationHours, StandardTime} from './constants.js'
import styled from "styled-components"

//onchange to export

/*function hour()
{
    var selectMenu = document.getElementById('HourSelect')
    var options = "";
    for (let i=0; i<25; i++)
    {
        options = options + "<br> " + selectMenu.options.add(createOption(i))
    }
}*/

const TimeBox= styled.fieldset`
    position:relative;
    padding:1.5em;
    border: 0;
`
const TimeSelect = styled.div`
    position: absolute;
    display: flex;
`
export const StandardTimePicker = props =>
{
    const {name, title} = props
    const [selectedHour, setSelectedHour] = useState("1")
    const [selectedMin, setSelectedMin] = useState("00")
    const [selectedPeriod, setSelectedPeriod] = useState("AM")
    const [selectedTimeStandard, setSelectedTimeStandard] = useState()

    return(
    <>
            <div>
                <label> {title} 
                    <Block width="calc(100%)" maxWidth="125px" position="relative">
                    <TextInput resize="none" type="time" icon = {<CiClock2/>} value={selectedTimeStandard} onChange= {e=> {setSelectedTimeStandard(e.target.value); setSelectedHour(e.target.value.substring(0,2) <= 12 ? e.target.value.substring(0,2) : e.target.value.substring(0,2)-12); setSelectedMin (e.target.value.substring(3,5)); setSelectedPeriod(e.target.value.substring(0,2) >= 12 ? "PM" : "AM") } } />
                        <TimeSelect>
                        <select name={name} id={"HourSelect"} value = {selectedHour}
                            onChange={e => {
                                setSelectedHour(e.target.value); 
                                setSelectedTimeStandard(selectedPeriod === "PM" ? "" + eval(parseInt(e.target.value) + "+12") + ":" + selectedMin : e.target.value + ":" + selectedMin);
                            } }>                    
                                {
                                    StandardTime.map((value,key) => <option key={`standardtime-${key}-${value}`}>{value}</option>)
                                }
                            </select>
                            <select name={name} value = {selectedMin}
                            onChange={e =>{
                                setSelectedTimeStandard(selectedHour + ":" + e.target.value); setSelectedMin(e.target.value)} }>
                                {
                                    Minutes.map((value,key) => <option key= {`minutes-${key}-${value}`}> {value} </option>)
                                } 

                            </select>
                            
                            <select name={name} value = {selectedPeriod}
                            onChange={e => {setSelectedPeriod(e.target.value); setSelectedTimeStandard(e.target.value === "PM" ? "" + eval(parseInt(selectedHour) + "%12+12") + ":" + selectedMin : selectedHour + ":" + selectedMin )} }>
                                <option> AM </option>
                                <option> PM </option>
                            </select>
                        </TimeSelect>
                        
                    </Block>
                </label>
                <div>Spacing</div>
                <div>Standard Time: {selectedTimeStandard}</div>
                
            </div>
        </>
    )
}

export const DurationPicker = props =>
{
    const {name, title} = props
    const [selectedHourDuration, setSelectedHourDuration] = useState("0")
    const [selectedMinDuration, setSelectedMinDuration] = useState("0")

    return (
        <>
        
        <div>
                <label>{title}</label>
                <Block position="relative">
                    <input onChange ={e =>setSelectedHourDuration(e.target.value)} value={selectedHourDuration + " hours" } /> : <input value={selectedMinDuration + " minutes"} />
                    <p></p>
                    <select name={name} id={"HourSelect"} value = {selectedHourDuration}
                    onChange={e =>setSelectedHourDuration(e.target.value)}>                    
                    {
                        DurationHours.map((value, key) => <option key={`durationhours-${key}-${value}`}>{value}</option>)
                    }
                    </select>
                    <select name={name}  value = {selectedMinDuration}
                    onChange={e =>setSelectedMinDuration(e.target.value)}>
                    {
                        Minutes.map((value,key) => <option key= {`minutes-${key}-${value}`}> {value} </option>)
                    }
                </select>
                </Block>
                <p> Selected Duration is: {selectedHourDuration} hour and {selectedMinDuration} minute(s)</p>
            </div>
        </>
    )
}

export const MilitaryPicker = props => {
    const {name, title} = props
    const [selectedHour, setSelectedHour] = useState("1")
    const [selectedMin, setSelectedMin] = useState("00")
    const [selectedPeriod, setSelectedPeriod] = useState("AM")
    const [selectedTimeMilitary, setSelectedTimeMilitary] = useState()

    return (
        <>
            <div>
                <label> {title} 
                    <Block width="calc(100%)" maxWidth="125px" position="relative">
                    <TextInput type="time" icon = {<CiClock2/>} value={selectedTimeMilitary} onChange= {e=> {setSelectedTimeMilitary(e.target.value); setSelectedHour(e.target.value.substring(0,2)); setSelectedMin (e.target.value.substring(3,5)) } } />
                        <TimeSelect>
                        <select name={name} id={"HourSelect"} value = {selectedHour}
                            onChange={e => {setSelectedHour(e.target.value); setSelectedTimeMilitary(e.target.value + ":" + selectedMin)} }>                    
                                {
                                    MilitaryTime.map((value,key) => <option key={`militarytime-${key}-${value}`}>{value}</option>)
                                }
                            </select>
                            <select name={name} value = {selectedMin}
                            onChange={e =>{
                                setSelectedTimeMilitary(selectedHour + ":" + e.target.value); setSelectedMin(e.target.value)} }>
                                {
                                    Minutes.map((value,key) => <option key= {`minutes-${key}-${value}`}> {value} </option>)
                                } 

                            </select>
                        </TimeSelect>
                        
                    </Block>
                </label>
                <div>Spacing</div>
                <div>Military Time: {selectedTimeMilitary}</div>                
            </div>
        </>
    )
}
//<div>Separate Hour and Min: {selectedHour}:{selectedMin}</div>

StandardTimePicker.propTypes = {
    /** A string of the type of Time Picker, such as Duration, 12 or 24 */
    type: string.isRequired,
    /** Name of component */
    name: string,
    title: string
}
DurationPicker.propTypes = {

}
MilitaryPicker.propTypes = {
    
}

StandardTimePicker.descriptions = {
    type: ["string","","False",`Mode of app, example: "light", "dark"`],
    name: [],
    title: []
}
DurationPicker.descriptions = {

}
MilitaryPicker.descriptions = {

}