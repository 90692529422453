import styled from "styled-components";
import { useState } from "react";
import { Block } from '../layout/index.js'
import { Button } from '../button/index.js'
import { DateField } from "../input/datefield.js"
import { Card } from "../card/index.js"
import CalendarDays from "./calendardays.js";

import { FaCaretLeft, FaCaretRight } from "react-icons/fa6/index.esm.js"
import { YEARS, MONTHS, WEEKDAY } from "./constants.js";
import { SPACING_06, SPACING_07, SPACING_10 } from "../../spacing.js";
import { LIGHT_BORDER } from '../../color.js'

export const PickerWrapper = styled.div`
    border: 1px solid blue;
    border-radius: 8px;
    width: 500px;
`

export const CalendarBox = styled.div`
`

export const CalendarSelect = styled.select`
    border: none;
    cursor: pointer;
    border-radius: 8px;
    font-weight: 700;
    text-align: right;
    &:first-child {
        margin-right: ${SPACING_07};
    }
    &:hover {
        border: 1px solid ${LIGHT_BORDER};
    }
`

export const CalendarHeader = styled.h2`
    width: 100%;
    display: flex;
    align-items: center;
`

export const DayHeader = styled.div`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 100%;
`

export const Weekday = styled.div`
    text-align: center;
`

export const Calendar = props =>
{
    const {value, onChange} = props

    const changeCurrentDay = (day) =>
    {
        const tempDay = new Date(day.year, day.month, day.number)
        onChange(tempDay)
    }

    return(
        <Block padding={`0 ${SPACING_07}`} width={`calc(100% - ${SPACING_10})`}>
            <DayHeader>
                {
                    WEEKDAY.map((WEEKDAY, key) => {
                        return <Weekday key={`Calendar-weekday-${key}`}>{WEEKDAY}</Weekday>
                    })
                }
            </DayHeader>
            <CalendarDays day={value} changeCurrentDay={changeCurrentDay}/>
        </Block>
    )
}

export const CalendarRangeHeader = props =>
{
    const {fromDay, toDay} = props
    //const [currentDay, setCurrentDay] = useState(date ? date: new Date())
    return(
        <CalendarHeader>
            <h2>{MONTHS[fromDay.getMonth()]} {fromDay.getDate()} {fromDay.getFullYear()} -  {MONTHS[toDay.getMonth()]} {toDay.getDate()} {toDay.getFullYear()}</h2>
        </CalendarHeader>
    )
}

export const CalendarBar = props =>
{
    const {value, onChange} = props

    const changeCurrentMonthBack = () => {
        const tempDay = new Date(value.setMonth(value.getMonth() - 1))
        onChange(tempDay)
    }
    const changeCurrentMonthForward = () => {
        const tempDay = new Date(value.setMonth(value.getMonth() + 1))
        onChange(tempDay)
    }
    const changeMonth = (month) =>
    {
        const tempDay =  new Date(value.setMonth(month))
        onChange(tempDay)
    }
    const changeYear = (year) =>
    {
        const tempDay =  new Date(value.setFullYear(year))
        onChange(tempDay)
    }

    return(
        <Block>
            <Block display="flex" justify="center" padding={`${SPACING_07} 0`} borderBottom={`1px solid ${LIGHT_BORDER}`}>
                <DateField width="auto" label="Date" value={value} onChange={onChange} />
            </Block>
            <Block display="flex" justify="space-between" padding={SPACING_07} width={`calc(100% - ${SPACING_10})`}>
                <Button onClick={changeCurrentMonthBack} size="icon" type="secondary"><FaCaretLeft/></Button>
                <Block width="auto" display="flex">
                    <CalendarSelect value={MONTHS[value.getMonth()]}
                        onChange={ e => {
                            changeMonth(MONTHS.indexOf(e.target.value));
                        }}> 
                        {MONTHS.map((value,key) => <option key={`months-${key}-${value}`}>{value}</option>)}
                    </CalendarSelect>
                    <CalendarSelect value={value.getFullYear()}
                        onChange={ e => {
                            changeYear(e.target.value)
                        }}>
                        {YEARS.map((value,key) => <option key={`months-${key}-${value}`}>{value}</option>)}
                    </CalendarSelect>
                </Block>
                <Button onClick={changeCurrentMonthForward} size="icon" type="secondary"><FaCaretRight/></Button>
            </Block>
        </Block>
    )
}

export const CalendarPicker = props =>
{
    //const {} = props
    const { value, onChange, action, cancel } = props
    //onChange = {setCurrentDay(new Date())}
    return(
        <Card>
            <CalendarBar value={value} onChange={onChange}/> 
            <Calendar value={value} onChange={onChange}/>
            {(action || cancel) && <Block display="flex" padding={SPACING_07} justify="flex-end" width={`Calc(100% - ${SPACING_10})`}>
                {cancel && <Button type="secondary" onClick={cancel}>Cancel</Button>}
                {action && <Block width="auto" margin={`0 0 0 ${SPACING_06}`}><Button onClick={action}>Done</Button></Block>}
            </Block>}
        </Card>
    )
}

// TODO: rework this like the other two
export const DurationCalendarPicker = props =>
{
    const [fromDay, setFromDay] = useState(new Date(2022,3,2))
    const [toDay, setToDay] = useState(new Date(2022,4,2))
    //toDay.minDate = fromDay


    return (
        <>
        <CalendarBox>
            <CalendarRangeHeader fromDay={fromDay} toDay = {toDay}/>
            <div>
            <CalendarBar date={fromDay} stateChange = {setFromDay}/>  
            <CalendarBar date={toDay} stateChange= {setToDay}/>
            </div>
            
            <Calendar date={fromDay} stateChange= {setFromDay}/>
            <Calendar date={toDay} stateChange= {setToDay}/>
        </CalendarBox>
        
        </>
    )
}
