import styled from 'styled-components'

// ask if gap and padding based on screen width or maxWidth of container element
export const Container = styled.section`
    margin: 0;
    background: ${props => props.background ? props.background : "white"};
    display: grid;
    overflow-y: ${props => props.overflow && props.overflow};
    height: ${props => props.height && props.height};
    ${props => (props.width < 600) 
        &&`width: calc(100% - 30px);
        max-width: ${props.maxWidth ? `calc(${props.maxWidth} - 30px)` : 'none'};
        grid-template-columns: repeat(${props.sColumns ? props.sColumns : 4}, 1fr);
        padding: 15px;
        column-gap: 15px;
        row-gap: 15px;
        `
    }
    ${props => ((props.width > 599) && (props.width < 840)) 
        &&`width: calc(100% - 40px);
        max-width: ${props.maxWidth ? `calc(${props.maxWidth} - 40px)` : 'none'};
        grid-template-columns: repeat(${props.mColumns ? props.mColumns : 8}, 1fr);
        padding: 20px;
        column-gap: 20px;
        row-gap: 20px;
        `
    }
    ${props => ((props.width > 839) && (props.width < 1220)) 
        && `width: calc(100% - 40px);
        max-width: ${props.maxWidth ? `calc(${props.maxWidth} - 40px)` : 'none'};
        grid-template-columns: repeat(${props.lColumns ? props.lColumns : 12}, 1fr);
        padding: 20px;
        column-gap: 20px;
        row-gap: 20px;
        `
    }
    ${props => (props.width > 1219) 
        && `width: calc(100% - 60px);
        max-width: ${props.maxWidth ? `calc(${props.maxWidth} - 60px)` : 'none'};
        padding: 30px;
        column-gap: 30px;
        row-gap: 30px;
        grid-template-columns: repeat(${props.xlColumns ? props.xlColumns : 12}, 1fr);
        `
    }
`

export const Content = styled.article`
    border: ${props => props.border ? props.border : "1px solid lightgray"};
    border-radius: ${props => props.radius ? props.radius : "4px"};
    background: ${props => props.background ? props.background : "white"};
    ${props => (props.width < 600) 
        &&`
            grid-column: auto / span ${props.sColumn ? props.sColumn : 1};
            grid-row: auto / span ${props.sRow ? props.sRow : 1};
        `
    }
    ${props => ((props.width > 599) && (props.width < 840)) 
        &&`
            grid-column: auto / span ${props.mColumn ? props.mColumn : 1};
            grid-row: auto / span ${props.mRow ? props.mRow : 1};
        `
    }
    ${props => ((props.width > 839) && (props.width < 1220)) 
        && `
            grid-column: auto / span ${props.lColumn ? props.lColumn : 1};
            grid-row: auto / span ${props.lRow ? props.lRow : 1};
        `
    }
    ${props => (props.width > 1219) 
        && `
            grid-column: auto / span ${props.xlColumn ? props.xlColumn : 1};
            grid-row: auto / span ${props.xlRow ? props.xlRow : 1};
        `
    }
`

export const Block = styled.div`
    width: ${props => props.width ? props.width : '100%'};
    flex-direction: ${props => props.flexDirection && props.flexDirection};
    height: ${props => props.height && props.height};
    min-height: ${props => props.minHeight && props.minHeight};
    max-width: ${props => props.maxWidth && props.maxWidth};
    display: ${props => props.display ? props.display : 'block'};
    padding: ${props => props.padding && props.padding};
    background: ${props => props.background && props.background};
    border-radius: ${props => props.radius && props.radius};
    border: ${props => props.border && props.border};
    border-bottom: ${props => props.borderBottom && props.borderBottom};
    border-top: ${props => props.borderTop && props.borderTop};
    border-right: ${props => props.borderRight && props.borderRight};
    border-left: ${props => props.borderLeft && props.borderLeft};
    margin: ${props => props.margin && props.margin};
    position: ${props => props.position ? props.position : 'relative'};
    z-index: ${props => props.z ? props.z : '0'};
    top: ${props => props.top && props.top};
    right: ${props => props.right && props.right};
    bottom: ${props => props.bottom && props.bottom};
    left: ${props => props.left && props.left};
    cursor: ${props => props.cursor && props.cursor};
    grid-template-columns: ${props => props.columns && props.columns};
    column-gap: ${props => props.columnGap && props.columnGap};
    row-gap: ${props => props.rowGap && props.rowGap};
    align-items: ${props => props.align && props.align};
    justify-content: ${props => props.justify && props.justify};
    flex-flow: ${props => props.flexFlow && props.flexFlow};
    overflow-y: ${props => props.overflow && props.overflow};
    text-align: ${props => props.textAlign && props.textAlign};
    flex-shrink: ${props => props.flexShrink && props.flexShrink};
    color: ${props => props.color && props.color};
`