import { Fragment, useState } from "react"
import { Chat } from "../../components/simple/chat/index.js"
import { Block } from "../../components/simple/layout/index.js"
import { PropTable } from "../../components/simple/Documentation/proptable/proptable.js"
import { Showcode } from "../../components/simple/Documentation/showcode.js"

export const ChatPage = () => {
    const data = `
    const [messages, setMessages] = useState([
        {user: "Ian Hess", message: "Hey man, how are you?", date: date},
        {user: "John Adams", message: "Good, you?", date: date},
        {user: "Ian Hess", message: "Programming.....", date: date},
        {user: "John Adams", message: "R.I.P.", date: date},
    ])

    const sendNewMessage = newMessage => {
        const newMessages = [
            ...messages,
            newMessage
        ]
        setMessages(newMessages)
    }
    <Chat name="John Adams" user="Ian Hess" data={messages}
    action={sendNewMessage} />`
    const date = new Date()
    const [messages, setMessages] = useState([
        {user: "Ian Hess", message: "Hey man, how are you?", date: date},
        {user: "John Adams", message: "Good, you?", date: date},
        {user: "Ian Hess", message: "Programming.....", date: date},
        {user: "John Adams", message: "R.I.P.", date: date},
    ])

    const sendNewMessage = newMessage => {
        const newMessages = [
            ...messages,
            newMessage
        ]
        setMessages(newMessages)
    }
    //should we add showing off props?
    return(
        <Fragment>
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="flex-start">
                <Chat name="John Adams" user="Ian Hess" data={messages}
                action={sendNewMessage} />
            </Block>
            <Showcode data = {data} />
            <PropTable descriptions={Chat.descriptions} ></PropTable>
        </Fragment>
    )
}