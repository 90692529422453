import CodeEditor from '@uiw/react-textarea-code-editor';
import React, { useState } from 'react';
import { Button } from "../../../components/simple/button/index.js"
import { Block } from "../../../components/simple/layout/index.js"


export const Showcode = props => {
    const {data} = props
    const [code, setCode] = useState(data ? data :
        `function add(a, b) {\n  return a + b;\n}`
      );
    const [selected, setSelected] = useState(false)
    return(
        <>
        <Block>

         <Button onClick={() => setSelected(!selected)}>
            {selected && <div>Hide Code</div>}
            {!selected && <div>Show Code</div>} 
        </Button>
        </Block>

        {selected &&
        <CodeEditor 
        value={code}
        language="js"
        placeholder="Please enter JS code."
        onChange={(evn) => setCode(evn.target.value)}
        padding={15}
        style={{
        fontSize: 12,
        backgroundColor: "#f5f5f5",
        fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
      }}
      data-color-mode='light'
        />   }

        </>
    )
}