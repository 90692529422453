import { Fragment } from "react"
import * as Color from '../../components/color.js'
import { Tooltip, TooltipText } from "../../components/simple/tooltip/index.js"
import { Block } from "../../components/simple/layout/index.js"
import { PropTable } from "../../components/simple/Documentation/proptable/proptable.js"
import { Showcode } from "../../components/simple/Documentation/showcode.js"
export const TooltipPage = () => {
    const dataShow = `<Tooltip>
    <TooltipText>
        Tooltip text to show.
    </TooltipText>
</Tooltip>`
    const dataShow2 = `<Tooltip mode="dark">
    <TooltipText color={Color.DARK_GREY}>
        Tooltip text to show.
    </TooltipText>
</Tooltip>`
    return(
        <Fragment>
            <Block display="flex" width="calc(100% - 160px)" padding="80px" justify="center">
                <Tooltip>
                    <TooltipText>
                        Tooltip text to show.
                    </TooltipText>
                </Tooltip>
            </Block>
            <Showcode data = {dataShow} />
            <Block background={Color.DARK_GREY_BG_2} display="flex" width="calc(100% - 160px)" padding="80px" justify="center">
                <Tooltip mode="dark">
                    <TooltipText color={Color.DARK_GREY}>
                        Tooltip text to show.
                    </TooltipText>
                </Tooltip>
            </Block>
            <Showcode data = {dataShow2} />
            <PropTable title="Tooltip Props" descriptions={Tooltip.descriptions} ></PropTable>
            <PropTable title="TooltipText Props" descriptions={TooltipText.descriptions} ></PropTable>
        </Fragment>
    )
}