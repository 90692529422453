import { Fragment } from "react"
import { Accordion } from "../../components/simple/accordion/index.js"
import { Block } from "../../components/simple/layout/index.js"
import { PropTable } from "../../components/simple/Documentation/proptable/proptable.js"
import { Showcode } from "../../components/simple/Documentation/showcode.js"
export const AccordionPage = () => {
    const data = `
    <Accordion data={[
        {
            content: <div>example content</div>
        }
    ]} />
    `
    const data2 = `
    <Accordion data={[
        {
            header: "Header Text 1",
            content: <div>example content 1</div>
        },
        {
            header: "Header Text 2",
            content: <div>example content 2</div>
        },
        {
            header: "Header Text 3",
            content: <div>example content 3</div>
        },
    ]} />
    `
    return(
        <Fragment>
            <Block padding="20px" width="calc(100% - 40px)">
                <label>Without Header</label>
                <Accordion data={[
                    {
                        content: <div>example content</div>
                    }
                ]} />
                <Showcode data = {data} />
            </Block>

            <Block padding="20px" width="calc(100% - 40px)">
                <label>With Header</label>
                <Accordion data={[
                    {
                        header: "Header Text 1",
                        content: <div>example content 1</div>
                    },
                    {
                        header: "Header Text 2",
                        content: <div>example content 2</div>
                    },
                    {
                        header: "Header Text 3",
                        content: <div>example content 3</div>
                    },
                ]} />
                <Showcode data = {data2} />
            </Block>
            <Block padding="20px" width="calc(100% - 40px)">
                <PropTable descriptions={Accordion.descriptions} />
            </Block>
        </Fragment>
    )
}