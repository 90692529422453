import React from 'react'
import * as AppRoutes from '../components/routes.js'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Navigation } from '../components/simple/navigation/index.js'
// import { Context } from '../context/index.js'

// Pages
import { HomePage } from '../pages/home/index.js'
import { AccordionPage,
  AuthPage,
  BreadcrumbPage, 
  ButtonPage,
//   TypographyPage,
//   SpacingPage,
//   GridPage,
//   ButtonPage,
  CalendarPickerPage,
  TimePickerPage,
  CardPage, 
  ChatPage, 
  ColorPage,
  InputPage, 
  ListPage,
  ModalPage, 
  NotificationPage, 
  ProgressPage, 
  TabPage, 
  TablePage, 
  TooltipPage, 
  // SpinnerPage,
} from '../pages/documentation/index.js'

import { NavigationData } from '../pages/documentation/navigationpage.js'

export const App = () => {
  // const ref = useRef(null)
	// const [containerWidth, setContainerWidth] = useState(0)
  // const [state] = useContext(Context)
  // const offsetWidth = ref?.current?.offsetWidth

  // useEffect(() => {
  //   setContainerWidth(ref.current ? ref.current.offsetWidth : 0)
	// }, [offsetWidth, state.resize])

  return (
    <BrowserRouter>
      <Navigation data={NavigationData}>
        <Routes>
          <Route path={AppRoutes.HOME} element={<HomePage />} />
          {/* <Route path={AppRoutes.TYPOGRAPHY} element={<TypographyPage />} /> */}
          {/* <Route path={AppRoutes.SPACING} element={<SpacingPage />} /> */}
          {/* <Route path={AppRoutes.GRID} element={<GridPage />} /> */}
          <Route path={AppRoutes.ACCORDION} element={<AccordionPage />} />
          <Route path={AppRoutes.AUTH} element={<AuthPage />} />
          <Route path={AppRoutes.BREADCRUMB} element={<BreadcrumbPage />} />
          <Route path={AppRoutes.BUTTON} element={<ButtonPage />} />
          <Route path={AppRoutes.CALENDAR_PICKER} element={<CalendarPickerPage />} />
          <Route path={AppRoutes.CARD} element={<CardPage />} />
          <Route path={AppRoutes.COLOR} element={<ColorPage />} />
          <Route path={AppRoutes.CHAT} element={<ChatPage />} />
          <Route path={AppRoutes.INPUT} element={<InputPage />} />
          <Route path={AppRoutes.LIST} element={<ListPage />} />
          <Route path={AppRoutes.MODAL} element={<ModalPage />} />
          <Route path={AppRoutes.NOTIFICATION} element={<NotificationPage />} />
          <Route path={AppRoutes.PROGRESS} element={<ProgressPage />} />
          <Route path={AppRoutes.TAB} element={<TabPage />} />
          <Route path={AppRoutes.TABLE} element={<TablePage />} />
          <Route path={AppRoutes.TIME_PICKER} element={<TimePickerPage />} />
          <Route path={AppRoutes.TOOLTIP} element={<TooltipPage />} />
          {/* <Route path={AppRoutes.SPINNER} element={<SpinnerPage />} /> */}
        </Routes>
      </Navigation>
      {/* <Container sColumns={4} mColumns={6} lColumns={8} xlColumns={12} 
      background="none" height="calc(100vh - 60px)" width={containerWidth} ref={ref}>
        <Content sColumn={2} mColumn={2} lColumn={3} xlColumn={4} width={containerWidth}>
          <Navigation />
        </Content>
        <Content sColumn={2} mColumn={4} lColumn={5} xlColumn={8} width={containerWidth}>
          
        </Content>
      </Container> */}
    </BrowserRouter>
  )
}
