import React, { useRef, useEffect, useLayoutEffect, useState } from "react"
import PropTypes from "prop-types"

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, condition, action) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        if(condition) {
            action()
        }
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
    };
  }, [ref])
}

/**
 * Component that alerts if you click outside of it
 */
export const OutsideHandler = (props) => {
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, props.condition, props.action)

  return <div ref={wrapperRef}>{props.children}</div>
}

OutsideHandler.propTypes = {
  children: PropTypes.element.isRequired,
  action: PropTypes.func.isRequired,
  condition: PropTypes.bool.isRequired
}



export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}