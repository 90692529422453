import React from "react"
import { Block } from "../../layout/index.js"
import { Table } from "../../table/index.js"
import { arrayOf, shape } from "prop-types"

const defaultHeader = [
    {title: "Name", disabled: true},
    {title: "Type", disabled: true},
    {title: "Default", disabled: true},
    {title: "Required", disabled: true},
    {title: "Description", disabled: true}
]
//CAN REMOVE ALL COMMENTS WHEN GO THROUGH WITH IAN, just for showing if necessary

export const PropTable = props => {
    const {descriptions, header, title} = props
    const rows = []
    //Needs to be file.descriptions and file.propTypes
    let keys = Object.keys(descriptions)
    let descript = Object.values(descriptions)
    //console.log(Button.propTypes)
    //console.log("Descript", descript)
    //console.log("Inner Desc", descript[2])
    //let values = Object.values(Button.propTypes)
    //console.log("keys ", keys)
    //console.log("values ", values)
    //console.log("required?", values[1].isRequired)
    //console.log("rows", rows)
    //console.log("len",keys.length)
    
    for(var i=0; i<keys.length; i++)
    {
        //console.log(`IRow ${i}`,rows[i][0])
        //console.log(`Row ${i}`, rows[i])
        //console.log(keys[i])
        if(rows.length <= i)
        {
            rows.push([{title: ""},{title: ``},{title: ""},{title: ""},{title: ""}])
        }
        rows[i][0].title = keys[i]
        rows[i][1].title = descript[i][0]
        rows[i][2].title = descript[i][1]
        rows[i][3].title = descript[i][2]
        rows[i][4].title = descript[i][3]
    }

    return(
        <>
        <Block>
                <Table 
                    title={title ? title :"Props"}
                    header={header ? header : defaultHeader}
                    rows={rows}
                    />
            </Block>
        </>
    )
}
/*PropTable.propTypes = {
    propTypes: arrayOf(shape({})).isRequired,
    descriptions: arrayOf(shape({})).isRequired, //IE Button.descriptions, must be manually done
    header: arrayOf(shape({})).isRequired, //Header for all proptypes
}*/