import { Fragment } from "react"
import { Card } from "../../components/simple/card/index.js"
import { Block } from "../../components/simple/layout/index.js"
import * as Color from "../../components/color.js"
import { PropTable } from "../../components/simple/Documentation/proptable/proptable.js"
import { Showcode } from "../../components/simple/Documentation/showcode.js"
export const CardPage = () => {
    const dataShow = `
    <Card type="elevated">
                    <Block padding="20px" width="calc(100% - 40px)" height="100px" display="flex" align="center" justify="center">
                        Elevated
                    </Block>
                </Card>
                <Card>
                    <Block padding="20px" width="calc(100% - 40px)" height="100px" display="flex" align="center" justify="center">
                        Outlined
                    </Block>
                </Card>
                <Card type="selected">
                    <Block padding="20px" width="calc(100% - 40px)" height="100px" display="flex" align="center" justify="center">
                        Selected
                    </Block>
                </Card>
    `
    const dataShow2 = `<Card mode="dark">
    <Block padding="20px" width="calc(100% - 40px)" height="100px" display="flex" align="center" justify="center">
        Outlined Dark Mode
    </Block>
</Card>
<Card type="selected" mode="dark">
    <Block padding="20px" width="calc(100% - 40px)" height="100px" display="flex" align="center" justify="center">
        Selected Dark Mode
    </Block>
</Card>`
    const dataShow3 = `<Card header="Header Text" subHeader="Sub Header Text" action={() => alert("OnClick Event")} actionText="Button Text">
    <Block padding="20px" width="calc(100% - 40px)" height="100px" display="flex" align="center" justify="center">
        Outlined Dark Mode
    </Block>
</Card>`
    return(
        <Fragment>
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="space-evenly">
                <Card type="elevated">
                    <Block padding="20px" width="calc(100% - 40px)" height="100px" display="flex" align="center" justify="center">
                        Elevated
                    </Block>
                </Card>
                <Card>
                    <Block padding="20px" width="calc(100% - 40px)" height="100px" display="flex" align="center" justify="center">
                        Outlined
                    </Block>
                </Card>
                <Card type="selected">
                    <Block padding="20px" width="calc(100% - 40px)" height="100px" display="flex" align="center" justify="center">
                        Selected
                    </Block>
                </Card>
            </Block>
            <Showcode data = {dataShow} />
            {/* TODO: Change dark mode text to white for example */}
            <Block display="flex" width="calc(100% - 40px)" padding="20px" background={Color.DARK_GREY_BG_2} justify="space-evenly">
                <Card mode="dark">
                    <Block padding="20px" width="calc(100% - 40px)" height="100px" display="flex" align="center" justify="center">
                        Outlined Dark Mode
                    </Block>
                </Card>
                <Card type="selected" mode="dark">
                    <Block padding="20px" width="calc(100% - 40px)" height="100px" display="flex" align="center" justify="center">
                        Selected Dark Mode
                    </Block>
                </Card>
            </Block>
            <Showcode data = {dataShow2} />
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="space-evenly">
                <Card header="Header Text" subHeader="Sub Header Text" action={() => alert("OnClick Event")} actionText="Button Text">
                    <Block padding="20px" width="calc(100% - 40px)" height="100px" display="flex" align="center" justify="center">
                        Outlined Dark Mode
                    </Block>
                </Card>
            </Block>
            <Showcode data = {dataShow3} />
            <PropTable descriptions={Card.descriptions} ></PropTable>
        </Fragment>
    )
}