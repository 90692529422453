import { Fragment } from "react"
import { Notification } from "../../components/simple/notification/index.js"
import { Block } from "../../components/simple/layout/index.js"
import { PropTable } from "../../components/simple/Documentation/proptable/proptable.js"
import { Showcode } from "../../components/simple/Documentation/showcode.js"
export const NotificationPage = () => {
    const data = `<Notification closeAction={() => alert("Close Action")}
    header="Header Text"
    type="information">
    Information Notification Content
</Notification>`
    const data2 = `<Notification closeAction={() => alert("Close Action")}
    header="Header Text"
    type="error">
    Error Notification Content
</Notification>`
    const data3 = `<Notification closeAction={() => alert("Close Action")}
    header="Header Text"
    type="success">
    Success Notification Content
</Notification>`
    const data4 = `<Notification closeAction={() => alert("Close Action")}
    header="Header Text"
    type="warning">
    Warning Notification Content
</Notification>`
    return(
        <Fragment>
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="flex-start">
                <Notification closeAction={() => alert("Close Action")}
                    header="Header Text"
                    type="information">
                    Information Notification Content
                </Notification>
            </Block>
            <Showcode data = {data} />
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="flex-start">
                <Notification closeAction={() => alert("Close Action")}
                    header="Header Text"
                    type="error">
                    Error Notification Content
                </Notification>
            </Block>
            <Showcode data = {data2} />
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="flex-start">
                <Notification closeAction={() => alert("Close Action")}
                    header="Header Text"
                    type="success">
                    Success Notification Content
                </Notification>
            </Block>
            <Showcode data = {data3} />
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="flex-start">
                <Notification closeAction={() => alert("Close Action")}
                    header="Header Text"
                    type="warning">
                    Warning Notification Content
                </Notification>
            </Block>
            <Showcode data = {data4} />
            <PropTable descriptions={Notification.descriptions} ></PropTable>
        </Fragment>
    )
}