import React from "react"
import { node, string, func, bool } from "prop-types"
import styled from 'styled-components'
import * as Color from '../../color.js'
import * as Spacing from '../../spacing.js'
import { Button } from "../button/index.js"
import { Block } from "../layout/index.js"
import { IoCloseOutline } from "react-icons/io5/index.esm.js"
import { MdDangerous } from "react-icons/md/index.esm.js"

const ModalStyles = styled.section`
    background: ${Color.ZEBRA_STRIPE_BG};
    border-radius: 4px;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
    padding: ${Spacing.SPACING_09};
    position: relative;
    ${props => props.overlay ? "" : "width: 100%;"}
    border-top: ${props => props.destructive && `10px solid ${Color.ERROR_RED}`}
`

const ModalClose = styled.div`
    position: absolute;
    top: ${Spacing.SPACING_05};
    right: ${Spacing.SPACING_06};
    font-size: 20px;
    cursor: pointer;
    height: 20px;
    width: 20px;
`

const ModalHeader = styled.header`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

const HeaderIcon = styled.div`
    color: ${Color.ERROR_RED};
    height: 20px;
    width: 20px;
    font-size: 20px;
    margin-right: ${Spacing.SPACING_06};
`

const HeaderText = styled.h1`
    color: ${Color.DARK_GREY};
    font-family: Arial-BoldMT;
    font-size: 28px;
    font-weight: boldmt;
    margin: 0;
`

const ModalContent = styled.article`
    margin: ${Spacing.SPACING_08} 0 ${Spacing.SPACING_05};
`

const ModalFooter = styled.footer`
    display: flex;
    justify-content: flex-end;
`

const OverlayElement = styled.span`
${props => props.overlay 
    ? `
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(0,0,0,0.5); /*dim the background*/
`
: ""}
`

const BoxElement = styled.span`
${props => props.overlay 
    ? `
    position: absolute;
    width: 600px;
    height: 600px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`
: ""}
`

// TODO: Add dark mode for modal


export const Modal = props => {
    const { destructive, closeAction, action, actionText, secondaryAction, secondaryActionText, header, children, overlay, overlayAction } = props

    const [count, setCount] = React.useState(0);

    const useOutsideClick = (callback) => {
        const ref = React.useRef();
      
        React.useEffect(() => {
          const handleClick = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
              callback();
            }
          };
      
          window.addEventListener('click', handleClick, true);
      
          return () => {
            window.removeEventListener('click', handleClick, true);
          };
        }, [ref]);
      
        return ref;
      };

  const handleClickOutside = () => {
    if (overlay) {
        overlayAction ? overlayAction() : closeAction()
    }
    };

  const ref = useOutsideClick(handleClickOutside);


  
    return(
        overlay ?
        <OverlayElement overlay={overlay} onClick={overlayAction}>
            <BoxElement ref={ref} overlay={overlay} >
                <ModalStyles destructive={destructive} overlay={overlay}>
                    <ModalClose onClick={closeAction}>
                        <IoCloseOutline />
                    </ModalClose>
                    {header && <ModalHeader>
                        {destructive && <HeaderIcon><MdDangerous /></HeaderIcon>}
                        <HeaderText>
                            {header}
                        </HeaderText>
                    </ModalHeader>}
                    <ModalContent>
                        {children}
                    </ModalContent>
                    <ModalFooter>
                        {secondaryAction && <Block width="auto" margin={`0 ${Spacing.SPACING_06} 0 0`}>
                            <Button status={destructive ? "destructive" : "enabled"} type="secondary" onClick={secondaryAction}>
                                {secondaryActionText}
                            </Button>
                        </Block>}
                        <Button status={destructive ? "destructive" : "enabled"} onClick={action}>
                            {actionText}
                        </Button>
                    </ModalFooter>
                </ModalStyles>
            </BoxElement>
        </OverlayElement>
        : <ModalStyles destructive={destructive} overlay={overlay}>
            <ModalClose onClick={closeAction}>
                <IoCloseOutline />
            </ModalClose>
            {header && <ModalHeader>
                {destructive && <HeaderIcon><MdDangerous /></HeaderIcon>}
                <HeaderText>
                    {header}
                </HeaderText>
            </ModalHeader>}
        <ModalContent>
            {children}
        </ModalContent>
        <ModalFooter>
            {secondaryAction && <Block width="auto" margin={`0 ${Spacing.SPACING_06} 0 0`}>
                <Button status={destructive ? "destructive" : "enabled"} type="secondary" onClick={secondaryAction}>
                    {secondaryActionText}
                </Button>
            </Block>}
            <Button status={destructive ? "destructive" : "enabled"} onClick={action}>
                {actionText}
            </Button>
        </ModalFooter>
    </ModalStyles>
    )
}

Modal.propTypes = {
    /** Whether or not the modal is the Destructive version */
    destructive: bool,
    /** Action to take upon clicking close icon */
    closeAction: func,
    /** Function to execute for primary action button click */
    action: func.isRequired,
    /** Primary action button text */
    actionText: string.isRequired,
    /** Function to execute for secondary action button click */
    secondaryAction: func,
    /** Secondary action button text */
    secondaryActionText: string,
    /** Text to appear in header of modal */
    header: string.isRequired,
    /** Node to appear in content of modal */
    children: node.isRequired,
    /** Whether or not the modal overlays the screen */
    overlay: bool
}
Modal.descriptions = {
      // [Type, default, required, descriptions]
  destructive: ["bool","","False","Whether or not the modal is the Destructive version"],
  closeAction: ["Function","","False","Action to take upon clicking close icon"],
  action: ["Function","Needs a default","True","Function to execute for primary action button click"],
  actionText: ["string","Needs a default","True","Primary action button text"],
  secondaryAction: ["Function","","False","Function to execute for secondary action button click"],
  secondaryActionText: ["string","","False","Secondary action button text"],
  header: ["string","Needs a default","True","Text to appear in header of modal"],
  children: ["node","Needs a default","True","Node to appear in content of modal"],
  overlay: ["bool","","False","Whether or not the modal overlays the screen"],
}