import React from "react"
import { node, func, string } from "prop-types"
import styled from 'styled-components'
import * as Color from '../../color.js'
import * as Spacing from '../../spacing.js'

// TODO: go through colors again and make sure you pay attention to OPACITY
export const ButtonTheme = {
  primary: {
    light: {
      enabled: {
        background: Color.PRIMARY_BLUE,
        color: Color.ZEBRA_STRIPE_BG,
        border: Color.PRIMARY_BLUE,
        hoverBackground: Color.DARK_BLUE,
        hoverColor: Color.ZEBRA_STRIPE_BG,
        hoverBorder: Color.DARK_BLUE
      },
      disabled: {
        background: Color.DISABLED_BG,
        color: Color.DISABLED_TEXT,
        border: Color.DISABLED_BG,
        hoverBackground: Color.DISABLED_BG,
        hoverColor: Color.DISABLED_TEXT,
        hoverBorder: Color.DISABLED_BG
      },
      destructive: {
        background: Color.ERROR_RED,
        color: Color.ZEBRA_STRIPE_BG,
        border: Color.ERROR_RED,
        hoverBackground: Color.ERROR_RED_HOVER,
        hoverColor: Color.ZEBRA_STRIPE_BG,
        hoverBorder: Color.ERROR_RED_HOVER
      }
    },
    dark: {
      enabled: {
        background: Color.DM_BLUE,
        color: Color.DARK_GREY,
        border: Color.DM_BLUE,
        hoverBackground: Color.WHITE,
        hoverColor: Color.DARK_GREY,
        hoverBorder: Color.WHITE
      },
      disabled: {
        background: Color.TEXT_SECONDARY_2,
        color: Color.DISABLED_TEXT,
        border: Color.TEXT_SECONDARY_2,
        hoverBackground: Color.TEXT_SECONDARY_2,
        hoverColor: Color.DISABLED_TEXT,
        hoverBorder: Color.TEXT_SECONDARY_2
      },
      destructive: {
        background: Color.DARK_ERROR_RED,
        color: Color.ZEBRA_STRIPE_BG,
        border: Color.DARK_ERROR_RED,
        hoverBackground: Color.DARK_ERROR_RED_HOVER,
        hoverColor: Color.ZEBRA_STRIPE_BG,
        hoverBorder: Color.DARK_ERROR_RED_HOVER
      }
    }
  },
  secondary: {
    light: {
      enabled: {
        background: Color.TEXT_SECONDARY_1,
        color: Color.PRIMARY_BLUE,
        border: Color.TEXT_SECONDARY_1,
        hoverBackground: Color.TEXT_HOVER_1,
        hoverColor: Color.PRIMARY_BLUE,
        hoverBorder: Color.TEXT_HOVER_1
      },
      disabled: {
        background: Color.DISABLED_BG,
        color: Color.DISABLED_TEXT,
        border: Color.DISABLED_BG,
        hoverBackground: Color.DISABLED_BG,
        hoverColor: Color.DISABLED_TEXT,
        hoverBorder: Color.DISABLED_BG
      },
      destructive: {
        background: Color.WHITE,
        color: Color.ERROR_RED,
        border: Color.ERROR_RED,
        hoverBackground: Color.ERROR_RED,
        hoverColor: Color.WHITE,
        hoverBorder: Color.ERROR_RED
      }
    },
    dark: {
      enabled: {
        background: Color.GREY,
        color: Color.WHITE,
        border: Color.GREY,
        hoverBackground: Color.TEXT_HOVER_2,
        hoverColor: Color.WHITE,
        hoverBorder: Color.TEXT_HOVER_2
      },
      disabled: {
        background: Color.TEXT_SECONDARY_2,
        color: Color.WHITE,
        border: Color.TEXT_SECONDARY_2,
        hoverBackground: Color.TEXT_SECONDARY_2,
        hoverColor: Color.WHITE,
        hoverBorder: Color.TEXT_SECONDARY_2,
        opacity: .3
      },
      destructive: {
        background: Color.DARK_ERROR_RED,
        color: Color.ZEBRA_STRIPE_BG,
        border: Color.DARK_ERROR_RED,
        hoverBackground: Color.DARK_ERROR_RED_HOVER,
        hoverColor: Color.ZEBRA_STRIPE_BG,
        hoverBorder: Color.DARK_ERROR_RED_HOVER
      }
    }
  },
  tertiary: {
    light: {
      enabled: {
        background: Color.WHITE,
        color: Color.TEXT_SECONDARY_2,
        border: Color.TEXT_HOVER_1,
        hoverBackground: Color.TERTIARY_HOVER,
        hoverColor: Color.TEXT_SECONDARY_2,
        hoverBorder: Color.TEXT_HOVER_1
      },
      disabled: {
        background: Color.DISABLED_BG,
        color: Color.DISABLED_TEXT,
        border: Color.DISABLED_BG,
        hoverBackground: Color.DISABLED_BG,
        hoverColor: Color.DISABLED_TEXT,
        hoverBorder: Color.DISABLED_BG
      },
      destructive: {
        background: Color.ERROR_RED,
        color: Color.ZEBRA_STRIPE_BG,
        border: Color.ERROR_RED,
        hoverBackground: Color.ERROR_RED_HOVER,
        hoverColor: Color.ZEBRA_STRIPE_BG,
        hoverBorder: Color.ERROR_RED_HOVER
      }
    },
    dark: {
      enabled: {
        background: Color.DARK_GREY_BG_2,
        color: Color.WHITE,
        border: Color.WHITE,
        hoverBackground: Color.WHITE,
        hoverColor: Color.PRIMARY_BLUE,
        hoverBorder: Color.WHITE
      },
      disabled: {
        background: Color.TEXT_HOVER_2,
        color: Color.HAIRLINE,
        border: Color.TEXT_HOVER_2,
        hoverBackground: Color.TEXT_HOVER_2,
        hoverColor: Color.HAIRLINE,
        hoverBorder: Color.TEXT_HOVER_2
      },
      destructive: {
        background: Color.DARK_ERROR_RED,
        color: Color.ZEBRA_STRIPE_BG,
        border: Color.DARK_ERROR_RED,
        hoverBackground: Color.DARK_ERROR_RED_HOVER,
        hoverColor: Color.ZEBRA_STRIPE_BG,
        hoverBorder: Color.DARK_ERROR_RED_HOVER
      }
    }
  },
  ghost: {
    light: {
      enabled: {
        background: Color.WHITE,
        color: Color.PRIMARY_BLUE,
        border: Color.PRIMARY_BLUE,
        hoverBackground: Color.PRIMARY_BLUE,
        hoverColor: Color.WHITE,
        hoverBorder: Color.PRIMARY_BLUE
      },
      disabled: {
        background: Color.DISABLED_BG,
        color: Color.DISABLED_TEXT,
        border: Color.DISABLED_BG,
        hoverBackground: Color.DISABLED_BG,
        hoverColor: Color.DISABLED_TEXT,
        hoverBorder: Color.DISABLED_BG
      },
      destructive: {
        background: Color.WHITE,
        color: Color.ERROR_RED,
        border: Color.ERROR_RED,
        hoverBackground: Color.ERROR_RED,
        hoverColor: Color.WHITE,
        hoverBorder: Color.ERROR_RED
      }
    },
    dark: {
      enabled: {
        background: Color.DARK_GREY_BG_2,
        color: Color.WHITE,
        border: Color.WHITE,
        hoverBackground: Color.WHITE,
        hoverColor: Color.PRIMARY_BLUE,
        hoverBorder: Color.WHITE
      },
      disabled: {
        background: Color.TEXT_HOVER_2,
        color: Color.HAIRLINE,
        border: Color.TEXT_HOVER_2,
        hoverBackground: Color.TEXT_HOVER_2,
        hoverColor: Color.HAIRLINE,
        hoverBorder: Color.TEXT_HOVER_2
      },
      destructive: {
        background: Color.DARK_ERROR_RED,
        color: Color.ZEBRA_STRIPE_BG,
        border: Color.DARK_ERROR_RED,
        hoverBackground: Color.DARK_ERROR_RED_HOVER,
        hoverColor: Color.ZEBRA_STRIPE_BG,
        hoverBorder: Color.DARK_ERROR_RED_HOVER
      }
    }
  },
  text: {
    light: {
      enabled: {
        background: Color.WHITE,
        color: Color.PRIMARY_BLUE,
        border: Color.WHITE,
        hoverBackground: Color.PRIMARY_BG,
        hoverColor: Color.DARK_BLUE,
        hoverBorder: Color.PRIMARY_BG
      },
      disabled: {
        background: Color.WHITE,
        color: Color.DISABLED_TEXT,
        border: Color.WHITE,
        hoverBackground: Color.WHITE,
        hoverColor: Color.DISABLED_TEXT,
        hoverBorder: Color.WHITE
      },
      destructive: {
        background: Color.WHITE,
        color: Color.ERROR_RED,
        border: Color.WHITE,
        hoverBackground: Color.RED_100,
        hoverColor: Color.ERROR_RED,
        hoverBorder: Color.RED_100
      }
    },
    dark: {
      enabled: {
        background: Color.DARK_GREY_BG_2,
        color: Color.WHITE,
        border: Color.DARK_GREY_BG_2,
        hoverBackground: Color.HAIRLINE,
        hoverColor: Color.WHITE,
        hoverBorder: Color.HAIRLINE
      },
      disabled: {
        background: Color.DARK_GREY_BG_2,
        color: Color.TEXT_HOVER_2,
        border: Color.DARK_GREY_BG_2,
        hoverBackground: Color.DARK_GREY_BG_2,
        hoverColor: Color.TEXT_HOVER_2,
        hoverBorder: Color.DARK_GREY_BG_2
      },
      destructive: {
        background: Color.DARK_GREY_BG_2,
        color: Color.DARK_ERROR_RED,
        border: Color.DARK_GREY_BG_2,
        hoverBackground: Color.ERROR_RED,
        hoverColor: Color.WHITE,
        hoverBorder: Color.ERROR_RED
      }
    }
  }
}

export const ButtonSize = {
  large: {
    height: "48px",
    minWidth: "100px",
    fontSize: "16px",
    padding: `0 ${Spacing.SPACING_05}`
  },
  default: {
    height: "38px",
    minWidth: "85px",
    fontSize: "16px",
    padding: `0 ${Spacing.SPACING_05}`
  },
  small: {
    height: "28px",
    minWidth: "75px",
    fontSize: "14px",
    padding: `0 ${Spacing.SPACING_06}`
  },
  icon: {
    height: "auto",
    minWidth: "auto",
    fontSize: "inherit",
    padding: Spacing.SPACING_03
  }
}

export const ButtonStyles = styled.button`
  background: ${props => ButtonTheme[props.type][props.mode][props.status].background};
  border-radius: ${props => (props.size === 'icon') ? "9999px": "24px"};
  height: ${props => ButtonSize[props.size].height};
  min-width: ${props => ButtonSize[props.size].minWidth};
  font-family: arial;
  width: ${props => props.width ? props.width : "auto"};
  color: ${props => ButtonTheme[props.type][props.mode][props.status].color};
  border: 1px solid ${props => ButtonTheme[props.type][props.mode][props.status].border};
  font-size: ${props => ButtonSize[props.size].fontSize};
  box-shadow: ${props => ((props.type === "ghost") 
    || (props.type === "text") 
    || (props.status === "disabled") 
    || (props.mode === "dark")) 
    ? "none" : "rgba(0,0,0,.3) 0px 2px 4px"};
  cursor: ${props => (props.status === "disabled") ? "not-allowed" : "pointer"};
  padding: ${props => ButtonSize[props.size].padding};
  opacity: ${props => ButtonTheme[props.type][props.mode][props.status].opacity};
  &:hover {
    background: ${props => ButtonTheme[props.type][props.mode][props.status].hoverBackground};
    color: ${props => ButtonTheme[props.type][props.mode][props.status].hoverColor};
    border: 1px solid ${props => ButtonTheme[props.type][props.mode][props.status].hoverBorder};
  }
  ${props => (props.size === "icon") && `
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`

export const Button = props => {
  const {type, mode, status, size, children, onClick, width} = props

  const className = status ? status : "default"

  return(
    <ButtonStyles type={type ? type : "primary"}
      name={className}
      disabled={(status === "disabled")}
      width={width ? width : "auto"}
      mode={mode ? mode : "light"}
      status={status ? status : "enabled"}
      size={size ? size : "default"}
      onClick={onClick}>
      {children}
      
    </ButtonStyles>
  )
}

Button.propTypes = {
  /** React onClick property to be passed to button element */
  onClick: func.isRequired,
  /** Type of Button, example: "primary", "secondary", "tertiary", "ghost", "text" */
  type: string,
  /** Mode of app, example: "light", "dark" */
  mode: string,
  /** Status of Button, example: "enabled", "disabled", "destructive" */
  status: string,
  /** CSS Width of button */
  width: string,
  /** Overall size of Button, example: "small", "default", "large" */
  size: string,
  /** Node to appear inside of button element */
  children: node,
}
Button.descriptions = {
  // [Type, default, required, descriptions]
  onclick: ["function","Needs a default","True","React onClick property to be passed to button element"],
  type: ["string","","False","Type of Button, example: \"primary\", \"secondary\", \"tertiary\", \"ghost\", \"text\""],
  mode: ["string","","False","Mode of app, example: \"light\", \"dark\""],
  status: ["string","","False","Status of Button, example: \"enabled\", \"disabled\", \"destructive\""],
  width: ["string","","False","CSS Width of button"],
  size: ["string","","False","Overall size of Button, example: \"small\", \"default\", \"large\""],
  children: ["node","","False","Node to appear inside of button element"],
}
Button.examples = {
  
}