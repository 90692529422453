import { CalendarPicker, DurationCalendarPicker } from "../../components/simple/calendarpicker/index.js"
import { DateField } from '../../components/simple/input/index.js'
import { useState } from "react"
import { Block } from "../../components/simple/layout/index.js"
import { Fragment } from "react"
import { Showcode } from "../../components/simple/Documentation/showcode.js"
export const CalendarPickerPage = () =>
{
    const [date, setDate] = useState(new Date("2015/03/07"))
    const data = `<DateField label="Controllable Date" value={date} onChange={setDate} />
    `
    const data2 = `<CalendarPicker value={date} onChange={setDate} action={() => alert('clicked done')} cancel={() => alert('clicked cancel')} />`

    return(
        <Fragment>
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="flex-start">
                <DateField label="Controllable Date" value={date} onChange={setDate} />
            </Block>
            <Showcode data = {data} />
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="flex-start">
                <Block width="50%">
                    <CalendarPicker value={date} onChange={setDate} action={() => alert('clicked done')} cancel={() => alert('clicked cancel')} />
                </Block>  
            </Block>
            <Showcode data = {data2} />
            {/* <DurationCalendarPicker/> */}
            
        </Fragment>
    )
}