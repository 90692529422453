import React from "react"
import { string, arrayOf, number, shape } from "prop-types"
import styled from 'styled-components'
import * as Color from '../../color.js'


const ProgressStyles = styled.section`
    background: ${Color.DISABLED_BG};
    width: 100%;
    height: 10px;
    position: relative;
`

const ProgressBar = styled.div`
    background: ${Color.PRIMARY_BLUE};
    width: ${props => props.percent ? props.percent : 0}%;
    height: 10px;
`

const ProgressCircles = styled.div`
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
`

const ProgressStep = styled.div`
    top: -3px;
    position: absolute;
    width: 100px;
    display: flex;
    flex-direction: column;
    ${props => props.left && `
        left: calc(${props.percent}% - 2px);
        align-items: flex-start;
    `}
    ${props => props.center && `
        left: calc(${props.percent}% - 50px);
        align-items: center;
    `}
    ${props => props.right && `
        left: calc(${props.percent}% - 98px);
        align-items: flex-end;
    `}
`

const ProgressCircle = styled.div`
    height: 17px;
    width: 17px;
    background: ${props => props.complete ? Color.PRIMARY_BLUE : Color.DISABLED_BG};
    border-radius: 9px;
`

const ProgressText = styled.p`
    margin: 0;
    color: ${Color.MED_GREY};
    font-family: ArialMT;
    font-size: 16px;
`

export const Progress = props => {
    const { percent, data } = props

    return(
        <ProgressStyles>
            <ProgressBar percent={percent && percent} />
            <ProgressCircles>
                {data && data.map((step, key) => {
                    return(
                        <ProgressStep key={`Progress-Step-${step.name}-${key}`}
                            percent={step.percent}
                            left={step.percent < 10}
                            center={(step.percent > 9) && (step.percent < 91)}
                            right={step.percent > 90}>
                            <ProgressCircle complete={percent >= step.percent} />
                            <ProgressText>
                                {step.name}
                            </ProgressText>
                        </ProgressStep>
                    )
                })}
            </ProgressCircles>
        </ProgressStyles>
    )
}

Progress.propTypes = {
    /** Current Percentage complete for progress bar, example: 0 - 100 */
    percent: number.isRequired,
    /** Array of strings that is passed in to progress component to define steps for progress bar, example: [{name: "half", percent: 50}, {name: "done", percent: 100}] */
    data: arrayOf(shape({
        /** Name of each step, example: start at 0% */
        name: string.isRequired,
        /** Percent completed, a number, example 25 */
        percent: number.isRequired
    }).isRequired).isRequired,
}
Progress.descriptions = {
    // [Type, default, required, descriptions]
    percent: ["number","Needs a default","True", `Current Percentage complete for progress bar, example: 0 - 100 `],
    data: ["Object(name(string), percent(number))","Needs a default","True",`Array of strings that is passed in to progress component to define steps for progress bar, example: [{name: "half", percent: 50}, {name: "done", percent: 100}]`],
}
