import React, { useState, useRef, useEffect } from "react"
import { string, func, arrayOf, shape, instanceOf, number, element} from "prop-types"
import styled from 'styled-components'
import * as Color from '../../color.js'
import * as Spacing from '../../spacing.js'
import { Button } from '../button/index.js'
import { Block } from "../layout/index.js"
import { MdCheckCircle, MdDangerous, MdInfo, MdWarning } from "react-icons/md/index.esm.js"


export const InputContainer = styled.section`
    width: ${props => props.width ? props.width : "100%"};
    display: flex;
    flex-direction: column;
    position: relative;
`

export const InputLabel = styled.label`
    color: ${props => InputTheme[props.dark ? "dark" : "light"][props.status ? props.status : 'enabled'].labelColor};
    font-family: ArialMT;
    font-size: 14px;
    margin: 0 0 ${Spacing.SPACING_01} 0;
`

const InputIcon = styled.p`
    position: absolute;
    margin: 0;
    right: ${Spacing.SPACING_03};
    top: 9px;
    font-size: 16px;
    height: 16px;
    width: 16px;
`

const HelpText = styled.p`
    color: ${props => InputTheme[props.dark ? "dark" : "light"][props.status].helpColor};
    font-family: ArialMT;
    font-size: 14px;
    margin: 0;
`

const HelpIcon = styled.p`
    color: ${props => InputTheme[props.dark ? "dark" : "light"][props.status].helpColor};
    font-family: ArialMT;
    font-size: 14px;
    height: 14px;
    width: 14px;
    margin: 0 ${Spacing.SPACING_02} 0 0;
`

const InputBox = styled.input`
    border: 1px solid ${props => InputTheme[props.dark ? "dark" : "light"][props.status].border};
    background: ${props => InputTheme[props.dark ? "dark" : "light"][props.status].background};
    color: ${props => InputTheme[props.dark ? "dark" : "light"][props.status].color};
    box-shadow: ${props => (props.status === "active") && "0px 0px 5px 0px rgba(15, 51, 95, 0.2)"};
    padding: ${Spacing.SPACING_03} ${props => props.icon ? Spacing.SPACING_08 : Spacing.SPACING_03} ${Spacing.SPACING_03} ${Spacing.SPACING_03};
    border-radius: ${props => props.radius ? props.radius : '6px'};
    display: flex;
    font-family: ArialMT;
    font-size: 16px;
    margin: 0;
    width: calc(100% - ${props => props.icon ? "42px" : "18px"});
    letter-spacing: 0.32px;
    outline: none;
    &:hover {
        border: 1px solid ${props => InputTheme[props.dark ? "dark" : "light"][props.status].hoverBorder};
        background: ${props => InputTheme[props.dark ? "dark" : "light"][props.status].hoverBackground};
        color: ${props => InputTheme[props.dark ? "dark" : "light"][props.status].hoverColor};
    }
`

const InputAreaBox = styled.textarea`
    border: 1px solid ${props => InputTheme[props.dark ? "dark" : "light"][props.status].border};
    background: ${props => InputTheme[props.dark ? "dark" : "light"][props.status].background};
    resize: ${props => props.resize ? props.resize : 'none'};
    height: ${props => props.height ? props.height : 'auto'};
    color: ${props => InputTheme[props.dark ? "dark" : "light"][props.status].color};
    box-shadow: ${props => (props.status === "active") && "0px 0px 5px 0px rgba(15, 51, 95, 0.2)"};
    padding: ${Spacing.SPACING_03} ${props => props.icon ? Spacing.SPACING_08 : Spacing.SPACING_03} ${Spacing.SPACING_03} ${Spacing.SPACING_03};
    border-radius: ${props => props.radius ? props.radius : '6px'};
    display: flex;
    font-family: ArialMT;
    font-size: 16px;
    margin: 0;
    width: calc(100% - ${props => props.icon ? "42px" : "18px"});
    letter-spacing: 0.32px;
    outline: none;
    &:hover {
        border: 1px solid ${props => InputTheme[props.dark ? "dark" : "light"][props.status].hoverBorder};
        background: ${props => InputTheme[props.dark ? "dark" : "light"][props.status].hoverBackground};
        color: ${props => InputTheme[props.dark ? "dark" : "light"][props.status].hoverColor};
    }
`

export const InputText = styled.p`
    margin: 0;
`

// slight secondary background around inputs
export const InputTheme = {
    light: {
        active: {
            background: Color.INPUT_BACKGROUND,
            color: Color.DARK_GREY,
            border: Color.PRIMARY_BLUE,
            hoverBackground: Color.INPUT_BACKGROUND,
            hoverColor: Color.DARK_GREY,
            hoverBorder: Color.PRIMARY_BLUE,
            labelColor: Color.PRIMARY_BLUE,
            helpColor: Color.TEXT_SECONDARY_2
        },
        enabled: {
            background: Color.INPUT_BACKGROUND,
            color: Color.DARK_GREY,
            border: Color.TEXT_HOVER_1,
            hoverBackground: Color.INPUT_BACKGROUND,
            hoverColor: Color.DARK_GREY,
            hoverBorder: Color.TEXT_HOVER_1,
            labelColor: Color.MED_GREY,
            helpColor: Color.TEXT_SECONDARY_2
        },
        disabled: {
            background: Color.DISABLED_BG,
            color: Color.DISABLED_TEXT,
            border: Color.DISABLED_BG,
            hoverBackground: Color.DISABLED_BG,
            hoverColor: Color.DISABLED_TEXT,
            hoverBorder: Color.DISABLED_BG,
            labelColor: Color.MED_GREY,
            helpColor: Color.TEXT_SECONDARY_2
        },
        error: {
            background: Color.LIGHT_PINK_BG,
            color: Color.DARK_GREY,
            border: Color.ERROR_RED,
            hoverBackground: Color.LIGHT_PINK_BG,
            hoverColor: Color.DARK_GREY,
            hoverBorder: Color.ERROR_RED,
            labelColor: Color.ERROR_RED,
            helpColor: Color.ERROR_RED
        },
        ghost: {
            background: 'transparent',
            color: Color.DARK_GREY,
            border: 'transparent',
            hoverBackground: 'transparent',
            hoverColor: Color.DARK_GREY,
            hoverBorder: 'transparent',
            labelColor: Color.MED_GREY,
            helpColor: Color.TEXT_SECONDARY_2
        }
    },
    dark: {
        active: {
            background: Color.HAIRLINE,
            color: Color.ZEBRA_STRIPE_BG,
            border: Color.DM_BLUE,
            hoverBackground: Color.HAIRLINE,
            hoverColor: Color.ZEBRA_STRIPE_BG,
            hoverBorder: Color.DM_BLUE,
            labelColor: Color.DM_BLUE,
            helpColor: Color.DM_GREY
        },
        enabled: {
            background: Color.HAIRLINE,
            color: Color.ZEBRA_STRIPE_BG,
            border: Color.ZEBRA_STRIPE_BG,
            hoverBackground: Color.HAIRLINE,
            hoverColor: Color.ZEBRA_STRIPE_BG,
            hoverBorder: Color.ZEBRA_STRIPE_BG,
            labelColor: Color.TEXT_HOVER_1,
            helpColor: Color.DM_GREY
        },
        disabled: {
            background: Color.DARK_GREY_BG_3,
            color: Color.TEXT_HOVER_2,
            border: Color.DARK_GREY_BG_2,
            hoverBackground: Color.DARK_GREY_BG_2,
            hoverColor: Color.TEXT_HOVER_2,
            hoverBorder: Color.DARK_GREY_BG_2,
            labelColor: Color.TEXT_HOVER_1,
            helpColor: Color.DM_GREY
        },
        error: {
            background: Color.DARK_GREY_BG_1,
            color: Color.ZEBRA_STRIPE_BG,
            border: Color.DARK_ERROR_RED,
            hoverBackground: Color.DARK_GREY_BG_1,
            hoverColor: Color.ZEBRA_STRIPE_BG,
            hoverBorder: Color.DARK_ERROR_RED,
            labelColor: Color.DARK_ERROR_RED,
            helpColor: Color.DARK_ERROR_RED
        }
    }
}

export const TextInput = props => {
    const { dark, help, label, status, limit, icon, placeholder, onChange, value, type, resize, height, radius } = props
    const [newStatus, setNewStatus] = useState(status)
    const count = value?.length ? value.length : 0

    // useEffect(() => {
    //     if(count > )
    // }, [count])

    const DetectFocus = () => {
        if(status !== "error" && status !== "disabled") {
            setNewStatus("active")
        }
    }

    return(
        <InputContainer>
            {label && <InputLabel dark={dark && dark} status={newStatus ? newStatus : "enabled"}>{label}</InputLabel>}
            <Block>
                {(type === "textarea")
                    ? <InputAreaBox onBlur={() => setNewStatus(status)}
                        resize={resize && resize}
                        height={height && height}
                        onFocus={DetectFocus}
                        radius={radius && radius}
                        onChange={e => onChange(e)}
                        disabled={newStatus === "disabled"}
                        dark={dark && dark}
                        maxLength={limit && limit}
                        status={newStatus ? newStatus : "enabled"}
                        placeholder={placeholder && placeholder}
                        type="textarea"
                        value={value}
                        icon={icon && icon} />
                    : <InputBox onBlur={() => setNewStatus(status)}
                        onFocus={DetectFocus}
                        onChange={e => onChange(e)}
                        radius={radius && radius}
                        disabled={newStatus === "disabled"}
                        dark={dark && dark}
                        maxLength={limit && limit}
                        status={newStatus ? newStatus : "enabled"}
                        placeholder={placeholder && placeholder}
                        type="text"
                        value={value}
                        icon={icon && icon} />}
                {icon && <InputIcon>{icon}</InputIcon>}
            </Block>
            { (help || limit) &&
                <Block display="flex" justify="space-between" align="center" margin={`${Spacing.SPACING_01} 0 0 0`}>
                    {help && <Block display="flex" justify="flex-start" align="center">
                        {((newStatus === "error") || (status === "error")) && <HelpIcon dark={dark && dark} status={newStatus ? newStatus : "enabled"}><MdDangerous /></HelpIcon>}
                        <HelpText dark={dark && dark} status={newStatus ? newStatus : "enabled"}>{help}</HelpText>
                    </Block>}
                    {limit && <HelpText dark={dark && dark} status={newStatus ? newStatus : "enabled"}>{count}/{limit}</HelpText>}
                </Block>
            }
        </InputContainer>
    )
}

TextInput.propTypes = {
    /** Function to set text when it is inputted */
    onchange: func,
    /** Text from input */
    value:string,
    /** String label for text input */
    label: string,
    /** String under text input, description or instructions */
    help: string,
    /**  */
    //dark: , //unsure -> local?
    /** Status message for input */
    status: string, 
    /** Number character limit for label */
    limit: number,
    /** A react element icon */
    icon: element, //Can also be node to be more general 
    /**  */
    ///placeholder: , //maybe not a proptype (local)? no examples , also why is it { &&}
    /** Type of element for input*/
    type: string, 
    /** String to say if user is allowed to resize the text input area */
    resize: string, 
    /** Height of text input */
    height: string,
}
