import React, { useState, Fragment, useEffect } from "react"
import { arrayOf, bool, func, string, node, shape, array } from "prop-types"
import styled from 'styled-components'
import * as Color from '../../color.js'
import * as Spacing from '../../spacing.js'
import { Block } from '../layout/index.js'
import { MdMoreVert, MdArrowDropDown, MdArrowDropUp } from "react-icons/md/index.esm.js"
import { FaChevronDown, FaChevronUp } from "react-icons/fa/index.esm.js"
import { Button } from "../button/index.js"
import { BiFilter, BiPlus, BiSearchAlt } from "react-icons/bi/index.esm.js"
import { TextInput, SearchField } from "../input/index.js"
import { OutsideHandler } from '../utils/index.js'
import FirstIcon from '../../assets/icons/Arrow/First.svg'
import FirstDisabledIcon from '../../assets/icons/Arrow/First_Disabled.svg'
import BackIcon from '../../assets/icons/Arrow/Back.svg'
import BackDisabledIcon from '../../assets/icons/Arrow/Back_Disabled.svg'
import NextIcon from '../../assets/icons/Arrow/Next.svg'
import NextDisabledIcon from '../../assets/icons/Arrow/Next_Disabled.svg'
import LastIcon from '../../assets/icons/Arrow/Last.svg'
import LastDisabledIcon from '../../assets/icons/Arrow/Last_Disabled.svg'

export const TableStyles = styled.table`
    background: white;
    border: 1px solid ${Color.DISABLED_BG};
    border-radius: 10px;
    width: 100%;
    border-spacing: 0px;
`

export const TableTitle = styled.h1`
    color: ${Color.DARK_GREY};
    font-size: 20px;
    font-weight: bold;
`

export const TableActionIcon = styled.div`
    padding: 0 0 0 20px;
    position: relative;
    svg {
        position: absolute;
        font-size: 20px;
        left: 0;
        top: -2px;
    }
`

export const TableHeaderDots = styled.div`
    text-align: center;
    font-size: 22px;
    color: ${Color.DARK_GREY};
    cursor: pointer;
    padding: ${Spacing.SPACING_05} ${Spacing.SPACING_05} ${Spacing.SPACING_05} 0;
    width: 0;
`

export const TableRowCount = styled.p`
    color: ${Color.DARK_GREY};
    font-family: ArialMT;
    font-size: 12px;
    margin: 0 0 0 ${Spacing.SPACING_05};
`

export const TableFiltersCount = styled.p`
    background: ${Color.ZEBRA_STRIPE_BG};
    color: ${Color.MED_GREY};
    font-family: Arial-BoldMT;
    font-size: 12px;
    width: 14px;
    border-radius: 7px;
    margin: 0;
    position: absolute;
    left: 0;
    top: 2px;
`

export const TableFilterLabel = styled.label`
    color: ${Color.MED_GREY};
    font-family: ArialMT;
    font-size: 14px;
    margin-bottom: 3px;
`

export const TableFilterSelect = styled.select`
    border: 1px solid ${Color.TEXT_HOVER_1};
    border-radius: 6px;
    padding: ${Spacing.SPACING_02} ${Spacing.SPACING_03};
`

export const TableClearFilters = styled.p`
    color: ${Color.PRIMARY_BLUE};
    font-family: ArialMT;
    font-size: 16px;
    margin: ${Spacing.SPACING_04} 0;
    cursor: pointer;
`

export const TableHeadRow = styled.tr`
    th {
        border-bottom: 1px solid ${Color.DISABLED_BG};
    }
`

export const TableHeadControl = styled.div`
    display: inline-block;
    position: absolute;
    margin-top: -4px;
    cursor: pointer;
`

export const TableOrderArrow = styled.div`
    font-size: 24px;
    height: 0px;
    width: 0px;
    &:first-child {

    }
    &:last-child {
        padding-top: 6px;
    }
    ${props => props.active ? `
        color: ${Color.DARK_GREY};
        font-weight: bold;
    ` : `
        color: ${Color.DM_GREY};
        font-weight: normal;
    `}
`

export const TableRow = styled.tr`
    position: relative;
    td {
        border-bottom: 1px solid ${Color.DISABLED_BG};
    }
    &:nth-child(odd) {
        td {
            background: ${Color.SNOW};
        }
    }
    &:last-child {
        td {
            border-bottom: none;
            &:first-child {
                border-bottom-left-radius: 10px;
            }
            &:last-child {
                border-bottom-right-radius: 10px;
            }
        }
    }
`
export const TableHead = styled.th`
    text-align: ${props => props.alignRight ? "right" : "left"};
    padding: ${Spacing.SPACING_06} ${props => props.onClick ? Spacing.SPACING_05 : "0"} ${Spacing.SPACING_06} 0;
    color: ${Color.MED_GREY};
    ${props => props.active ? `
        font-family: neue-haas-unica, sans-serif;
        font-size: 16px;
        font-weight: bold;
    ` : `
        font-family: neue-haas-unica, sans-serif;
        font-size: 16px;
        font-weight: normal;
    `}
    max-width: 200px;
    &:first-child {
        padding-left: ${Spacing.SPACING_05};
    }
    &:nth-last-child(2) {
        text-align: ${props => props.rowControl ? 'right' : 'left'};
        padding-right: ${props => props.subrowHeader ? Spacing.SPACING_05 : '0'}
    }
    &:last-child {
        text-align: ${props => !props.rowControl && 'right'};
        padding-right: ${props => !props.rowControl && Spacing.SPACING_06};
    }
`
export const TableDown = styled.td`
    text-align: ${props => props.alignRight ? "right" : "left"};
    flex: 0 1 auto;
    padding: ${Spacing.SPACING_06} 0;
    color: ${Color.MED_GREY};
    font-size: 14px;
    font-weight: normal;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: arial;
    a {
        color: ${Color.PRIMARY_BLUE};
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
    &:first-child {
        padding-left: ${props => (props.subrowHeader && props.dropdown) ? '0' : Spacing.SPACING_05};
    }
    &:nth-last-child(2) {
        text-align: ${props => props.rowControl ? 'right' : 'left'};
        padding-right: ${props => props.subrowHeader ? Spacing.SPACING_05 : '0'};
    }
    &:last-child {
        text-align: ${props => !props.rowControl && 'right'};
        padding-right: ${props => !props.rowControl && Spacing.SPACING_06};
    }
    position: relative;
`

export const TableDots = styled.td`
    text-align: center;
    font-size: 22px;
    color: ${Color.DARK_GREY};
    cursor: pointer;
    padding: ${Spacing.SPACING_05} ${Spacing.SPACING_05} ${Spacing.SPACING_05} ${props => props.subrowHeader ? Spacing.SPACING_05 : Spacing.SPACING_07};
    width: 0;
    position: relative;
    border-left: ${props => props.subrowHeader && `1px solid ${Color.DISABLED_BG}`};
`

export const TablePaginationText = styled.p`
    color: ${props => props.color ? props.color : Color.DARK_GREY};
    font-size: 14px;
    font-family: arial;
    font-weight: ${props => props.weight ? props.weight : '700'};
    ${props => props.selected && `border-bottom: 2px solid ${Color.PRIMARY_BLUE};`}
    margin: ${props => props.margin ? props.margin : `0 ${Spacing.SPACING_04} 0 0`};
    padding: ${props => props.padding && props.padding};
    cursor: ${props => props.cursor && props.cursor};
`

export const TablePaginationIcon = styled.figure`
    cursor: pointer;
    margin: 0 0 0 ${Spacing.SPACING_04};
`

export const TableRowPopup = styled.ul`
    background: ${Color.INPUT_BACKGROUND};
    border-radius: 6px;
    border: 1px solid ${Color.TEXT_HOVER_1};
    position: absolute;
    right: ${Spacing.SPACING_05};
    padding: 0 ${Spacing.SPACING_03} 0 ${Spacing.SPACING_03};
    z-index: 1;
`

export const TableRowControl = styled.li`
    list-style: none;
`

export const TableRowControlText = styled.p`
    font-family: arial;
    font-size: 16px;
    color: ${Color.PRIMARY_BLUE};
    margin: 0;
    padding: ${Spacing.SPACING_04} 0;
`

// columns to take as little space as possible, and align left with a 20px padding.
// add controls to table, instead of allowing developers to customize
// some fields may default as two lines
// maybe allow max width to be passed in?
// show rest of ellipses text after hover over or click
// For filters IN table, look at excel
// active state on button decides color of button for filter (tertiary)
// number inside of filter button needs to match color of button
// create carat dropdown component for table
// search should have X icon once filled with text, and clicking that X erases the content
// install neu fonts, check with MCB
// if only one option on table kebab, only use icon, not popup

export const Table = props => {
    const [activeColumn, setActiveColumn] = useState(null)
    const [columnOrderDown, setColumnOrderDown] = useState(false)
    const [showFilters, setShowFilters] = useState(false)
    const [filtersCount, setFiltersCount] = useState(0)
    const [searchText, setSearchText] = useState("")
    const [rowPopup, setRowPopup] = useState(false)
    const rightAlignedColumns = []
    // Pagination
    const paginationOptions = [30, 60, 90]
    const [paginationCount, setPaginationCount] = useState(paginationOptions[0])
    const [page, setPage] = useState(0)
    const pages = Math.ceil(props.rows.length / paginationCount)
    const { rows: rowsFromProps } = props || []
    const [rows, setRows] = useState(rowsFromProps)
    const pageOptions = [
        {
            icon: FirstIcon,
            disabledicon: FirstDisabledIcon,
            action: () => setPage(0),
            disabled: (page === 0)
        },
        {
            icon: BackIcon,
            disabledicon: BackDisabledIcon,
            action: () => setPage(page - 1),
            disabled: (page === 0)
        },
        {
            icon: NextIcon,
            disabledicon: NextDisabledIcon,
            action: () => setPage(page + 1),
            disabled: (page === (pages - 1))
        },
        {
            icon: LastIcon,
            disabledicon: LastDisabledIcon,
            action: () => setPage(pages - 1),
            disabled: (page === (pages - 1))
        },
    ]
    const [openRows, setOpenRows] = useState([])

    useEffect(() => {
        if (searchText) {
            const searchRows = []
            for (let i = 0; i < rowsFromProps.length; i++) {
                if (rowsFromProps[i].some(val => val.title?.toString().toLowerCase().includes(searchText.toLowerCase()))) {
                    searchRows.push(rowsFromProps[i]);
                }
            }
            setRows(searchRows)
        } else {
            setRows(rowsFromProps)
        }
    }, [searchText, rowsFromProps])

    const ClickPaginationCount = option => {
        setPage(0)
        setPaginationCount(option)
    }

    const OrderColumn = (callback, key, title, action) => {
        setActiveColumn(key)
        setColumnOrderDown(!columnOrderDown)
        if (action) {
            action(key, title, columnOrderDown)
        } else {
            setRows(sortColumn(key, columnOrderDown))
        }
        if (callback) {
            callback(key, title, columnOrderDown)
        }
    }

    const sortColumn = (key, direction) => {
        const sortedArray = rows.sort((a, b) => {
            if(a[key].title?.props?.children){
                if(b[key].title?.props?.children){
                    return (a[key].title.props.children.localeCompare(b[key].title.props.children))
                }
                return(a[key].title.props.children.localeCompare(b[key].title))
            }
            return (a[key].title.localeCompare(b[key].title))
        })
        if (direction) {
            sortedArray.reverse()
        }
        return (sortedArray)
    }

    const clearFilter = () => {
        props.clearFilters?.()
        setFiltersCount(0)
    }

    const FilterChange = (name, id, value, action) => {
        action(name, id, value)
        if (value !== "default") {
            setFiltersCount(filtersCount + 1)
        } else {
            setFiltersCount(filtersCount - 1)
        }
    }

    const ToggleSubRow = rowkey => {
        let openRow = null
        let newRows = []
        if(openRows) {
            openRow = openRows.find(openRow => openRow === rowkey)
        }
        if(openRow !== undefined) {
            for(let i = 0; i < openRows.length; i++ ) {
                if(openRows[i] !== openRow){
                    newRows.push(openRows[i])
                }
            }
        } else {
            if(openRows) {
                newRows = [
                    ...openRows
                ]
            }
            newRows.push(rowkey)
        }
        setOpenRows(newRows)
    }

    const CheckSubRowStatus = rowkey => {
        if(openRows !== undefined) {
            const openRow = openRows.find(openRow => openRow === rowkey)
            if(openRow !== undefined) {
                return true
            }
        }
        return false
    }

// <TextInput onChange={e => setSearchText(e.target.value)} value={searchText} placeholder="Search" icon={<BiSearchAlt />} />
//                    <Searchfield onChange={e=> setSearchText(e.target.value)} value = {searchText}/>
// <Searchfield label = "Search Field" value={label} onChange={e => setLabel(e.target.value)}/>
    return (
        <Block>
            <Block display="flex" justify="space-between" align="center">
                <TableTitle>{props.title}</TableTitle>
                {props.action && <Block width="auto"
                    display="flex"
                    align="center">
                    <Button onClick={props.action}>
                        <TableActionIcon><BiPlus />{props.actionText}</TableActionIcon>
                    </Button>
                    {props.tableControl && <Block width="1px" height="22px" margin={`0 ${Spacing.SPACING_06}`} background={Color.DISABLED_BG} />}
                    {props.tableControl && <TableHeaderDots onClick={props.tableControl}><MdMoreVert /></TableHeaderDots>}
                </Block>}
            </Block>
            {(props.filters || props.search) && <Block display="flex" justify="flex-start" align="flex-end" margin={`0 0 ${Spacing.SPACING_04} 0`}>
                <Block display="flex" flexDirection="column" width="auto">
                    {props.searchControls && <Block margin={`0 0 ${Spacing.SPACING_05} 0`}>
                        {props.searchControls}
                    </Block>}
                    {props.search && <Block width="auto" margin={`0 ${Spacing.SPACING_05} 0 0`}>
                        <SearchField label = "Search Field" value={searchText} onChange={e => setSearchText(e.target.value)}/>
                    </Block>}
                </Block>
                {props.filters && <Block display="flex" align="center">
                    <Button type="tertiary" onClick={() => setShowFilters(!showFilters)}>
                        <TableActionIcon>{(filtersCount > 0) ? <TableFiltersCount>{filtersCount}</TableFiltersCount> : <BiFilter />}Filters</TableActionIcon>
                    </Button>
                    <TableRowCount>{rows.length} Items</TableRowCount>
                </Block>}
            </Block>}
            {showFilters && <Block display="grid"
                columns="calc(20% - 13px) calc(20% - 13px) calc(20% - 12px) calc(20% - 13px) calc(20% - 13px)"
                columnGap={Spacing.SPACING_05}
                rowGap={Spacing.SPACING_04}
                margin={`0 0 ${Spacing.SPACING_04}`}>
                {props.filters.map((filter, key) => <Block key={`Table-Filter-${key}-${filter.label}-${filter.name}`}
                    display="flex"
                    flexDirection="column">
                    {filter.label && <TableFilterLabel htmlFor={filter.id}>{filter.label}:</TableFilterLabel>}
                    <TableFilterSelect value={filter.selectedValue || "default"} onChange={(event) => FilterChange(filter.name, filter.id, event.target.value, filter.action)} defaultValue="default" name={filter.name} id={filter.id}>
                        {filter.defaultLabel && <option value="default">{filter.defaultLabel}</option>}
                        {filter.options.map((option, optionkey) => <option key={`Table-Filter-${filter.label}-Option-${option.name}-${optionkey}`} value={option.value}>
                            {option.name}
                        </option>)}
                    </TableFilterSelect>
                </Block>)}
            </Block>}
            {(filtersCount > 0) && <TableClearFilters onClick={clearFilter}>
                Clear Filters
            </TableClearFilters>}
            <TableStyles>
                <thead>
                    <TableHeadRow>
                        {props.header?.map((head, headerkey) => {
                            if (head.alignRight) {
                                rightAlignedColumns.push(headerkey)
                            }
                            return (
                                <TableHead subrowHeader={props.subrowHeader && true} rowControl={props.rowControl} alignRight={head.alignRight && head.alignRight} active={activeColumn === headerkey} key={`Table-Head-${headerkey}`}>
                                    {head.title}
                                    {!head.disabled && <TableHeadControl onClick={() => OrderColumn(head.callback, headerkey, head.title, head.action)}>
                                        <TableOrderArrow active={!columnOrderDown && (activeColumn === headerkey)}><MdArrowDropUp /></TableOrderArrow>
                                        <TableOrderArrow active={columnOrderDown && (activeColumn === headerkey)}><MdArrowDropDown /></TableOrderArrow>
                                    </TableHeadControl>}
                                </TableHead>
                            )
                        })}
                        {props.rowControl && <TableHead />}
                    </TableHeadRow>
                </thead>
                <tbody>
                    {rows?.map((row, rowkey) => {
                        const rowMin = ((page + 1) * paginationCount) - paginationCount
                        const rowMax = ((page + 1) * paginationCount) - 1
                        if ((rowkey >= rowMin) && (rowkey <= rowMax)) {
                            return (
                                <TableRow key={`Table-Row-${rowkey}`}>
                                    {row.map((data, datakey) => {
                                        let alignRight = false
                                        if (rightAlignedColumns.find(e => e === datakey)) {
                                            alignRight = true
                                        }
                                        return (
                                            <TableDown subrowHeader={props.subrowHeader && true} dropdown={data.dropdown && true} rowControl={props.rowControl} alignRight={alignRight} key={`Table-Down-${datakey}`}>
                                                {data.dropdown ? <Block cursor="pointer" width="auto"
                                                    padding={`0 0 ${(CheckSubRowStatus(rowkey) && props.subrowHeader) ? Spacing.SPACING_05 : '0'} 0`}
                                                    display="flex" align="center" justify="flex-start"
                                                    onClick={() => ToggleSubRow(rowkey)}>
                                                    {CheckSubRowStatus(rowkey)
                                                        ? <Block width="auto" margin={`0 ${Spacing.SPACING_05} 0 ${props.subrowHeader ? Spacing.SPACING_05 : '0'}`}><FaChevronUp /></Block>
                                                        : <Block width="auto" margin={`0 ${Spacing.SPACING_05} 0 ${props.subrowHeader ? Spacing.SPACING_05 : '0'}`}><FaChevronDown /></Block>
                                                    }
                                                    {data.title}
                                                </Block>
                                                : <Block padding={(data.subtitle && CheckSubRowStatus(rowkey) && props.subrowHeader) && `0 0 ${Spacing.SPACING_05} 0`}>{data.title}</Block>}
                                                {(data.subtitle && CheckSubRowStatus(rowkey)) && <Block padding={ `0 ${props.subrowHeader && Spacing.SPACING_05} 0 0`}
                                                    borderTop={props.subrowHeader && `1px solid ${Color.DISABLED_BG}`}>
                                                    {data.subtitle.map((subtitle, subkey) => <Block
                                                        padding={(datakey === 0) ? (props.subrowHeader ? `${Spacing.SPACING_05} 0 0 45.5px`:`${Spacing.SPACING_05} 0 0 29.5px`): `${Spacing.SPACING_05} 0 0 0`} 
                                                        width="auto" key={`Table-Subrow-${subkey}-${subtitle}`}>
                                                        {subtitle}
                                                    </Block>)}
                                                </Block>}
                                            </TableDown>
                                        )
                                    })}
                                    {props.rowControl && <TableDots subrowHeader={props.subrowHeader && true} onClick={() => setRowPopup(rowkey)}><MdMoreVert /></TableDots>}
                                    {(rowPopup === rowkey) && <OutsideHandler condition={rowkey >= 0} action={() => setRowPopup(false)}><TableRowPopup>
                                        {props.rowControl.map((control, key) => {
                                            return (<TableRowControl key={`row-control-${key}`}>
                                                {control}
                                            </TableRowControl>)
                                        })}
                                    </TableRowPopup></OutsideHandler>}
                                </TableRow>
                            )
                        }
                        return null
                    })}
                </tbody>
            </TableStyles>
            {(rows.length > 30) && <Block margin={`${Spacing.SPACING_06} 0 0 0`} display="flex" justify="space-between" flexDirection="row" align="center">
                <Block width="auto" display="flex" flexDirection="row">
                    <TablePaginationText>
                        View
                    </TablePaginationText>
                    {paginationOptions && paginationOptions.map((option, key) => <TablePaginationText key={`Table-Pagination-Option-${option}-${key}`}
                        onClick={() => ClickPaginationCount(option)}
                        color={((paginationCount === option) ? Color.PRIMARY_BLUE : Color.GREY)}
                        selected={paginationCount === option}
                        margin={`0 ${Spacing.SPACING_02}`}
                        padding="0 2px 6px 2px"
                        cursor="pointer"
                        weight={((paginationCount === option) ? '700' : '100')}>
                        {option}
                    </TablePaginationText>)}
                </Block>
                <Block width="auto" display="flex" flexDirection="row" align="center">
                    <TablePaginationText weight="100">
                        Page {page + 1} of {pages}
                    </TablePaginationText>
                    {pageOptions.map((option, key) => {
                        if (!option.disabled) {
                            return (
                                <TablePaginationIcon key={`Table-PageOption-${key}`}
                                    onClick={option.action}>
                                    <img src={option.icon} />
                                </TablePaginationIcon>
                            )
                        }
                        return (
                            <TablePaginationIcon key={`Table-PageOption-${key}`}
                                disabled={true}>
                                <img src={option.disabledicon} />
                            </TablePaginationIcon>
                        )
                    })}
                </Block>
            </Block>}
        </Block>
    )
}

Table.propTypes = {
    /** Alert message sent on a table action, example: Row Added */
    action: func,
    /** A node that can be passed in for the table action, example Add Row */
    actionText: string,
    /** Whether there is a search option or not */
    search: bool,
    /** Array of table filters */
    filters: arrayOf(shape({
        /** Label of filter, for example "Choose a car" */
        label: string,
        /** Name of filter */
        name: string,
        /** ID of filter */
        id: string,
        /** Default label that is desplayed, example: "All Cars" */
        label:string,
        /** Alert that displays when filter is changed */
        action: func,
        /** Array of options to choose from, example: [{name: "Audi", value: "audi"}, {name: "Bentley", value: "bentley"},] */
        options: arrayOf(shape({
            /** Name of filter */
            name: string,
            /** String value of filter */
            value: string,
        })),
    })),
    /** Alert displayed when filters are cleared */
    clearFilters: func,
    /** Alert function for table control */
    tableControl: func,
    /** Header for Table, Array with title, disabled, and callback */
    header: arrayOf(shape({
            /** Title of header */
            title:string,
            /** Whether header is disabled or not */
            disabled:bool,
            /** Alert displayed when header is selected to sort */
            callback:func
        }
    )), 
    /** Array of rows that contain data */ //Should this variable be renamed?
    // rows: arrayOf(shape(arrayOf(
    //     {
    //         /** Title for row */
    //         title:string
    //     }
    // )
    // )).isRequired, 
    /** Row control that allows for different options to be chosen */
    rowControl: arrayOf(shape({
        /** Alert that displays when the rowControl is clicked */
        onClick:func   
    }
    )),

}
Table.descriptions = {
    action: ["function","","False",`Alert message sent on a table action, example: Row Added`],
    actionText: ["string","","False",`A node that can be passed in for the table action, example Add Row`],
    search: ["bool","","False",`Whether there is a search option or not`],
    filters: ["Object(label(string), name(string), id(string), label(string), action(function), options(object(title(string), disabled(bool), callback(func) )) )","","False",`Array of table filters`],
    clearFilters: ["function","","False",`Alert displayed when filters are cleared`],
    tableControl: ["function","","False",`Alert function for table control`],
    header: ["Object(title(string),disabled(bool),callback(func))","","False",`Header for Table, Array with title, disabled, and callback`],
    rows: ["Object(title(string))","Default needed","True",`Array of rows that contain data`],
    rowControl: ["Object(onclick(func))","","False",`Row control that allows for different options to be chosen`],
}