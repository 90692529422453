import { useState, Fragment } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from '../../simple/button/index.js'
import { Block } from '../../simple/layout/index.js'
import * as AppRoutes from '../../routes.js'
import { Card } from '../../simple/card/index.js'
import { TextInput } from '../../simple/input/index.js'
import * as Spacing from '../../spacing.js'
import { FiMail } from "react-icons/fi/index.esm.js"
import { BsFillShieldLockFill } from "react-icons/bs/index.esm.js"
import { FaLock, FaLockOpen } from "react-icons/fa/index.esm.js"


export const AuthSignIn = () => {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    return (
        <Block padding={Spacing.SPACING_05} width={`calc(100% - ${Spacing.SPACING_09})`} display="flex" justify="center">
            <Block width="768px">
                <Card header="Sign into your account" action={() => alert(`Username: ${username}, Password: ${password}`)} actionText="Sign In">
                    <Block width={`calc(100% - ${Spacing.SPACING_09})`} padding={`0 ${Spacing.SPACING_06}`}>
                        <Block margin={`0 0 ${Spacing.SPACING_06} 0`}>
                            <TextInput label="Username" value={username} onChange={e => setUsername(e.target.value)} />
                        </Block>
                        <Block>
                            <TextInput type="password" label="Password" value={password} onChange={e => setPassword(e.target.value)} icon={<FaLockOpen />} />
                        </Block>
                    </Block>
                </Card>
            </Block>
        </Block>
    )
}

export const AuthSignUp = () => {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [cPassword, setCPassword] = useState("")

    return (
        <Block padding={Spacing.SPACING_05} width={`calc(100% - ${Spacing.SPACING_09})`} display="flex" justify="center">
            <Block width="768px">
                <Card header="Sign up for a new account"
                    subHeader="One account for everything Trane!"
                    action={() => alert(`Username: ${username}, Password: ${password}`)}
                    actionText={<Block display="flex" justify="center">
                        <Block width="auto" margin={`0 ${Spacing.SPACING_03} 0 0`}>
                            <FaLock />
                        </Block>
                        Sign Up
                    </Block>}>
                    <Block width={`calc(100% - ${Spacing.SPACING_09})`} padding={`0 ${Spacing.SPACING_06}`}>
                        <Block margin={`0 0 ${Spacing.SPACING_06} 0`}>
                            <TextInput label="Username" value={username} onChange={e => setUsername(e.target.value)} />
                        </Block>
                        <Block margin={`0 0 ${Spacing.SPACING_06} 0`}>
                            <TextInput type="password" label="Password" value={password} onChange={e => setPassword(e.target.value)} icon={<FaLockOpen />} />
                        </Block>
                        <Block>
                            <TextInput type="password" label="Confirm Password"
                                status={(password !== cPassword) ? "error" : "enabled"}
                                help={(password !== cPassword) && "Passwords do not match"}
                                value={cPassword} onChange={e => setCPassword(e.target.value)}
                                icon={<BsFillShieldLockFill />} />
                        </Block>
                    </Block>
                </Card>
            </Block>
        </Block>
    )
}

export const AuthForgotPassword = () => {
    const [email, setEmail] = useState("")

    return (
        <Block padding={Spacing.SPACING_05} width={`calc(100% - ${Spacing.SPACING_09})`} display="flex" justify="center">
            <Block width="768px">
                <Card header="Forgot your password?"
                    subHeader="Fill out the form below and click submit and we will send you an email."
                    action={() => alert(`Email: ${email}`)}
                    actionText={<Block display="flex" justify="center">
                        <Block width="auto" margin={`0 ${Spacing.SPACING_03} 0 0`}>
                            <FiMail />
                        </Block>
                        Submit
                    </Block>}>
                    <Block width={`calc(100% - ${Spacing.SPACING_09})`} padding={`0 ${Spacing.SPACING_06}`}>
                        <Block>
                            <TextInput label="Email Address" value={email} onChange={e => setEmail(e.target.value)} icon={<FiMail />} />
                        </Block>
                    </Block>
                </Card>
            </Block>
        </Block>
    )
}