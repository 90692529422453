import { Fragment, useState } from "react"
import { Block } from "../../components/simple/layout/index.js"
import { TextInput, DropList, DropChecklist, SearchField, DropSearchField } from "../../components/simple/input/index.js"
import * as Color from '../../components/color.js'
import { MdOutlineCalendarToday } from "react-icons/md/index.esm.js"
import { Selectrix } from "../../components/simple/input/selectrix.js"
import { Showcode } from "../../components/simple/Documentation/showcode.js"
const defaultList = [
    "Blue",
    "Yellow",
    "Red",
    "Green",
    "Yellow",
]

const options = [
    {value:"Travel", selected:true},
    {value:"Education", selected:false},
    {value:"Government", selected:false},
    ]

export const InputPage = () => {

    const [text, setText] = useState("")
    const [label, setLabel] = useState("")

    const data = `
    <TextInput onChange={e => setText(e.target.value)} value={text} label="Label" help="Help text" limit={50} icon={<MdOutlineCalendarToday />} />
    <TextInput onChange={e => setText(e.target.value)} value={text} status="disabled" label="Label" help="Help text" />
    <TextInput onChange={e => setText(e.target.value)} value={text} status="error" label="Label" help="Help text" />`
    const data2 = `
    <TextInput onChange={e => setText(e.target.value)} value={text} dark label="Label" help="Help text" />
    <TextInput onChange={e => setText(e.target.value)} value={text} dark status="disabled" label="Label" help="Help text" />
    <TextInput onChange={e => setText(e.target.value)} value={text} dark status="error" label="Label" help="Help text" />
`
    const data3 = `<TextInput resize="none" height="100px" type="textarea"
    onChange={e => setText(e.target.value)} value={text} label="Label" help="Help text" />
`
    const data4 = `<DropList options={["One","Two","Three","Four","Five"]}
    onChange={e => setText(e.target.value)} value={text} label="Label" help="Help text" />
`
    const data5 = `<DropList options={defaultList} label="Default Label" />
    `
    const data6 = `<DropChecklist options={defaultList} label="Default Label" />
    `
    const data7 = `<SearchField label="Search Field" value={label} onChange={e => setLabel(e.target.value)}/>
    `
    const data8 = `<DropSearchField options={defaultList} label="Search Dropdown" value={label} onChange={e => setLabel(e.target.value)}/>
    `
    const data9 = `<Selectrix className="TestChecks" keyVal="Storage" labelType= "Values" 
    options = {options} />`
    return(
        <Fragment>
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="flex-start">
                <TextInput onChange={e => setText(e.target.value)} value={text} label="Label" help="Help text" limit={50} icon={<MdOutlineCalendarToday />} />
                <TextInput onChange={e => setText(e.target.value)} value={text} status="disabled" label="Label" help="Help text" />
                <TextInput onChange={e => setText(e.target.value)} value={text} status="error" label="Label" help="Help text" />
            </Block>
            <Showcode data = {data} />
            <Block display="flex" width="calc(100% - 40px)" padding="20px" background={Color.DARK_GREY_BG_2} justify="space-evenly">
                <TextInput onChange={e => setText(e.target.value)} value={text} dark label="Label" help="Help text" />
                <TextInput onChange={e => setText(e.target.value)} value={text} dark status="disabled" label="Label" help="Help text" />
                <TextInput onChange={e => setText(e.target.value)} value={text} dark status="error" label="Label" help="Help text" />
            </Block>
            <Showcode data = {data2} />
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="flex-start">
                <TextInput resize="none" height="100px" type="textarea"
                    onChange={e => setText(e.target.value)} value={text} label="Label" help="Help text" />
            </Block>
            <Showcode data = {data3} />
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="flex-start">
                <DropList options={["One","Two","Three","Four","Five"]}
                    onChange={e => setText(e.target.value)} value={text} label="Label" help="Help text" />
            </Block>
            <Showcode data = {data4} />
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="flex-start">
                <DropList options={defaultList} label="Default Label" />
            </Block>
            <Showcode data = {data5} />
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="flex-start">
                <DropChecklist options={defaultList} label="Default Label" />
            </Block>
            <Showcode data = {data6} />
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="flex-start">
                <SearchField label="Search Field" value={label} onChange={e => setLabel(e.target.value)}/>
            </Block>
            <Showcode data = {data7} />
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="flex-start">
                <DropSearchField options={defaultList} label="Search Dropdown" value={label} onChange={e => setLabel(e.target.value)}/>
            </Block>
            <Showcode data = {data8} />
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="flex-start">
                <Selectrix className="TestChecks" keyVal="Storage" labelType= "Values" 
                options = {options} />
            </Block>
            <Showcode data = {data9} />
        </Fragment>
    )
}