import { MdInfo } from "react-icons/md/index.esm.js"
import { ReactComponent as TraneConnect } from "../../components/assets/icons/trane_connect.svg"
import { ReactComponent as TraneComponents } from "../../components/assets/icons/documents_and_reports.svg"
import * as AppRoutes from '../../components/routes.js'

const user = {
    name: "User Name"
}

const notifications = [
    'Test',
    'Test',
    'Test'
]

export const NavigationData = {
    app: {
        title: 'Trane Components',
        // selected: {
        //     title: 'Finneytown High School',
        //     subtitle: '1234 Address Place NE'
        // },
        // selectedoptions: [
        //     {
        //         title: 'Finneytown High School',
        //         subtitle: '1234 Address Place NE'
        //     },
        //     {
        //         title: 'Finneytown High School',
        //         subtitle: '1234 Address Place NE'
        //     },
        //     {
        //         title: 'Finneytown High School',
        //         subtitle: '1234 Address Place NE'
        //     },
        // ],
        options: [
            {
                title: 'Getting Started',
                icon: null,
                route: AppRoutes.HOME,
                // onClick: () => alert('Clicked Home'),
            },
            {
                title: 'Foundation',
                icon: null,
                route: AppRoutes.HOME,
                options: [
                    {
                        title: 'Color',
                        route: AppRoutes.COLOR
                    },
                ]
            },
            {
                title: 'Elements',
                icon: null,
                route: AppRoutes.HOME,
                options: [
                    {
                        title: 'Accordion',
                        icon: null,
                        route: AppRoutes.ACCORDION
                    },
                    {
                        title: 'Breadcrumb',
                        icon: null,
                        route: AppRoutes.BREADCRUMB
                    },
                    {
                        title: 'Button',
                        icon: null,
                        route: AppRoutes.BUTTON
                    },
                    {
                        title: 'Card',
                        icon: null,
                        route: AppRoutes.CARD
                    },
                    {
                        title: 'Calendar',
                        icon: null,
                        route: AppRoutes.CALENDAR_PICKER
                    },
                    {
                        title: 'Chat',
                        icon: null,
                        route: AppRoutes.CHAT
                    },
                    {
                        title: 'Input',
                        icon: null,
                        route: AppRoutes.INPUT
                    },
                    {
                        title: 'List',
                        icon: null,
                        route: AppRoutes.LIST
                    },
                    {
                        title: 'Modal',
                        icon: null,
                        route: AppRoutes.MODAL
                    },
                    {
                        title: 'Notification',
                        icon: null,
                        route: AppRoutes.NOTIFICATION
                    },
                    {
                        title: 'Progress',
                        icon: null,
                        route: AppRoutes.PROGRESS
                    },
                    {
                        title: 'Tab',
                        icon: null,
                        route: AppRoutes.TAB
                    },
                    {
                        title: 'Table',
                        icon: null,
                        route: AppRoutes.TABLE
                    },
                    {
                        title: 'Time Picker',
                        icon: null,
                        route: AppRoutes.TIME_PICKER
                    },
                    {
                        title: 'Tooltip',
                        icon: null,
                        route: AppRoutes.TOOLTIP
                    },
                ]
            },
            {
                title: 'Modules',
                icon: null,
                route: AppRoutes.HOME,
                options: [
                    {
                        title: 'Auth',
                        route: AppRoutes.AUTH
                    },
                ]
            },
        ]
    },
    // topnav: {
    //     options: [
    //         {
    //             name: "Support",
    //             onClick: () => alert('Example Click'),
    //             options: [
    //                 {
    //                     name: "About Trane Connect"
    //                 },
    //                 {
    //                     name: "Trane University"
    //                 },
    //                 {
    //                     name: "Request Help / Give Feedback"
    //                 }
    //             ]
    //         },
    //         {
    //             name: user.name,
    //             options: [
    //                 {
    //                     name: "My Profile"
    //                 },
    //                 {
    //                     name: "Reset Password"
    //                 },
    //                 {
    //                     name: "Logout"
    //                 }
    //             ]
    //         },
    //         {
    //             name: "Notifications",
    //             header: {
    //                 title: "Alarms & Notifications",
    //                 action: {
    //                     name: "Settings",
    //                     onClick: () => alert("Clicked")
    //                 }
    //             },
    //             empty: 'No new alarms or notifications',
    //             footer: {
    //                 action: {
    //                     name: "See all alarms",
    //                     onClick: () => alert("Clicked")
    //                 }
    //             },
    //             notifications: notifications
    //         },
    //     ]
    // },
    popout: [
        {
            title: 'Trane Components',
            icon: <TraneComponents />,
            onClick: () => alert('Navigate to Place 1'),
            route: AppRoutes.TABLE
        },
        {
            title: 'Trane Connect',
            icon: <TraneConnect />,
            url: 'http://www.ianhess.online/'
        },
    ]
}