import styled from "styled-components"
import * as Color from '../../color.js'
import * as Spacing from '../../spacing.js'
import { InputContainer, InputLabel } from './textinput.js'
import { useState } from "react"

const DateBox = styled.input`
    border: 1px solid ${props => InputTheme[props.dark ? "dark" : "light"][props.status].border};
    background: ${props => InputTheme[props.dark ? "dark" : "light"][props.status].background};
    color: ${props => InputTheme[props.dark ? "dark" : "light"][props.status].color};
    box-shadow: ${props => (props.status === "active") && "0px 0px 5px 0px rgba(15, 51, 95, 0.2)"};
    padding: ${Spacing.SPACING_03} ${props => props.icon ? Spacing.SPACING_08 : Spacing.SPACING_03} ${Spacing.SPACING_03} ${Spacing.SPACING_03};
    border-radius: ${props => props.radius ? props.radius : '6px'};
    display: flex;
    font-family: ArialMT;
    font-size: 16px;
    margin: 0;
    width: calc(100% - ${props => props.icon ? "42px" : "18px"});
    letter-spacing: 0.32px;
    outline: none;
    &:hover {
        border: 1px solid ${props => InputTheme[props.dark ? "dark" : "light"][props.status].hoverBorder};
        background: ${props => InputTheme[props.dark ? "dark" : "light"][props.status].hoverBackground};
        color: ${props => InputTheme[props.dark ? "dark" : "light"][props.status].hoverColor};
    }
`

export const InputTheme = {
    light: {
        active: {
            background: Color.INPUT_BACKGROUND,
            color: Color.DARK_GREY,
            border: Color.PRIMARY_BLUE,
            hoverBackground: Color.INPUT_BACKGROUND,
            hoverColor: Color.DARK_GREY,
            hoverBorder: Color.PRIMARY_BLUE,
            labelColor: Color.PRIMARY_BLUE,
            helpColor: Color.TEXT_SECONDARY_2
        },
        enabled: {
            background: Color.INPUT_BACKGROUND,
            color: Color.DARK_GREY,
            border: Color.TEXT_HOVER_1,
            hoverBackground: Color.INPUT_BACKGROUND,
            hoverColor: Color.DARK_GREY,
            hoverBorder: Color.TEXT_HOVER_1,
            labelColor: Color.MED_GREY,
            helpColor: Color.TEXT_SECONDARY_2
        },
        disabled: {
            background: Color.DISABLED_BG,
            color: Color.DISABLED_TEXT,
            border: Color.DISABLED_BG,
            hoverBackground: Color.DISABLED_BG,
            hoverColor: Color.DISABLED_TEXT,
            hoverBorder: Color.DISABLED_BG,
            labelColor: Color.MED_GREY,
            helpColor: Color.TEXT_SECONDARY_2
        },
        error: {
            background: Color.LIGHT_PINK_BG,
            color: Color.DARK_GREY,
            border: Color.ERROR_RED,
            hoverBackground: Color.LIGHT_PINK_BG,
            hoverColor: Color.DARK_GREY,
            hoverBorder: Color.ERROR_RED,
            labelColor: Color.ERROR_RED,
            helpColor: Color.ERROR_RED
        },
        ghost: {
            background: 'transparent',
            color: Color.DARK_GREY,
            border: 'transparent',
            hoverBackground: 'transparent',
            hoverColor: Color.DARK_GREY,
            hoverBorder: 'transparent',
            labelColor: Color.MED_GREY,
            helpColor: Color.TEXT_SECONDARY_2
        }
    },
    dark: {
        active: {
            background: Color.HAIRLINE,
            color: Color.ZEBRA_STRIPE_BG,
            border: Color.DM_BLUE,
            hoverBackground: Color.HAIRLINE,
            hoverColor: Color.ZEBRA_STRIPE_BG,
            hoverBorder: Color.DM_BLUE,
            labelColor: Color.DM_BLUE,
            helpColor: Color.DM_GREY
        },
        enabled: {
            background: Color.HAIRLINE,
            color: Color.ZEBRA_STRIPE_BG,
            border: Color.ZEBRA_STRIPE_BG,
            hoverBackground: Color.HAIRLINE,
            hoverColor: Color.ZEBRA_STRIPE_BG,
            hoverBorder: Color.ZEBRA_STRIPE_BG,
            labelColor: Color.TEXT_HOVER_1,
            helpColor: Color.DM_GREY
        },
        disabled: {
            background: Color.DARK_GREY_BG_3,
            color: Color.TEXT_HOVER_2,
            border: Color.DARK_GREY_BG_2,
            hoverBackground: Color.DARK_GREY_BG_2,
            hoverColor: Color.TEXT_HOVER_2,
            hoverBorder: Color.DARK_GREY_BG_2,
            labelColor: Color.TEXT_HOVER_1,
            helpColor: Color.DM_GREY
        },
        error: {
            background: Color.DARK_GREY_BG_1,
            color: Color.ZEBRA_STRIPE_BG,
            border: Color.DARK_ERROR_RED,
            hoverBackground: Color.DARK_GREY_BG_1,
            hoverColor: Color.ZEBRA_STRIPE_BG,
            hoverBorder: Color.DARK_ERROR_RED,
            labelColor: Color.DARK_ERROR_RED,
            helpColor: Color.DARK_ERROR_RED
        }
    }
}

export const DateField = props =>
{
    const {dark, value, label, placeholder, radius, status, onChange, width} = props
    const [newStatus, setNewStatus] = useState(status)

    const DetectFocus = () => {
        if(status !== "error" && status !== "disabled") {
            setNewStatus("active")
        }
    }

    const HandleDate = (event) => {
        const newDate = new Date(event.target.value.replaceAll("-", "/"))
        onChange(newDate)
    }

    const Year = value.getFullYear()
    const Month = (value.getMonth() + 1).toString().padStart(2, "0")
    const Day = value.getDate().toString().padStart(2, "0")
    const DateString = `${Year}-${Month}-${Day}`

    return(
        <InputContainer width={width}>
            <InputLabel htmlFor="date" dark={dark && dark} status={newStatus ? newStatus : "enabled"}>
                {label}
            </InputLabel>
            <DateBox onBlur={() => setNewStatus(status)}
                onFocus={DetectFocus}
                radius={radius && radius}
                disabled={newStatus === "disabled"}
                dark={dark && dark}
                status={newStatus ? newStatus : "enabled"}
                placeholder={placeholder && placeholder}
                type="date" name="date"
                onChange={HandleDate}
                value={DateString} />
        </InputContainer>
    )
}