import React, {createContext, useReducer} from "react"
import Reducer from './reducer.js'
import { WithEventListeners } from './witheventlisteners.js'

const initialState = {
    user: {},
    loading: null,
    popup: null,
    scroll: 0,
    resize: 0,
    key: ''
}

export const Context = createContext(initialState)

export const Store = ({children}) => {
    const [state, dispatch] = useReducer(Reducer, initialState)
    return (
        <Context.Provider value={[state, dispatch]}>
            <WithEventListeners>
                {children}
            </WithEventListeners>
        </Context.Provider>
    )
}