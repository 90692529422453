import { Fragment } from "react"
import { Button } from "../../components/simple/button/index.js"
import { Block } from "../../components/simple/layout/index.js"
import * as Color from "../../components/color.js"
import { PropTable } from "../../components/simple/Documentation/proptable/proptable.js"
import { Showcode } from "../../components/simple/Documentation/showcode.js"

export const ButtonPage = () => {
    const dataShow = `<Button onClick={() => alert("Default")}>
    Default
</Button>
<Button status="disabled" onClick={() => alert("Default")}>
    Disabled
</Button>
<Button status="destructive" onClick={() => alert("Default")}>
    Destructive
</Button>

<Button mode="dark" onClick={() => alert("Default")}>
    Default
</Button>
<Button mode="dark" status="disabled" onClick={() => alert("Default")}>
    Disabled
</Button>
<Button mode="dark" status="destructive" onClick={() => alert("Default")}>
    Destructive
</Button>`
const dataShow2 = `<Button type="secondary" onClick={() => alert("Default")}>
Default
</Button>
<Button type="secondary" status="disabled" onClick={() => alert("Default")}>
Disabled
</Button>
<Button type="secondary" status="destructive" onClick={() => alert("Default")}>
Destructive
</Button>

<Button type="secondary" mode="dark" onClick={() => alert("Default")}>
    Default
</Button>
<Button type="secondary" mode="dark" status="disabled" onClick={() => alert("Default")}>
    Disabled
</Button>
<Button type="secondary" mode="dark" status="destructive" onClick={() => alert("Default")}>
    Destructive
</Button>
                `
const dataShow3 = `<Button type="tertiary" onClick={() => alert("Default")}>
Default
</Button>
<Button type="tertiary" status="disabled" onClick={() => alert("Default")}>
Disabled
</Button>
<Button type="tertiary" status="destructive" onClick={() => alert("Default")}>
Destructive
</Button>

<Button type="tertiary" mode="dark" onClick={() => alert("Default")}>
    Default
</Button>
<Button type="tertiary" mode="dark" status="disabled" onClick={() => alert("Default")}>
    Disabled
</Button>
<Button type="tertiary" mode="dark" status="destructive" onClick={() => alert("Default")}>
    Destructive
</Button>`
const dataShow4 = `<Button type="ghost" onClick={() => alert("Default")}>
Default
</Button>
<Button type="ghost" status="disabled" onClick={() => alert("Default")}>
Disabled
</Button>
<Button type="ghost" status="destructive" onClick={() => alert("Default")}>
Destructive
</Button>

<Button type="ghost" mode="dark" onClick={() => alert("Default")}>
    Default
</Button>
<Button type="ghost" mode="dark" status="disabled" onClick={() => alert("Default")}>
    Disabled
</Button>
<Button type="ghost" mode="dark" status="destructive" onClick={() => alert("Default")}>
    Destructive
</Button>
`
const dataShow5 = `<Button type="text" onClick={() => alert("Default")}>
Default
</Button>
<Button type="text" status="disabled" onClick={() => alert("Default")}>
Disabled
</Button>
<Button type="text" status="destructive" onClick={() => alert("Default")}>
Destructive
</Button>

<Button type="text" mode="dark" onClick={() => alert("Default")}>
    Default
</Button>
<Button type="text" mode="dark" status="disabled" onClick={() => alert("Default")}>
    Disabled
</Button>
<Button type="text" mode="dark" status="destructive" onClick={() => alert("Default")}>
    Destructive
</Button>`

const dataShow6 = `<Button type="primary" status="enabled" width="250px" onClick={() => alert("Default")}> Width 250 </Button>
<Button type="primary" status="enabled" width="150px" onClick={() => alert("Default")}> Width 150 </Button>
<Button type="primary" status="enabled" width="50px" onClick={() => alert("Default")}> Width 50 </Button>
`
const dataShow7 = `<Button type="primary" status="enabled" children={<div>Children example with div</div>} onClick={() => alert("Default")}/>
`
const dataShow8 = `<Button type="primary" status="enabled" children={<div>Children example with div</div>} onClick={() => alert("Default")}/>
`
    return(
        <Fragment>
            <h1>Primary Buttons</h1>
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="space-evenly">
                <Button onClick={() => alert("Default")}>
                    Default
                </Button>
                <Button status="disabled" onClick={() => alert("Default")}>
                    Disabled
                </Button>
                <Button status="destructive" onClick={() => alert("Default")}>
                    Destructive
                </Button>
            </Block>
            <Block display="flex" width="calc(100% - 40px)" padding="20px" background={Color.DARK_GREY_BG_2} justify="space-evenly">
                <Button mode="dark" onClick={() => alert("Default")}>
                    Default
                </Button>
                <Button mode="dark" status="disabled" onClick={() => alert("Default")}>
                    Disabled
                </Button>
                <Button mode="dark" status="destructive" onClick={() => alert("Default")}>
                    Destructive
                </Button>
            </Block>
            <Showcode data = {dataShow} />
            <h1>Secondary Buttons</h1>
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="space-evenly">
                <Button type="secondary" onClick={() => alert("Default")}>
                    Default
                </Button>
                <Button type="secondary" status="disabled" onClick={() => alert("Default")}>
                    Disabled
                </Button>
                <Button type="secondary" status="destructive" onClick={() => alert("Default")}>
                    Destructive
                </Button>
            </Block>
            <Block display="flex" width="calc(100% - 40px)" padding="20px" background={Color.DARK_GREY_BG_2} justify="space-evenly">
                <Button type="secondary" mode="dark" onClick={() => alert("Default")}>
                    Default
                </Button>
                <Button type="secondary" mode="dark" status="disabled" onClick={() => alert("Default")}>
                    Disabled
                </Button>
                <Button type="secondary" mode="dark" status="destructive" onClick={() => alert("Default")}>
                    Destructive
                </Button>
            </Block>
            <Showcode data = {dataShow2} />
            <h1>Tertiary Buttons</h1>
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="space-evenly">
                <Button type="tertiary" onClick={() => alert("Default")}>
                    Default
                </Button>
                <Button type="tertiary" status="disabled" onClick={() => alert("Default")}>
                    Disabled
                </Button>
                <Button type="tertiary" status="destructive" onClick={() => alert("Default")}>
                    Destructive
                </Button>
            </Block>
            <Block display="flex" width="calc(100% - 40px)" padding="20px" background={Color.DARK_GREY_BG_2} justify="space-evenly">
                <Button type="tertiary" mode="dark" onClick={() => alert("Default")}>
                    Default
                </Button>
                <Button type="tertiary" mode="dark" status="disabled" onClick={() => alert("Default")}>
                    Disabled
                </Button>
                <Button type="tertiary" mode="dark" status="destructive" onClick={() => alert("Default")}>
                    Destructive
                </Button>
            </Block>
            <Showcode data = {dataShow3} />
            <h1>Ghost Buttons</h1>
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="space-evenly">
                <Button type="ghost" onClick={() => alert("Default")}>
                    Default
                </Button>
                <Button type="ghost" status="disabled" onClick={() => alert("Default")}>
                    Disabled
                </Button>
                <Button type="ghost" status="destructive" onClick={() => alert("Default")}>
                    Destructive
                </Button>
            </Block>
            <Block display="flex" width="calc(100% - 40px)" padding="20px" background={Color.DARK_GREY_BG_2} justify="space-evenly">
                <Button type="ghost" mode="dark" onClick={() => alert("Default")}>
                    Default
                </Button>
                <Button type="ghost" mode="dark" status="disabled" onClick={() => alert("Default")}>
                    Disabled
                </Button>
                <Button type="ghost" mode="dark" status="destructive" onClick={() => alert("Default")}>
                    Destructive
                </Button>
            </Block>
            <Showcode data = {dataShow4} />
            <h1>Text Buttons</h1>
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="space-evenly">
                <Button type="text" onClick={() => alert("Default")}>
                    Default
                </Button>
                <Button type="text" status="disabled" onClick={() => alert("Default")}>
                    Disabled
                </Button>
                <Button type="text" status="destructive" onClick={() => alert("Default")}>
                    Destructive
                </Button>
            </Block>
            <Block display="flex" width="calc(100% - 40px)" padding="20px" background={Color.DARK_GREY_BG_2} justify="space-evenly">
                <Button type="text" mode="dark" onClick={() => alert("Default")}>
                    Default
                </Button>
                <Button type="text" mode="dark" status="disabled" onClick={() => alert("Default")}>
                    Disabled
                </Button>
                <Button type="text" mode="dark" status="destructive" onClick={() => alert("Default")}>
                    Destructive
                </Button>
            </Block>
            <Showcode data = {dataShow5} />
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="space-evenly">
                <Button type="primary" status="enabled" width="250px" onClick={() => alert("Default")}> Width 250 </Button>
                <Button type="primary" status="enabled" width="150px" onClick={() => alert("Default")}> Width 150 </Button>
                <Button type="primary" status="enabled" width="50px" onClick={() => alert("Default")}> Width 50 </Button>
            </Block>
            <Showcode data = {dataShow6} />
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="space-evenly"> 
                <Button type="primary" status="enabled" size="small" onClick={() => alert("Default")}> Small button </Button>
                <Button type="primary" status="enabled" size="default" onClick={() => alert("Default")}> Default button </Button>
                <Button type="primary" status="enabled" size="large" onClick={() => alert("Default")}> Large button </Button>
            </Block>
            <Showcode data = {dataShow7} />
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="space-evenly">
                <Button type="primary" status="enabled" children={<div>Children example with div</div>} onClick={() => alert("Default")}/>
           </Block>
           <Showcode data = {dataShow8} />
            <PropTable descriptions={Button.descriptions} ></PropTable>
        </Fragment>
    )
}
