import React, { Fragment } from "react"
import { string, arrayOf, shape } from "prop-types"
import styled from 'styled-components'
import * as Color from '../../color.js'
import * as Spacing from '../../spacing.js'
import { Link } from "react-router-dom"

const BreadcrumbStyles = styled.ul`
    width: 100%;
    font-family: ArialMT;
    font-size: 14px;
    list-style: none;
    display: flex;
    justify-content: flex-start;
`

const BreadcrumbLink = styled.li`
    cursor: pointer;
    a {
        text-decoration: none;
        color: ${Color.MED_GREY};
        padding-bottom: 2px;
        &:hover {
            border-bottom: 1px solid ${Color.MED_GREY};
        }
    }
`

const LinkSeparator = styled.li`
    margin: 0 ${Spacing.SPACING_02};
    color: ${Color.MED_GREY};
`


export const Breadcrumb = props => {
  const { data } = props

  return(
    <BreadcrumbStyles>
        {data && data.map((breadcrumb, key) => {
            return(
                <Fragment key={`BreadcrumbLink-${breadcrumb.name}-${key}`}>
                    <BreadcrumbLink>
                        <Link to={breadcrumb.url}>{breadcrumb.name}</Link>
                    </BreadcrumbLink>
                    {((data.length > 1) && ((data.length - 1) > key)) && <LinkSeparator>
                        /
                    </LinkSeparator>}
                </Fragment>
            )
        })}
    </BreadcrumbStyles>
  )
}

Breadcrumb.propTypes = {
  /** An array of objects, each containing a url and a name */
  data: arrayOf(shape({
    /** Url that links to a page */
    url: string.isRequired,
    /** Name of the URL that is displayed to the user */
    name: string.isRequired
  })).isRequired
}
Breadcrumb.descriptions = {
    data: ["Object[url(string), name(string)]","","False","Array of objects containing url and name"],
    //Should url and name description be added, if so how? Can just add new columns -> not sure on subcols
}
