
//label (# selecteD)
//search\
//header
//select all
//select options

import { Button } from "../button/index.js"
import { DropList, DropChecklist, CheckList } from "./droplist.js"
import { SearchField } from "./searchfield.js"
import { useState, useEffect } from "react"

//<input type="checkbox" key="selectall"/>
export const Selectrix = props => {
    const {dark, options, labelType, keyVal, className} = props
    const [activeItemsCount, setActiveItemsCount] = useState(0)
    const [label, setLabel] = useState("Select one or more")
    const [selected, setSelected] = useState("")
    const [selectAll, setSelectAll] = useState("false")


    /*useEffect(() => {
           setActiveItemsCount(selected.length)
           setLabel(selected.length + " selected")
        }, [selected])
*/
        /*useEffect(() => {

        }, {options})*/

    //onchange         setLabel("One Selected")
/*const setLabels = (e) =>
{
    setSelected(getSelectedCheckboxes())
    console.log("Selected " + selected)
    console.log("Selected Length " +selected.length)
}*/

/*const getSelectedCheckboxes = props => {
    var checkList = []
    //`.${className}:input[type=checkbox]:checked`
    //:input[type=checkbox]:checked
    //var checkboxes = document.querySelectorAll(`input[type=checkbox]:checked.${className}`)
    var checkboxes = options
    //var checkboxes = document.getElementsByClassName(className)
    console.log(checkboxes)
    for (var i = 0; i < checkboxes.length; i++) {       
        checkList.push(checkboxes[i].value)
      }
    console.log("Checklist " + checkList)
    return checkList
}*/
//used to try and set the selected box to selected, selectall not currently working (need to get setSelectAll working)
const handleChange = (selectedOption) =>
{
    console.log("e",selectedOption)
    var target = selectedOption.target
    var selectedVal = target.value
    console.log("selected ", target, selectedVal)
    console.log("options", options)
    //console.log(options)
    for (var i=0; i< options.length; i++)
    {
        console.log("init", options[i])
        console.log("selected", target, selectedVal)
        if(selectedVal == "Select All")
        {
            //console.log("hi")
            options[i].selected = (!options[i].selected)
        }
        else if(selectedVal == options[i].value)
        {
            options[i].selected = (!options[i].selected) //should flip the selected option
            console.log(options[i].selected)
            console.log("i", options[i])
        }
        
        //console.log(options[i])
    }
    if(selectedVal == "Select All")
    {

    }
    console.log("finaloptions", options)
    //Change E to selected
}
 //Changed to only use handle change
/*const selectAllCheckboxes = props => { 
    setSelectAll(!selectAll)
    console.log(selectAll)
    if (selectAll)
    {
    var checkList = []
    var checkboxes = document.querySelectorAll(`input[type=checkbox].${className}`)
    //console.log(checkboxes)
    //console.log("test")
    for (var i = 0; i < checkboxes.length; i++) {
        checkList.push(checkboxes[i].value)
      }
    //console.log(checkList)
    console.log("Select All")
    setSelected(checkList)
    }
    else{
        console.log("Deselect All")
        setSelected()
    }
}*/
    //console.log(options)
    //console.log(options.value)
    return(
        <>
        <div>
            {labelType==="Selected" && 
            <label key={`Selected-Key-${keyVal}`}> {label ? label : "Select one or more"}  </label>
            }
            {labelType==="Values" && 
            <>
                <select>{options && options.map((option, key) => <option checked={option.selected} key={`option-${key}`}>{option.value}</option>)} </select>
                <>{options && options.map((option, key) => {
                    //console.log("option",option, option.value, option.selected);
                    if(option.selected)
                    {
                        return <div key={`option-${key}`} value={option.value}> {option.value}</div>
                    }
                })}
                </>
                
            </>
            }
        </div>
        <SearchField />
        <CheckList label=" " optionList = {[{value:"Select All", selected:false}]} onChange={handleChange}/>
        <CheckList className={className} optionList = {options} onChange={handleChange}/>
        </>
    )
}

//"Select All  " + options.length + " selected"

//e => {setSelectedBox(e)} 
//<CheckList label=" " optionList = {[{value:"Select All  " + options.length + " selected", selected:false}]} onChange={e => {setLabels(e.target.value); selectAllCheckboxes()} }/>
//<CheckList className={className} optionList = {options} onChange={e => {setLabels(e.target.value); setLabel(activeItemsCount + " selected") } }/>
//
//LabelType selected or values