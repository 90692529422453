const Reducer = (state, action) => {
    switch (action.type) {
        case 'SET_USER':
            return {
                ...state,
                user: action.payload
            }
        case 'SET_CART':
                return {
                    ...state,
                    cart: action.payload
                }
        case 'SET_LOADING':
            return {
                ...state,
                loading: action.payload
            }
        case 'SET_POPUP':
            return {
                ...state,
                popup: action.payload
            }
        case 'SET_SCROLL':
            return {
                ...state,
                scroll: action.payload
            }
        case 'SET_RESIZE':
            return {
                ...state,
                resize: action.payload
            }
        case 'SET_KEY':
            return {
                ...state,
                key: action.payload
            }
        default:
            return state
    }
}

export default Reducer