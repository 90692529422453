import React from "react"
import { node, string, func } from "prop-types"
import styled from 'styled-components'
import * as Color from '../../color.js'
import * as Spacing from '../../spacing.js'
import { Block } from "../layout/index.js"
import { IoCloseOutline } from "react-icons/io5/index.esm.js"
import { MdCheckCircle, MdDangerous, MdInfo, MdWarning } from "react-icons/md/index.esm.js"

export const NotificationTheme = {
    information: {
        background: Color.LIGHT_BLUE_BG,
        border: `1px solid ${Color.PRIMARY_BLUE}`,
        icon: <MdInfo />,
        iconColor: Color.PRIMARY_BLUE
    },
    error: {
        background: Color.LIGHT_PINK_BG,
        border: `1px solid ${Color.ERROR_RED}`,
        icon: <MdDangerous />,
        iconColor: Color.ERROR_RED
    },
    success: {
        background: Color.LIGHT_GREEN_BG,
        border: `1px solid ${Color.SUCCESS_GREEN}`,
        icon: <MdCheckCircle />,
        iconColor: Color.SUCCESS_GREEN
    },
    warning: {
        background: Color.LIGHT_ORANGE_BG,
        border: `1px solid ${Color.WARNING_ORANGE}`,
        icon: <MdWarning />,
        iconColor: Color.WARNING_ORANGE
    }
  }

const NotificationStyles = styled.section`
    background: ${props => NotificationTheme[props.type].background};
    border: ${props => NotificationTheme[props.type].border};
    border-radius: 10px;
    padding: ${Spacing.SPACING_06};
    width: 100%;
    box-shadow: 0px 2px 3px rgba(0, 0, 0,.2);
`

const NotificationHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const HeaderText = styled.h1`
    color: ${Color.DARK_GREY};
    font-family: ArialMT;
    font-size: 16px;
    margin: 0;
`

const HeaderIcon = styled.div`
    margin-right: ${Spacing.SPACING_02};
    width: 20px;
    height: 20px;
    font-size: 20px;
    color: ${props => NotificationTheme[props.type].iconColor};
`

const NotificationClose = styled.div`
    font-size: 20px;
    cursor: pointer;
    height: 20px;
    width: 20px;
`

const NotificationContent = styled.article`
    padding-left: ${Spacing.SPACING_07};
`


export const Notification = props => {
    const { type, header, closeAction, children } = props

    return(
        <NotificationStyles type={type}>
            <NotificationHeader>
                <Block display="flex" width="auto" justify="flex-start">
                    <HeaderIcon type={type}>
                        {NotificationTheme[type].icon}
                    </HeaderIcon>
                    <HeaderText>
                        {header}
                    </HeaderText>
                </Block>
                <NotificationClose onClick={closeAction}>
                    <IoCloseOutline />
                </NotificationClose>
            </NotificationHeader>
            <NotificationContent>
                {children}
            </NotificationContent>
        </NotificationStyles>
    )
}

Notification.propTypes = {
    /** What type of notification is this? Example: "information", "error", "success", "warning" */
    type: string.isRequired,
    /** Action to take upon clicking close icon */
    closeAction: func.isRequired,
    /** Text to appear in header of modal */
    header: string.isRequired,
    /** Node to appear in content of modal */
    children: node.isRequired,
}
Notification.descriptions = {
      // [Type, default, required, descriptions]
  type: ["string","Needs a default","True", `What type of notification is this? Example: "information", "error", "success", "warning" `],
  closeAction: ["function","Needs a default","True","Action to take upon clicking close icon"],
  header: ["string","Needs a default","True","Text to appear in header of modal"],
  children: ["node","Needs a default","True","Node to appear in content of modal"],
}