import React, {useState} from 'react'
import {AiOutlineSearch} from "react-icons/ai/index.esm.js"
import {BiSearchAlt } from "react-icons/bi/index.esm.js"
import { InputLabel, TextInput } from '../../simple/input/index.js'
import { DropList } from './droplist.js'
import styled from 'styled-components'
import * as Color from '../../color.js'

const SearchFieldContainer = styled.div`
    width: 100%;
`

const SearchInputContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    box-shadow: rgba(0,0,0,.3) 0px 2px 4px;
    border-radius: 6px;
    border: 1px solid ${Color.TEXT_HOVER_1};
`

export const SearchField = props => {
    const {label, value, onChange} = props
    //const [searchInput, setSearchInput] = useState(value ? value : "");
    //const [searchText, setSearchText] = useState("");
    //onChange = onChange ? onChange : setSearchInput

    //Might need to change from textinput to make selector be next to search field, or maybe override css?

    //<TextInput onChange={e => setSearchText(e.target.value)} value={searchText} placeholder="Search" icon={<BiSearchAlt />} />
    return <TextInput onChange={e => onChange(e)} value={value} label={label ? label: ""} placeholder='Search' icon={<AiOutlineSearch/>} />
}

export const DropSearchField = props => {
    const {label, value, onChange, options} = props
    //const [searchInput, setSearchInput] = useState(value ? value : "");
    //const [searchText, setSearchText] = useState("");
    //onChange = onChange ? onChange : setSearchInput

    //Might need to change from textinput to make selector be next to search field, or maybe override css?

    //<TextInput onChange={e => setSearchText(e.target.value)} value={searchText} placeholder="Search" icon={<BiSearchAlt />} />

    return (
        <SearchFieldContainer>
            {label && <InputLabel>{label}</InputLabel>}
            <SearchInputContainer>
                <DropList radius="6px 0 0 6px" options={options} status="button" />
                <TextInput radius="0 6px 6px 0" onChange={e => onChange(e)} value={value} placeholder='Search' status="ghost" icon={<AiOutlineSearch/>} />
            </SearchInputContainer>
        </SearchFieldContainer>
    )
}