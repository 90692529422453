import { Fragment } from "react"
import { Block } from "../../components/simple/layout/index.js"
import { SortableList } from "../../components/simple/list/index.js"
import * as Color from '../../components/color.js'
import { PropTable } from "../../components/simple/Documentation/proptable/proptable.js"
import { Showcode } from "../../components/simple/Documentation/showcode.js"
const data = [
    {
        id: 1,
        name: "Crash",
        value: "Crash Bandicoot",
        edit: true
    },
    {
        id: 2,
        name: "Mario",
        value: "Super Mario Brothers",
        edit: true
    },
    {
        id: 3,
        name: "Turok",
        value: "Turok Dinosaur Hunter"
    },
    {
        id: 4,
        name: "RE",
        value: "Resident Evil",
        menu: true
    },
]

const testFunc = data => {
    console.log("Dragged or Dropped Item")
    console.log(data)
}

export const ListPage = () => {
    const data1 = `<SortableList data={data} submitData={testFunc} />
    `
    const data2 = `<SortableList data={data} submitData={testFunc} />
    `
    return(
        <Fragment>
            <Block display="flex" width="calc(100% - 40px)" padding="20px" justify="flex-start">
                <SortableList data={data} submitData={testFunc} />
            </Block>
            <Showcode data = {data1} />
            <Block display="flex" width="calc(100% - 40px)" padding="20px" background={Color.DARK_GREY_BG_2} justify="flex-start">
                <SortableList data={data} submitData={testFunc} />
            </Block>
            <Showcode data = {data2} />
            <PropTable descriptions={SortableList.descriptions} ></PropTable>
        </Fragment>
    )
}