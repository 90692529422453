import React, { useState } from "react"
import { string, func, arrayOf, shape, instanceOf} from "prop-types"
import styled from 'styled-components'
import * as Color from '../../color.js'
import * as Spacing from '../../spacing.js'
import { Button } from '../button/index.js'
import { Block } from "../layout/index.js"
import { TextInput } from "../input/index.js"


const ChatStyles = styled.section`
    width: 100%;
`

const ChatHeader = styled.header`
    border-bottom: 1px solid ${Color.TEXT_HOVER_1};
`

const ChatTitle = styled.h1`
    font-family: Arial-BoldMT;
    font-size: 21px;
    font-weight: boldmt;
    color: ${Color.BLACK};
    margin: 0 0 ${Spacing.SPACING_03};
`

const ChatName = styled.p`
    color: ${Color.TEXT_HOVER_2};
    font-family: ArialMT;
    font-size: 14px;
    margin: 0 0 ${Spacing.SPACING_04};
`

const ChatMessage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${props => props.sent ? 'flex-end' : 'flex-start'};
    margin: ${Spacing.SPACING_03} 0 0;
`

const ChatBubble = styled.div`
    width: auto;
    max-width: 75%;
    font-family: ArialMT;
    font-size: 16px;
    padding: ${Spacing.SPACING_04} ${Spacing.SPACING_07};
    border-radius: 23px;
    ${props => props.sent
        ? `
            color: ${Color.WHITE};
            background: ${Color.PRIMARY_BLUE};
        `
        : `
            color: ${Color.BLACK};
            background: ${Color.TEXT_SECONDARY_1};
        `
    }
`

const ChatDate = styled.p`
    color: ${Color.TEXT_HOVER_2};
    font-family: ArialMT;
    font-size: 12px;
    margin: ${Spacing.SPACING_03} 0 0;
`

const ChatContent = styled.article`
`

const ChatFooter = styled.footer`
    margin: ${Spacing.SPACING_07} 0 0;
`

export const Chat = props => {
    const { user, name, data, action } = props
    const [text, setText] = useState("")

    const sendNewMessage = () => {
        const date = new Date()
        const newMessage = {
            user,
            message: text,
            date
        }
        action(newMessage)
        setText("")
    }

    return(
        <ChatStyles>
            <ChatHeader>
                <ChatTitle>
                    Chat History
                </ChatTitle>
                <ChatName>
                    {name && name}
                </ChatName>
            </ChatHeader>
            <ChatContent>
                {data && data.map((message, key) => {
                    const newDate = message.date.toUTCString()
                    return (
                        <ChatMessage sent={message.user === user} key={`Chat-Message-${key}`}>
                            <ChatBubble sent={message.user === user}>
                                {message.message}
                            </ChatBubble>
                            <ChatDate>
                                {newDate}
                            </ChatDate>
                        </ChatMessage>
                    )
                })}
            </ChatContent>
            <ChatFooter>
                <Block margin={`0 0 ${Spacing.SPACING_03}`}>
                    {/* TODO: replace with input component */}
                    <TextInput onChange={e => setText(e.target.value)} value={text} />
                </Block>
                <Button width="100%" onClick={sendNewMessage}>Send</Button>
            </ChatFooter>
        </ChatStyles>
    )
}

Chat.propTypes = {
    /** Action that is taken, example: send new message */
    action: func.isRequired,
    /** User name, displayed on chat messages */
    user: string.isRequired,
    /** Name of chat, for example who it is with or group name */
    name: string.isRequired,
    /** Data array that contains information for messages */
    data: arrayOf(shape({
        /** String that is the user's name, the sender of the message */
        user: string.isRequired,
        /** String message that is sent */
        message: string.isRequired,
        /** Date that message is timestamped with */
        date: instanceOf(Date).isRequired
    }))
}
Chat.descriptions = {
    // [Type, default, required, descriptions]
  action: ["function","Needs a default","True","Action that is taken, example: send new message"],
  user: ["string","Needs a default","True","User name, displayed on chat messages"],
  name: ["string","Needs a default","True","Name of chat, for example who it is with or group name"],
  data: ["Object[user(string), message(string), Date Object]","Needs a default","True","Data array that contains information for messages"],
}
