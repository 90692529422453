// ------- 2px ------- \\
export const SPACING_01 = "2px"

// ------- 4px ------- \\
export const SPACING_02 = "4px"
export const SPACING_03 = "8px"
export const SPACING_04 = "12px"
export const SPACING_05 = "16px"
export const SPACING_06 = "20px"
export const SPACING_07 = "24px"

// ------- 8px ------- \\
export const SPACING_08 = "32px"
export const SPACING_09 = "40px"
export const SPACING_10 = "48px"

// ------- 12px ------- \\
export const SPACING_11 = "64px"
export const SPACING_12 = "80px"