import React, { useState } from "react"
import { node, string, arrayOf, shape } from "prop-types"
import styled from 'styled-components'
import * as Color from '../../color.js'
import * as Spacing from '../../spacing.js'
import { Block } from "../layout/index.js"
import { FaChevronDown, FaChevronUp } from "react-icons/fa/index.esm.js"

const AccordionStyles = styled.section`
  width: 100%;
  border-bottom: 1px solid ${Color.TEXT_HOVER_1};
`

const AccordionArticle = styled.article`
  border-top: 1px solid ${Color.TEXT_HOVER_1};
`

const ArticleHeader = styled.header`
  display: flex;
  padding: ${Spacing.SPACING_05} ${Spacing.SPACING_06};
`

const ArticleHeaderText = styled.p`
  font-family: ArialMT;
  font-size: 16px;
  color: ${Color.MED_GREY};
  margin: 0;
  padding: 0;
  flex: 1 0 auto;
`

const ArticleContent = styled.div`
  padding: 0 ${Spacing.SPACING_06} ${Spacing.SPACING_05};
`

export const Accordion = props => {
  const { data } = props
  const [openArticle, setOpenArticle] = useState(0)
  // TODO: add ability to close and open and leave that way
  // TODO: add animation to ease slide open/close
  // TODO: add ellipsis component to expand text
  return(
    <AccordionStyles>
      {data.map((article, key) => {
        return(
          <AccordionArticle key={`Accordion-Article-${key}`}>
            <ArticleHeader>
              <ArticleHeaderText>
                {article.header}
              </ArticleHeaderText>
              <Block cursor="pointer" display="flex" align="center" justify="flex-end" onClick={(openArticle === key)
                ? () => setOpenArticle(null)
                : () => setOpenArticle(key)}>
                {(openArticle === key)
                  ? <FaChevronUp />
                  : <FaChevronDown />
                }
              </Block>
            </ArticleHeader>
            {(openArticle === key) && <ArticleContent>
              {article.content}
            </ArticleContent>}
          </AccordionArticle>
        )})}
    </AccordionStyles>
  )
}

Accordion.propTypes = {
  /** An array of objects, each containing a header string and content */
  data: arrayOf(shape({
    header: string,
    content: node.isRequired
  })).isRequired
}
Accordion.descriptions = {
  // [Type, default, required, descriptions]
  data: ["Object[header(string), content(node)]", "Needs a default", "True", "An array of objects, each containing a header string and content node"]
}