import React, { useState, Fragment } from "react"
import { node, string, func, bool, arrayOf, shape, number } from "prop-types"
import styled from 'styled-components'
import { Block } from '../layout/index.js'
import * as Color from '../../color.js'
import * as Spacing from '../../spacing.js'
import { GrDrag } from "react-icons/gr/index.esm.js"
import { MdMoreVert } from "react-icons/md/index.esm.js"

const SortableListStyles = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`

const DragIcon = styled.div`
    cursor: grab;
    display: flex;
    align-items: center;
    font-size: 18px;
    margin: 0 ${Spacing.SPACING_04};
`

const SortableListItem = styled.li`
    background: ${Color.WHITE};
    border: 1px solid ${Color.DM_MED_GREY};
    border-radius: 6px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    margin: 0 0 ${Spacing.SPACING_02} 0;
    position: relative;
    display: flex;
    justify-content: space-between;
    min-width: 320px;
`

const SortableListInput = styled.input`
    color: ${Color.DARK_GREY_BG_1};
    font-family: ArialMT;
    font-size: 16px;
    letter-spacing: 0.32px;
    border: 1px solid ${Color.PRIMARY_BLUE};
    box-shadow: 0px 0px 5px 0px rgba(15, 51, 95, 0.2);
    border-radius: 6px;
    margin: ${Spacing.SPACING_03} ${Spacing.SPACING_04} ${Spacing.SPACING_03} 0;
    padding: ${Spacing.SPACING_03} ${Spacing.SPACING_03};
    flex-grow: 1;
`

const SortableListValue = styled.p`
    font-family: NeueHaasUnicaW1G-Regular;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.3px;
    color: ${Color.MED_GREY};
    margin: ${Spacing.SPACING_04} ${Spacing.SPACING_04} ${Spacing.SPACING_04} 0;
    padding: 0;
`

const Done = styled.p`
    font-family: ArialMT;
    font-size: 14px;
    letter-spacing: 0.26px;
    color: ${Color.PRIMARY_BLUE};
    margin: ${Spacing.SPACING_04} ${Spacing.SPACING_04} ${Spacing.SPACING_04} 0;
    padding: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
`

const Edit = styled.p`
    font-family: ArialMT;
    font-size: 14px;
    letter-spacing: 0.26px;
    color: ${Color.PRIMARY_BLUE};
    margin: ${Spacing.SPACING_04} ${Spacing.SPACING_04} ${Spacing.SPACING_04} 0;
    padding: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
`

const MenuIcon = styled.p`
    font-size: 18px;
    color: ${Color.DARK_GREY_BG_1};
    margin: ${Spacing.SPACING_04} ${Spacing.SPACING_04} ${Spacing.SPACING_04} 0;
    padding: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
`

const Menu = styled.div`
    background: ${Color.INPUT_BACKGROUND};
    border-radius: 6px;
    border: 1px solid ${Color.DM_MED_GREY};
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 4px;
    right: 4px;
    padding: 0 ${Spacing.SPACING_04};
`
// Hover state
const MenuItem = styled.p`
    margin: 0;
    padding: ${Spacing.SPACING_04} 0;
    min-width: 100px;
    color: ${Color.PRIMARY_BLUE};
    font-family: ArialMT;
    font-size: 16px;
    letter-spacing: 0.32px;
    cursor: pointer;
`

export const SortableList = props => {
    const { data, submitData } = props
    const [newData, setNewData] = useState(data)
    const [editingKey, setEditingKey] = useState(null)
    const [menuKey, setMenuKey] = useState(null)

    const AllowDrop = e => {
        e.preventDefault()
    }

    const Drag = (e, key) => {
        e.dataTransfer.setData("incomingdrop", key)
    }

    const Drop = (e, key) => {
        e.preventDefault()
        let newListData = []
        let IncomingDropKey = parseInt(e.dataTransfer.getData("incomingdrop"))
        if(IncomingDropKey !== key) {
            const IncomingObject = newData.slice(IncomingDropKey, IncomingDropKey +1)[0]
            for(let i = 0; i < newData.length; i++) {
                if(i !== IncomingDropKey && i !== key) {
                    newListData.push(newData[i])
                }
                if(i === key) {
                    newListData.push(IncomingObject)
                }
                if(i === IncomingDropKey) {
                    newListData.push(newData[key])
                }
            }
            setNewData(newListData)
            submitData(newListData)
        }
    }

    const InputChange = (key, value) => {
        let newListData = [...newData]
        for(let i = 0; i < newListData.length; i++) {
            if(i === key) {
                newListData[i] = {
                    ...newListData[i],
                    value
                }
            }
        }
        setNewData(newListData)
        submitData(newListData)
    }

    return(
        <SortableListStyles>
            {newData && newData.map((item, key) => <SortableListItem key={`List-Sortable-Item-${key}-${item.id}-${item.name}`}
            draggable="true"
            onDragStart={e => Drag(e, key)}
            onDrop={e => Drop(e, key)}
            onDragOver={e => AllowDrop(e)}>
                <Block display="flex">
                    <DragIcon>
                        <GrDrag />
                    </DragIcon>
                    {(key === editingKey)
                        ? <SortableListInput type="text" id={item.id} name={item.name} value={item.value} onChange={e => InputChange(key, e.target.value)} />
                        : <SortableListValue>{item.value}</SortableListValue>
                    }
                </Block>
                {item.edit && <Fragment>
                    {(key === editingKey)
                        ? <Done onClick={() => setEditingKey(null)}>save</Done>
                        : <Edit onClick={() => setEditingKey(key)}>edit</Edit>
                    }
                </Fragment>}
                {item.menu && <Fragment>
                    <MenuIcon onClick={() => setMenuKey(key)}><MdMoreVert /></MenuIcon>
                    {(key === menuKey) && <Menu>
                        <MenuItem>edit</MenuItem>
                        <MenuItem>delete</MenuItem>
                    </Menu>}
                </Fragment>}
            </SortableListItem>)}
        </SortableListStyles>
    )
}

SortableList.propTypes = {
    /** Data array for list */
    data: arrayOf(shape({
        /** ID number for data element */
        id:number.isRequired,
        /** Name for element in data */
        name:string.isRequired,
        /** String for data value */
        value:string.isRequired,
        /** Whether or not there is the ability for the user to edit an element*/
        edit:bool,
        /** Whether or not there is a dropdown menu */
        menu:bool,
    })),
    /** Test function to submit Data */
    submitData: func,
}
SortableList.descriptions = {
    data: ["Object[id(number), name(string), value(string), edit(bool), menu(bool)]","Needs default","True","Data array for list, contains id, name, value, edit, and menu"],
    submitData: ["Function","","False","Test function to submit Data"],
}
