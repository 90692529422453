import styled from "styled-components"
import * as Color from '../../color.js'
import * as Spacing from '../../spacing.js'
import { useState } from "react"
import { InputContainer, InputLabel, InputText } from './textinput.js'
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md/index.esm.js"

const StyledSelect = styled.select`
    border: 1px solid ${props => InputTheme[props.dark ? "dark" : "light"][props.status].border};
    background: ${props => InputTheme[props.dark ? "dark" : "light"][props.status].background};
    color: ${props => InputTheme[props.dark ? "dark" : "light"][props.status].color};
    box-shadow: ${props => (props.status === "active") && "0px 0px 5px 0px rgba(15, 51, 95, 0.2)"};
    padding: ${Spacing.SPACING_03};
    border-radius: ${props => props.radius ? props.radius : '6px'};
    display: flex;
    font-family: ArialMT;
    font-size: 16px;
    margin: 0;
    width: 100%;
    letter-spacing: 0.32px;
    outline: none;
    cursor: pointer;
    &:hover {
        border: 1px solid ${props => InputTheme[props.dark ? "dark" : "light"][props.status].hoverBorder};
        background: ${props => InputTheme[props.dark ? "dark" : "light"][props.status].hoverBackground};
        color: ${props => InputTheme[props.dark ? "dark" : "light"][props.status].hoverColor};
    }
`

const FakeSelect = styled.div`
    border: 1px solid ${props => InputTheme[props.dark ? "dark" : "light"][props.status].border};
    background: ${props => InputTheme[props.dark ? "dark" : "light"][props.status].background};
    color: ${props => InputTheme[props.dark ? "dark" : "light"][props.status].color};
    box-shadow: ${props => (props.status === "active") && "0px 0px 5px 0px rgba(15, 51, 95, 0.2)"};
    padding: ${Spacing.SPACING_03};
    border-radius: ${props => props.radius ? props.radius : '6px'};
    display: flex;
    justify-content: space-between;
    font-family: ArialMT;
    font-size: 16px;
    margin: 0;
    width: calc(100% - ${Spacing.SPACING_05});
    letter-spacing: 0.32px;
    outline: none;
    cursor: pointer;
    &:hover {
        border: 1px solid ${props => InputTheme[props.dark ? "dark" : "light"][props.status].hoverBorder};
        background: ${props => InputTheme[props.dark ? "dark" : "light"][props.status].hoverBackground};
        color: ${props => InputTheme[props.dark ? "dark" : "light"][props.status].hoverColor};
    }
`

const CheckBoxes = styled.div`
    border: 1px solid ${props => InputTheme[props.dark ? "dark" : "light"][props.status].border};
    background: ${props => InputTheme[props.dark ? "dark" : "light"][props.status].background};
    color: ${props => InputTheme[props.dark ? "dark" : "light"][props.status].color};
    box-shadow: ${props => (props.status === "active") && "0px 0px 5px 0px rgba(15, 51, 95, 0.2)"};
    padding: ${Spacing.SPACING_03};
    border-radius: 6px;
    font-family: ArialMT;
    font-size: 16px;
    letter-spacing: 0.32px;
    cursor: pointer;
    width: calc(100% - ${Spacing.SPACING_05});
    margin: ${Spacing.SPACING_02} 0 0 0;
`

const CheckLabels = styled.label`
    display: block;
    cursor: pointer;
    user-select: none;
    &:hover
    {
        background-color: ${Color.HIGHLIGHT};
    }
`

export const InputTheme = {
    light: {
        active: {
            background: Color.INPUT_BACKGROUND,
            color: Color.DARK_GREY,
            border: Color.PRIMARY_BLUE,
            hoverBackground: Color.INPUT_BACKGROUND,
            hoverColor: Color.DARK_GREY,
            hoverBorder: Color.PRIMARY_BLUE,
            labelColor: Color.PRIMARY_BLUE,
            helpColor: Color.TEXT_SECONDARY_2
        },
        enabled: {
            background: Color.INPUT_BACKGROUND,
            color: Color.DARK_GREY,
            border: Color.TEXT_HOVER_1,
            hoverBackground: Color.INPUT_BACKGROUND,
            hoverColor: Color.DARK_GREY,
            hoverBorder: Color.TEXT_HOVER_1,
            labelColor: Color.MED_GREY,
            helpColor: Color.TEXT_SECONDARY_2
        },
        disabled: {
            background: Color.DISABLED_BG,
            color: Color.DISABLED_TEXT,
            border: Color.DISABLED_BG,
            hoverBackground: Color.DISABLED_BG,
            hoverColor: Color.DISABLED_TEXT,
            hoverBorder: Color.DISABLED_BG,
            labelColor: Color.MED_GREY,
            helpColor: Color.TEXT_SECONDARY_2
        },
        error: {
            background: Color.LIGHT_PINK_BG,
            color: Color.DARK_GREY,
            border: Color.ERROR_RED,
            hoverBackground: Color.LIGHT_PINK_BG,
            hoverColor: Color.DARK_GREY,
            hoverBorder: Color.ERROR_RED,
            labelColor: Color.ERROR_RED,
            helpColor: Color.ERROR_RED
        },
        button: {
            background: Color.PRIMARY_BLUE,
            color: Color.ZEBRA_STRIPE_BG,
            border: Color.PRIMARY_BLUE,
            hoverBackground: Color.DARK_BLUE,
            hoverColor: Color.ZEBRA_STRIPE_BG,
            hoverBorder: Color.DARK_BLUE
        }
    },
    dark: {
        active: {
            background: Color.HAIRLINE,
            color: Color.ZEBRA_STRIPE_BG,
            border: Color.DM_BLUE,
            hoverBackground: Color.HAIRLINE,
            hoverColor: Color.ZEBRA_STRIPE_BG,
            hoverBorder: Color.DM_BLUE,
            labelColor: Color.DM_BLUE,
            helpColor: Color.DM_GREY
        },
        enabled: {
            background: Color.HAIRLINE,
            color: Color.ZEBRA_STRIPE_BG,
            border: Color.ZEBRA_STRIPE_BG,
            hoverBackground: Color.HAIRLINE,
            hoverColor: Color.ZEBRA_STRIPE_BG,
            hoverBorder: Color.ZEBRA_STRIPE_BG,
            labelColor: Color.TEXT_HOVER_1,
            helpColor: Color.DM_GREY
        },
        disabled: {
            background: Color.DARK_GREY_BG_3,
            color: Color.TEXT_HOVER_2,
            border: Color.DARK_GREY_BG_2,
            hoverBackground: Color.DARK_GREY_BG_2,
            hoverColor: Color.TEXT_HOVER_2,
            hoverBorder: Color.DARK_GREY_BG_2,
            labelColor: Color.TEXT_HOVER_1,
            helpColor: Color.DM_GREY
        },
        error: {
            background: Color.DARK_GREY_BG_1,
            color: Color.ZEBRA_STRIPE_BG,
            border: Color.DARK_ERROR_RED,
            hoverBackground: Color.DARK_GREY_BG_1,
            hoverColor: Color.ZEBRA_STRIPE_BG,
            hoverBorder: Color.DARK_ERROR_RED,
            labelColor: Color.DARK_ERROR_RED,
            helpColor: Color.DARK_ERROR_RED
        }
    }
}

export const CheckList = props => {
    const { optionList, onChange, className, dark, status} = props
    const [newStatus, setNewStatus] = useState(status)
    
    const defaultList = [
        "Blue",
        "Yellow",
        "Red",
        "Green",
        "Yellow",
    ]
    //<CheckLabels key={`Checklabel-${headKey}-${head.value}`}>
    //<input className ={className ? className : ""} onChange={e => onChange(e)} 
    //type ="checkbox" value={head.value} key = {`keys-${headKey}-${head.value}`}/>{head.value}</CheckLabels>))


    /*
        (defaultList).map((value,key) => 
        {
            console.log(value, key);
            <CheckLabels key={`Checklabel-${key}-${value}`}><input className ={className ? className : ""} onChange={e => onChange(e)} type ="checkbox" value={value} key = {`keys-${key}-${value}`}/>{value}</CheckLabels>
        })}
        */

    /*
    {
                (optionList?.map((head,headKey) => {
                    console.log(head);
                    console.log(headKey);
                    console.log("HeadVal: " +head.value);
                    <CheckLabels key={`Checklabel-${headKey}-${head.value}`}><input className ={className ? className : ""} onChange={e => onChange(e)} type="checkbox" value={head.value} key = {`keys-${headKey}-${head.value}`}/>{head.value}</CheckLabels>
            }))
            }
            */
    return(
        <CheckBoxes disabled={newStatus === "disabled"}
                    dark={dark && dark}
                    status={newStatus ? newStatus : "enabled"}>


{
            (optionList?.map((head,headKey) => {
                //console.log(head, headKey, head.value, head.selected);
                return <CheckLabels key={`Checklabel-${headKey}-${head.value}`}><input className ={className ? className : ""} checked={head.selected ? head.selected : false} onChange={e => onChange(e)} type="checkbox" value={head.value} key = {`keys-${headKey}-${head.value}`}/>{head.value}</CheckLabels>
            }))
            }
        
        
            
        </CheckBoxes>
        )
}


export const DropList = props => {
    const {label, options, status, dark, radius} = props
    const [newStatus, setNewStatus] = useState(status)

    const DetectFocus = () => {
        if(status !== "error" && status !== "disabled") {
            setNewStatus("active")
        }
    }

    return(
        <InputContainer>
            {label && <InputLabel dark={dark && dark} status={newStatus ? newStatus : "enabled"}> {label ? label : "Label"}</InputLabel>}
            
            <StyledSelect disabled={newStatus === "disabled"}
                        dark={dark && dark}
                        radius={radius && radius}
                        status={newStatus ? newStatus : "enabled"} onBlur={() => setNewStatus(status)} onFocus={DetectFocus} name="Drop1" defaultValue="select">
                <option key="droplist-option-default-select" value="select" disabled hidden>Select</option>
                {options && options.map((value,key) => <option key={`keys-${key}-${value}`}>{value}</option>)
                }
            </StyledSelect>
            
            
        </InputContainer>
    )
}

export const DropChecklist = props => {
    const {label, options, status, dark, radius} = props
    const [newStatus, setNewStatus] = useState(status)
    const [open, setOpen] = useState(false)

    const Click = () => {
        if(status !== "error" && status !== "disabled") {
            if(!open) {
                setNewStatus("active")
            } else {
                setNewStatus("enabled")
            }
        }
        setOpen(!open)
    }

    return(
        <InputContainer onBlur={() => setNewStatus(status)}>
            {label && <InputLabel dark={dark && dark} status={newStatus ? newStatus : "enabled"}> {label ? label : "Label"}  </InputLabel>}
            <FakeSelect disabled={newStatus === "disabled"}
                    dark={dark && dark}
                    radius={radius && radius}
                    status={newStatus ? newStatus : "enabled"}
                    defaultValue="select"
                    onClick={(newStatus !== "disabled") ? Click : null}>
                    <InputText>Select</InputText>
                    {open ? <MdArrowDropUp/> : <MdArrowDropDown />}
            </FakeSelect>
            {open && <CheckBoxes disabled={newStatus === "disabled"}
                    dark={dark && dark}
                    status={newStatus ? newStatus : "enabled"}>
            {
                (options && options).map((value,key) => <CheckLabels key={`checklabels-${key}-${value}`}>
                    <input type ="checkbox" key = {`keys-${key}-${value}`}/>{value}
                </CheckLabels>)
            }
            </CheckBoxes>}
        </InputContainer>
    )
}
